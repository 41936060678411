import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";

// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";

import { useAuth } from "hooks/useAuth";

import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import { useKeycloak } from "@react-keycloak/web";

const useStyles = makeStyles(styles);

const excludedRoles = [
"STRATESYS",
"UVERIFYKUO",
"SUPERUSER",
"EHS",
"OFFLINE_ACCESS",
"UMA_AUTHORIZATION",
"DEFAULT-ROLES-CHRONYS",
"UVERIFYGRUPOINFRA",
"PRIMARY USER ROLE FOR SUPPLIER",
"UVERIFYBELTRAME",
"UVERIFYANGLO",
"UVERIFYCORTE",
"DEFAULT-ROLES-STAGGING",
];

export default function UserProfile() {
  const classes = useStyles();
  const { userInfo } = useAuth();
  const { keycloak } = useKeycloak();

  const emailSSOUser = sessionStorage.getItem("emailSSOUser");
  const nameSSOUser = sessionStorage.getItem("nameSSOUser");

  const userName = userInfo?.name ? userInfo?.name : nameSSOUser
  const userEmail = userInfo?.email ? userInfo?.email : emailSSOUser

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <PermIdentity />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                Profile
              </h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={5}>
                <InputLabel>Username</InputLabel>
                  <CustomInput
                    id="name-disabled"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      disabled: true,
                      value: userName
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={7}>
                <InputLabel>E-mail</InputLabel>
                  <CustomInput
                    id="email-address"
                    inputProps={{
                      disabled: true,
                      value: userEmail
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <InputLabel>Roles</InputLabel>
                  <CustomInput
                    id="about-me"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      multiline: true,
                      disabled: true,
                      value: userInfo?.realm_access?.roles
                        ? userInfo?.realm_access?.roles
                            .map((role) => role.toUpperCase())
                            .filter((role) => !excludedRoles.includes(role))
                            .join("\n")
                        : keycloak?.realmAccess?.roles
                            .map((role) => role.toUpperCase())
                            .filter((role) => !excludedRoles.includes(role))
                            .join("\n"),
                    }}
                  />
                </GridItem>
              </GridContainer>
              <Clearfix />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
