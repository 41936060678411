import { useQuery } from "@tanstack/react-query";
import apiInputService from "services/apiInputService";

const fetchSows = async (pagination: any) => {
  const sows = await apiInputService.get("/sow/paginated/", {
    params: { ...pagination },
  });

  const data = sows.data._metadata;

  return data;
};

export const useSowsQuery = (subscription: string, pagination?: string, options?: any) => {
  return useQuery<any>(["sows", subscription, pagination], () => fetchSows(pagination), {
    ...options,
  });
};
