import moment from "moment";

export const sortDateWithMoment = (a: any, b: any) => {
  const a1 = moment(a).toDate();
  const b1 = moment(b).toDate();
  if (a1 < b1) return 1;
  else if (a1 > b1) return -1;
  else return 0;
};

export const sortStatusWithBadge = (
  a: { values: { status: { props: { children: number } } } },
  b: { values: { status: { props: { children: number } } } }
) => {
  if (a.values.status.props.children < b.values.status.props.children) return -1;
  else if (a.values.status.props.children > b.values.status.props.children) return 1;

  return 0;
};

export const sortSowStatus = (
  a: { values: { sowStatus: { props: { children: number } } } },
  b: { values: { sowStatus: { props: { children: number } } } }
) => {
  if (a?.values.sowStatus.props.children < b?.values.sowStatus.props.children) return -1;
  else if (a?.values.sowStatus.props.children > b?.values.sowStatus.props.children) return 1;

  return 0;
};
