import React from "react";
import styled from "styled-components";
import { Check, Timer } from "@material-ui/icons";

interface Steps {
  [step: string]: boolean;
}

interface StepTimelineProps {
  steps: Steps;
}

const StepsContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Step = styled.div<{ completed: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid ${({ completed }) => (completed ? "green" : "orange")};
  color: ${({ completed }) => (completed ? "green" : "orange")};
  font-size: 24px;
`;

const StepLine = styled.div`
  flex: 1;
  height: 2px;
  background: #e9e9e9;
  z-index: 89;
  margin-top: 1px;
  /* border-top: 2px dashed gray; */
`;

const StepTitle = styled.div`
  font-size: 12px;
  /* top: 10; */
  /* bottom: 200px; */
  margin-bottom: -60px;
  text-align: center;
  position: absolute;
`;

const StepTimeline: React.FC<StepTimelineProps> = ({ steps }) => {
  const stepTitles = Object.keys(steps);

  return (
    <StepsContainer>
      {stepTitles.map((stepTitle, index) => (
        <React.Fragment key={index}>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Step completed={steps[stepTitle]}>{steps[stepTitle] ? <Check /> : <Timer />}</Step>
            <StepTitle>{stepTitle}</StepTitle>
          </div>
          {index !== stepTitles.length - 1 && (
            <StepLine style={{ background: steps[stepTitle] ? "green" : "#e9e9e9" }} />
          )}
        </React.Fragment>
      ))}
    </StepsContainer>
  );
};

export default StepTimeline;
