import Table from "components/Table/Table.js";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { makeStyles } from "@material-ui/core";

import { TFunction, useTranslation } from "react-i18next";

import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Badge from "components/Badge/Badge";
import ButtonTS from "components/CustomButtons/ButtonTS";
import { Box, Typography } from "@mui/material";
import { ExtractTypeEnum, WorkItemResultEnum } from "utils/@types";
import ZoomableImage from "components/ZoomableImage";
import {
  StyledBox,
  StyledButtonTS,
  StyledContainer,
  StyledNoImageAvailable,
  StyledTypography,
} from "./styles";
import axios from "axios";
import { compareStringsIgnoreCaseAndRemoveSpecialChars } from "utils/functions";

const useStyles = makeStyles(styles as any);

const tableHeaders = ["field", "original", "extracted", "status"];

const renderAnalysisStatus = (status: any, t: any) => {
  const renderedStatus = (status: any, color: any) => (
    <Badge color={color}>{t(`modal-table-status-${status}`)}</Badge>
  );

  switch (status) {
    case WorkItemResultEnum.HOMOLOGADO:
      return renderedStatus("valid", "success");
    case WorkItemResultEnum.APPROVED:
      return renderedStatus("valid", "success");
    case WorkItemResultEnum.NAO_HOMOLOGADO:
      return renderedStatus("invalid", "danger");
    case WorkItemResultEnum.NOT_APPROVED:
      return renderedStatus("invalid", "danger");
    default:
      return renderedStatus("error", "warning");
  }
};

const renderAnalysisExtracted = (extract: string, t: TFunction<"analysis-modal", undefined>) => {
  if (compareStringsIgnoreCaseAndRemoveSpecialChars(extract, ExtractTypeEnum.INVALIDO))
    return t(`modal-table-extract-invalid-doc`);

  if (compareStringsIgnoreCaseAndRemoveSpecialChars(extract, ExtractTypeEnum.CAMPO_NAO_ENCONTRADO))
    return t("modal-table-extract-invalid-field");

  if (compareStringsIgnoreCaseAndRemoveSpecialChars(extract, ExtractTypeEnum.DOCUMENTO_INVALIDO))
    return t("modal-table-extract-invalid-document");

  if (compareStringsIgnoreCaseAndRemoveSpecialChars(extract, ExtractTypeEnum.TIPO_DOCUMENTO))
    return t("modal-table-extract-document-type");

  return extract;
};

export default function AnalysisModal({ analysis, modal, setModal, document }: any) {
  const classes = useStyles();
  const { t } = useTranslation("analysis-modal");

  const analyzedContent =
    analysis
      ?.filter((item: any) => item.fieldCompared)
      ?.map((analysis: any) => [
        t(`modal-table-cell-${analysis.fieldName}`),
        renderAnalysisExtracted(analysis.fieldCompared, t),
        renderAnalysisExtracted(analysis.fieldExtracted, t),
        renderAnalysisStatus(analysis.status, t),
      ]) || [];

  const tableHeadersFormatted = tableHeaders.map((header) => t(`modal-table-header-${header}`));

  const openPdfInNewTab = async () => {
    if (!document?.url) return;

    const { data } = await axios.get(document?.url, {
      responseType: "blob",
    });

    const metadata = {
      type: "application/pdf",
      disposition: "inline",
    };

    const file = new File([data], "file", metadata);
    const fileURL = URL.createObjectURL(file);

    window.open(fileURL);
  };

  return (
    <div>
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal,
        }}
        open={modal}
        keepMounted
        onClose={() => setModal(false)}
        aria-labelledby='modal-slide-title'
        aria-describedby='modal-slide-description'
      >
        <DialogTitle
          id='classic-modal-slide-title'
          disableTypography
          className={classes.modalHeader}
        >
          <h4 className={classes.modalTitle}>{t("modal-title")}</h4>
        </DialogTitle>
        <DialogContent id='modal-slide-description' className={classes.modalBody}>
          <Box>
            {document?.url ? (
              document?.url.includes(".pdf") ? (
                <StyledContainer>
                  <StyledButtonTS color='primary' onClick={openPdfInNewTab}>
                    {t("open-pdf")}
                  </StyledButtonTS>
                </StyledContainer>
              ) : (
                <StyledBox>
                  <ZoomableImage src={document?.url} />
                  <StyledTypography>
                    {t("zoomable-image-guideline", { ns: "common" })}
                  </StyledTypography>
                </StyledBox>
              )
            ) : (
              <StyledNoImageAvailable>
                <Typography>{t("no-image-available")}</Typography>
              </StyledNoImageAvailable>
            )}
            <Typography>
              File Name: <strong>{document?.name}</strong>
            </Typography>
          </Box>
          <Table
            hover
            tableHeaderColor='success'
            tableHead={tableHeadersFormatted}
            tableData={analyzedContent}
          />
        </DialogContent>
        <DialogActions className={classes.modalFooter + " " + classes.modalFooterCenter}>
          <ButtonTS onClick={() => setModal(false)}>{t("modal-action-close")}</ButtonTS>
        </DialogActions>
      </Dialog>
    </div>
  );
}
