import { useRef } from "react";
import LoadingBar from "react-top-loading-bar";

import "react-loading-skeleton/dist/skeleton.css";
import DashboardCardsSupplier from "./components/DashboardCardsSupplier";

export function Dashboard() {
  const ref = useRef(null);

  return (
    <>
      <LoadingBar color='#ff9800' ref={ref} />
      <DashboardCardsSupplier />
    </>
  );
}
