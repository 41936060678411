import { useQuery } from "@tanstack/react-query";
import apiInputService from "services/apiInputService";

const fetchSync = async (type: string) => {
  const { data } = await apiInputService.get(`/Fieldglass/sync/${type}`);

  return data;
};

export const useSync = (type: string, options?: any) => {
  return useQuery<any[]>(["sync", type], () => fetchSync(type), {
    enabled: false,
    ...options,
  });
};
