import { differenceInYears } from "date-fns";
import { formateDateWithTZ } from "utils/functions";

export function workerOrderRemap(workOrderObj: {
  customFields: any[];
  workOrderStatus: any;
  workOrderStartDate: any;
  workOrderEndDate: any;
  siteName: any;
  siteCode: any;
  primaryLocationCode: any;
  locationCode: any;
  primaryLocation: any;
  costCenterName: any;
  businessUnitCode: any;
  businessUnitName: any;
  dateOfBirth: string | number | Date;
  vendorName: any;
  sowWorkerRoleCode: any;
  sowWorkerRole: any;
  securityID: any;
}) {
  const customFields: { [key: string]: string } = {};

  workOrderObj?.customFields?.forEach(
    (customField) => (customFields[customField.name] = customField.value)
  );

  const workOrderInfo = {
    Status: workOrderObj.workOrderStatus,
    "Start Date": workOrderObj.workOrderStartDate,
    "End Date": workOrderObj.workOrderEndDate,
    Site: `${workOrderObj.siteName} (${workOrderObj.siteCode})`,
    Location: `${workOrderObj?.primaryLocationCode ?? workOrderObj?.locationCode ?? " "} - ${
      workOrderObj?.primaryLocation ?? workOrderObj?.siteName ?? " "
    }`,
    "Cost Center": workOrderObj.costCenterName,
    BusinessUnit: `${workOrderObj?.businessUnitCode ?? " "} - ${
      workOrderObj?.businessUnitName ?? " "
    }`,
    "Date of birth": formateDateWithTZ(workOrderObj.dateOfBirth),
    Age: differenceInYears(new Date(), new Date(workOrderObj.dateOfBirth)),
    "Vendor Name": workOrderObj.vendorName ? workOrderObj.vendorName : "",
    "Sow Worker Role Code": workOrderObj.sowWorkerRoleCode ? workOrderObj.sowWorkerRoleCode : "",
    "Sow Worker Role": workOrderObj.sowWorkerRole ? workOrderObj.sowWorkerRole : "",
    "Security ID": workOrderObj.securityID ? workOrderObj.securityID : "",
    // 'Job Name': workOrderObj.sowWorkerRoleName ?? '-',
    // Supervisor: workOrderObj.workOrderWorkOrderRevisionOwner,
    // 'Profile Worker Primary Contact': workOrderObj.workOrderWorkOrderRevisionOwner,
    // 'Security ID': workOrderObj.securityID,
    // 'Job Code': workOrderObj.sowWorkerRoleCode ?? '-',
    // 'Job Title': (workOrderObj.sowWorkerRoleCode ?? '-') + " / " + (workOrderObj.sowWorkerRoleName ?? '-'),
    // 'User Id': workOrderObj.userID ?? workOrderObj.workerID ?? '-',
    // 'Activity Checklist': workOrderObj.jobCode ? workOrderObj.jobCode : '',
  };

  return workOrderInfo;
}
