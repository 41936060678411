import { makeStyles } from '@material-ui/core/styles';

export const useDecisionTreeWizardStyles = makeStyles({
  background: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.4)',
    zIndex: 1040,
    display: 'block',
  },

  modal: {
    backgroundColor: 'white',
    position: 'fixed',
    height: 'auto',
    maxHeight: '90vh',
    top: '50%',
    left: '50%',
    textAlign: 'center',
    transform: 'translate(-50%, -50%)',
    borderRadius: '5px',
    alignItems: 'center',
    zIndex: 1041,
  },

  modalheader: {
    background: '#08558d',
    color: 'white',
    borderRadius: '5px',
    textAlign: 'center',
    paddingLeft: '50px',
    paddingRight: '50px',
    paddingTop: '10px',
    height: '100px',
    margin: '0px 25px',
  },

  modalbody: {
    height: '270px',
    width: '472px',
    margin: '30px 10px 10px 10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },

  buttonGrid: {
    display: 'grid',
    justifyContent: 'center',
    gap: '10px'
  }
});