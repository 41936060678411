import React from "react";
import { Button, Typography } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import QRCode from "react-qr-code";
import iconAngloUverify from "../../../../../assets/img/anglo-american.png";
import { ButtonContainer, CardContainer } from "./PhysicalCardDialog.styles";
import avatar from "assets/img/default-avatar.png";
import { useWorkerPhysicalCardQuery } from "services/input-core/workers/get-physical-card-data";
import CustomSkeleton from "components/CustomSkeleton/CustomSkeleton";
import { Box } from "@mui/material";

type ActivityItem = {
  isValid: boolean;
  name: string;
};

type WorkerPhysicalCardData = {
  firstName: string;
  lastName: string;
  consolidatedWorkerId: string;
  activityItems: ActivityItem[];
  businessUnit: string;
  supplierName: string;
  endDate: string;
  expirationDate: string;
  hash: string;
  age: number;
  nationality: string;
  dateOfBirth: string;
  sowWorkerRole: string;
  label: string;
  workerImage: string;
  driverLicenseType: string;
};

type PhysicalCardDialogProps = {
  showNotification?: (color: string, message: string) => void;
  setModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  workerPhysicalIdData?: WorkerPhysicalCardData;
  worker?: any;
};

const PhysicalCardDialog: React.FC<PhysicalCardDialogProps> = ({ worker, setModalOpen }) => {
  const navigate = useNavigate();
  const [isPrinted, setIsPrinted] = React.useState(false);
  const [getNewPhoto, setGetNewPhoto] = React.useState(null);

  const { data: workerPhysicalCardData, isLoading } = useWorkerPhysicalCardQuery(worker?._id, {
    enabled: !!worker?._id,
  });

  React.useEffect(() => {
    setGetNewPhoto(workerPhysicalCardData?.workerImage);
  }, [workerPhysicalCardData?.workerImage]);

  const handlePrint = () => {
    setIsPrinted(true);
    window.print();
  };

  React.useEffect(() => {
    const showHide = () => {
      setIsPrinted(false);
    };
    window.addEventListener("afterprint", showHide);

    return () => {
      window.removeEventListener("afterprint", showHide);
    };
  }, []);

  const groupedData = workerPhysicalCardData?.activityItems?.reduce((acc: any, item: any) => {
    if (!acc[item.type]) {
      acc[item.type] = [];
    }
    acc[item.type].push(item);
    return acc;
  }, {});

  const exportToFile = (): React.ReactNode => {
    return (
      <Box
        style={{
          width: "18cm",
          display: "flex",
          border: "1px dashed #000",
          padding: "3px",
          backgroundColor: "#fff",
        }}
      >
        <Box
          style={{
            width: "9cm",
            height: "6cm",
            border: "1px solid #3A3287",
          }}
        >
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "4px 4px 0 4px",
              marginBottom: 10,
            }}
          >
            <Box>
              <img style={{ width: "120px" }} src={iconAngloUverify} alt='Anglo Logo' />
            </Box>
          </Box>

          <Box
            style={{
              display: "flex",
              marginLeft: 10,
            }}
          >
            <Box style={{ width: "120px", height: "140px" }}>
              {getNewPhoto === undefined ? (
                <img
                  style={{ width: 105, height: 140, borderRadius: 5 }}
                  src={avatar}
                  alt='Avatar'
                />
              ) : (
                <img
                  style={{ width: 105, height: 140, borderRadius: 5, objectFit: "cover" }}
                  src={getNewPhoto}
                  alt='Avatar'
                />
              )}

              <Typography
                style={{
                  color: "#3A3287",
                  fontSize: "8px",
                  fontWeight: "bold",
                  fontFamily: "Arial",
                }}
              >
                Card Expiration Date: {workerPhysicalCardData?.endDate}
              </Typography>
            </Box>

            <Box
              style={{
                marginLeft: "14px",
              }}
            >
              <Typography align='left' style={typographyStyle}>
                Name: {workerPhysicalCardData?.firstName} {workerPhysicalCardData?.lastName}
              </Typography>

              <Typography align='left' style={typographyStyle}>
                ID: {workerPhysicalCardData?.consolidatedWorkerId}
              </Typography>

              <Typography align='left' style={typographyStyle}>
                Worker Role: {workerPhysicalCardData?.sowWorkerRole}
              </Typography>

              <Typography align='left' style={typographyStyle}>
                Worker Role end date: {workerPhysicalCardData?.endDate}
              </Typography>
              <Typography align='left' style={typographyStyle}>
                Driver License: {workerPhysicalCardData?.driverLicenseType}
              </Typography>

              <Typography style={typographyStyle}>
                Supplier Company: {workerPhysicalCardData?.supplierName}
              </Typography>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "4px",
                  justifyContent: "center",
                  alignItems: "flex-end",
                  marginTop: "20px",
                  marginRight: "6px",
                }}
              >
                <QRCode
                  size={164}
                  value={`${process.env.REACT_APP_UVERIFY_URL}/physical-card?_id=${worker._id}&hash=${worker.hash}`}
                  style={{
                    width: "75px",
                    height: "75px",
                  }}
                  onClick={() => {
                    navigate(`/physical-card?_id=${worker._id}&hash=${worker.hash}`);
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          style={{
            width: "9cm",
            height: "6cm",
            border: "1px solid #3a3287",
            display: "flex",
            padding: "4px",
          }}
        >
          {Object.entries(groupedData).map(([type, items]: any) => (
            <Box
              key={type}
              style={{
                width: "50%",
                height: "100%",
                borderRight: "1px solid #e9e9e9",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography style={typographyTitle}>{type}</Typography>
              {items.map((activityItem: any) => (
                <Box padding='5px 10px 0 5px' key={activityItem.name}>
                  <Typography
                    style={{
                      color: "#3A3287",
                      fontSize: "9px",
                      fontWeight: "bold",
                      fontFamily: "Arial",
                    }}
                  >
                    ➢ {activityItem.name}: {activityItem.expirationDate ?? "-"}
                  </Typography>
                </Box>
              ))}
            </Box>
          ))}
        </Box>
      </Box>
    );
  };

  return (
    <CardContainer>
      {!isLoading ? exportToFile() : <CustomSkeleton numberOfLines={1} height={"100px"} />}
      {!isPrinted && (
        <ButtonContainer
          className='hide-on-print'
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            marginTop: "20px",
            gap: "10px",
          }}
        >
          <Button
            className='hide-on-print'
            variant='outlined'
            color='primary'
            onClick={() => setModalOpen(false)}
          >
            Close
          </Button>
          <Button
            className='hide-on-print'
            variant='contained'
            color='primary'
            onClick={handlePrint}
          >
            Export
          </Button>
        </ButtonContainer>
      )}
    </CardContainer>
  );
};

const typographyStyle = {
  color: "#3A3287",
  fontSize: "8px",
  fontWeight: "bold",
  display: "flex",
  gap: "4px",
  fontFamily: "Arial",
};
const typographyTitle = {
  color: "#3A3287",
  fontSize: "12px",
  fontWeight: "bold",
  textDecoration: "underline",
  marginBottom: "10px",
  fontFamily: "Arial",
};

export default PhysicalCardDialog;
