import React from "react";
import * as Tabs from "@radix-ui/react-tabs";
import { Button } from "@material-ui/core";
import { styles } from "./styles";
import { useTranslation } from "react-i18next";

type TabTriggerProps = React.ComponentProps<typeof Tabs.Trigger> & {
  isActive?: boolean;
  size?: "small" | "medium" | "large";
  hasCounter?: number | null;
  variant?: "card" | "page";
};

export const TabTrigger = ({
  isActive,
  value,
  variant = "page",
  hasCounter = null,
  ...rest
}: TabTriggerProps) => {
  const classes = styles({ variant });
  const { t } = useTranslation("worker-profile");

  return (
    <Tabs.Trigger {...rest} value={value} asChild>
      <Button size='small' focusRipple={false} disableRipple className={classes.root}>
        {t(rest.children.toString())}
        {Boolean(hasCounter) && <span>{hasCounter.toString().padStart(2, "0")}</span>}
      </Button>
    </Tabs.Trigger>
  );
};
