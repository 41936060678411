import { useQuery } from "@tanstack/react-query";
import apiInputService from "services/apiInputService";

const fetchWorkerOrders = async (pagination: any) => {
  const workerOrders: any = await apiInputService.get(`/WorkOrders`, {
    params: { ...pagination },
  });

  const data = workerOrders.data._metadata;

  return data;
};

export const useWorkerOrdersQuery = (subscription: string, pagination?: string, options?: any) => {
  return useQuery<any>(
    ["WorkOrders", subscription, pagination],
    () => fetchWorkerOrders(pagination),
    {
      ...options,
    }
  );
};
