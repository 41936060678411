import { useMutation } from "@tanstack/react-query"
import apiInputService from "services/apiInputService"
import { ChangeATOStatusPayload } from "./@types"

const changeATOStatus = async (payload: ChangeATOStatusPayload) => {
    const { data } = await apiInputService.post("/accessToOperation", payload);

    return data;
}

export const useChangeATOStatus = (options?: any) => {
    return useMutation(changeATOStatus, {
        ...options,
    });
}