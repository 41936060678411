import { CircularProgress, makeStyles } from "@material-ui/core";
import { Group } from "@material-ui/icons";
import { cardTitle } from "assets/jss/material-dashboard-pro-react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import ReactTable from "components/ReactTable/ReactTable";
import { useTranslation } from "react-i18next";
import { useWorkersBySowIDQuery } from "services/input-core/workers/get-all-workers-by-sowid";
import Button from "components/CustomButtons/ButtonTS";
import { useNavigate } from "react-router-dom";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";
import { getBadgeFromStatus } from "utils/functions";
import { sortStatusWithBadge } from "helpers/customSortMethods";

const styles: any = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
};

interface WorkerTableProps {
  omit?: Array<string>;
}

const useStyles = makeStyles(styles);

const WorkersFromSowTable = ({ omit }: WorkerTableProps) => {
  const classes = useStyles();

  const refId = new URLSearchParams(window.location.search).get("id");
  const { t } = useTranslation("worker-profile");
  const navigate = useNavigate();

  const { data: workersFromSOW, isFetching: isWorkersFromSowFetching } =
    useWorkersBySowIDQuery(refId);

  const workersFormatted =
    workersFromSOW?.map((worker) => ({
      code: worker.workerID,
      name: `${worker.firstName + " "}${worker.lastName}`,
      status: getBadgeFromStatus(worker.status),
      ato: getBadgeFromStatus(worker.ATO ?? "No activities"),
      atw: getBadgeFromStatus(worker.ATW ?? "No activities"),
      actions: !!worker.workerID && (
        <div className='actions-right'>
          <Button
            justIcon
            round
            color='info'
            size='sm'
            onClick={() => loadProfile(worker.workerID)}
          >
            <ArrowForwardIos />
          </Button>
        </div>
      ),
    })) ?? [];

  const loadProfile = (profileId: string) => {
    navigate("/app/workers/profile?type=worker&id=" + profileId);
  };

  const columns: any = [
    {
      Header: t("worker-id"),
      accessor: "code",
    },
    {
      Header: t("worker-table-name"),
      accessor: "name",
    },
    {
      Header: t("worker-table-status-fg"),
      accessor: "status",
      sortType: sortStatusWithBadge,
    },
    {
      Header: t("worker-table-ato"),
      accessor: "ato",
    },
    {
      Header: t("worker-table-atw"),
      accessor: "atw",
    },
    {
      Header: t("worker-table-action"),
      accessor: "actions",
    },
  ];

  const columnsFiltered = columns.filter((c: any) => !omit?.includes(c.accessor));

  return (
    <Card>
      <CardHeader color='rose' icon>
        <CardIcon color='rose'>
          <Group />
        </CardIcon>
        <h4 className={classes.cardIconTitle}>{t("worker-table-title")}</h4>
      </CardHeader>
      <CardBody>
        {isWorkersFromSowFetching ? (
          <CircularProgress />
        ) : (
          <ReactTable columns={omit ? columnsFiltered : columns} data={workersFormatted} />
        )}
      </CardBody>
    </Card>
  );
};

export default WorkersFromSowTable;
