import { useQuery } from "@tanstack/react-query";
import apiInputService from "services/apiInputService";

const fetchWorkers = async (pagination: any) => {
  const workers = await apiInputService.get("/Worker/paginated", {
    params: { ...pagination },
  });

  const data = workers.data._metadata;

  return data;
};

export const useWorkersQuery = (subscription: string, pagination?: string, options?: any) => {
  return useQuery<any>(["workers", subscription, pagination], () => fetchWorkers(pagination), {
    ...options,
  });
};
