import { createContext, ReactNode, useContext, useMemo } from "react";
import { useGetRealmUri } from "services/input-core/appConfigs/get-realm-uri";
import { useSubscription } from "./useSubscription";

interface IClientConfigContext {
  realmURI: string;
  isLoadingRealmURI: boolean;
}

interface IClientConfigProps {
  children: ReactNode;
}

const ClientConfigContext = createContext<IClientConfigContext>({} as IClientConfigContext);

export function ClientConfigProvider({ children }: IClientConfigProps) {
  const { subscription } = useSubscription();

  const { data: realmURI, isFetching: isLoadingRealmURI } = useGetRealmUri(subscription);

  const value = useMemo(
    () => ({
      realmURI: realmURI?.realmUri,
      isLoadingRealmURI,
    }),
    [realmURI, isLoadingRealmURI]
  );

  return <ClientConfigContext.Provider value={value}>{children}</ClientConfigContext.Provider>;
}

export function useClientConfig(): IClientConfigContext {
  const context = useContext(ClientConfigContext);

  if (!context) {
    throw new Error("useClientConfig must be used within an ClientConfigProvider");
  }

  return context;
}
