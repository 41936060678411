import { SyntheticEvent } from "react";
import { FieldValues, UseFormWatch, Controller, Control } from "react-hook-form";
import { AvailableValuesProps, FieldsProps } from "services/input-core/clientConfig/type";
import AutoCompleteCustom from "components/AutoCompleteCustom";

type InputSearcherProps = {
  fieldData: FieldsProps;
  disabled?: boolean;
  hookFormWatch: UseFormWatch<FieldValues>;
  // eslint-disable-next-line @typescript-eslint/ban-types
  hookFormControl?: Control<FieldValues, object>;
};

export default function InputSearcher({
  fieldData,
  hookFormWatch,
  hookFormControl,
  disabled,
}: InputSearcherProps) {
  return (
    <>
      <Controller
        render={({ field: { onChange } }) => (
          <AutoCompleteCustom
            id='input-search'
            label={fieldData?.displayName?.default}
            required={fieldData?.required}
            value={
              fieldData?.availableValues?.find((a) => a?.value === hookFormWatch(fieldData?.name))
                ?.displayName?.default ?? ""
            }
            disabled={disabled}
            onChange={(e: SyntheticEvent<Element, Event>, data: { id: string }) => {
              if (typeof data !== "string") onChange(data?.id ?? undefined);
            }}
            options={fieldData.availableValues
              .filter((option: AvailableValuesProps) =>
                !option.showWhen
                  ? option
                  : hookFormWatch(option.showWhen[0].key) === option.showWhen[0].value[0]
              )
              .map((option: AvailableValuesProps) => ({
                id: option.value,
                label: option.displayName.default,
              }))}
          />
        )}
        rules={{
          required: fieldData?.required,
        }}
        name={fieldData?.name}
        control={hookFormControl}
      />
    </>
  );
}
