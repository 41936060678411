import { useQuery } from "@tanstack/react-query";
import apiInputService from "services/apiInputService";
import { DashboardData } from "./@types";

const fetchDashboard = async (): Promise<DashboardData> => {
  const response = await apiInputService.get(`/dashboard`);

  const { data } = response;

  return data as DashboardData;
};

export const useDashboard = (subscription: string, options?: any) => {
  return useQuery<DashboardData>(["dashboard-data", subscription], () => fetchDashboard(), {
    ...options,
  });
};
