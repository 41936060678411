/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-var-requires */
import { Box, Typography, Grid, Ite } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";

export default function Footer() {
  const { t } = useTranslation("login-page");

  const [facebookIcon] = React.useState(require("assets/img/facebook_icon.svg").default);

  const [youtubeIcon] = React.useState(require("assets/img/youtube_icon.svg").default);

  const [linkedinIcon] = React.useState(require("assets/img/linkedin_icon.svg").default);

  function RightsReserved() {
    return (
      <Box style={BoxStyle}>
        <Typography style={TypographyTitleStyle}>U-Verify by Stratesys</Typography>
        <Typography style={TypographyDescriptionStyle}>
          © Stratesys 2023 – {t("allRightsReserved")}
        </Typography>
      </Box>
    );
  }

  function StratesysLinks() {
    return (
      <Box style={BoxStyle}>
        <Typography style={TypographyTitleStyle}>Links</Typography>
        <a
          href='https://www.stratesys-ts.com/pt/#aboutus'
          target='_blank'
          style={TypographyDescriptionStyle}
        >
          Stratesys
        </a>
        <a
          href='https://www.stratesys-ts.com/pt/#aboutus'
          target='_blank'
          style={TypographyDescriptionStyle}
        >
          {t("policyPrivacy")}
        </a>
      </Box>
    );
  }

  function StratesysContact() {
    return (
      <Box style={BoxStyle}>
        <Typography style={TypographyTitleStyle}>{t("Contact")}</Typography>
        <Typography style={TypographyDescriptionStyle}>+55 (11) 4280-8482</Typography>
        <Typography style={TypographyDescriptionStyle}>info.sao@stratesys-ts.com</Typography>
      </Box>
    );
  }

  function SocialMedias() {
    return (
      <Box style={BoxStyle}>
        <Typography style={TypographyTitleStyle}>{t("followUp")}</Typography>
        <Box style={{ display: "flex", gap: "10px", width: "90px" }}>
          <a href='https://www.facebook.com/stratesysbrasil' target='_blank'>
            <img src={facebookIcon} alt='Facebook Icon' style={IconStyle} />
          </a>
          <a href='https://www.linkedin.com/company/stratesys-brasil/' target='_blank'>
            <img src={linkedinIcon} alt='Linkedin Icon' style={IconStyle} />
          </a>
          <a href='https://www.youtube.com/user/stratesys' target='_blank'>
            <img src={youtubeIcon} alt='Youtube Icon' style={IconStyle} />
          </a>
        </Box>
      </Box>
    );
  }

  if (isMobile) {
    return (
      <Box
        style={{
          width: "100%",
          color: "#76767f",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            borderTop: "1px solid #e9e9e9",
            padding: "5px",
            width: "95%",
            margin: "0 auto",
            alignItems: "center",
            position: "inherit",
          }}
        />
        <Box
          style={{
            color: "#76767f",
          }}
        >
          {RightsReserved()}
        </Box>
      </Box>
    );
  }

  return (
    <Box padding='0 20px'>
      <div
        style={{
          borderTop: "1px solid #e9e9e9",
          padding: "5px",
          width: "95%",
          margin: "0 auto",
          alignItems: "center",
          position: "inherit",
        }}
      />
      <Box
        style={{
          width: "100%",
          justifyContent: "space-between",
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          color: "#76767f",
        }}
      >
        {RightsReserved()}
        {StratesysLinks()}
        {StratesysContact()}
        {SocialMedias()}
      </Box>
    </Box>
  );
}

const BoxStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: isMobile ? "center" : "start",
  marginTop: "10px",
};
const TypographyTitleStyle = {
  fontSize: 13,
  fontWeight: "bold",
  paddingBottom: "10px",
};
const TypographyDescriptionStyle = {
  fontSize: 12,
  marginBotton: "5px",
  color: "inherit",
  fontWeight: "400",
  letterSpacing: "3px",
};
const IconStyle = { width: "20px", height: "20px" };
