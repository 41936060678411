export function supplierRemap(supplierObj: {
  customFields: any[];
  status: any;
  supplierCode: any;
  address1: any;
  address2: any;
  city: any;
  country: any;
  supplierZIPPostalCode: any;
  supplierPrimaryContactPerson: any;
  supplierCompanyContactPersonEmail: any;
  supplierPrimaryContactPersonforServicesEmail: any;
}) {
  const customFields: { [key: string]: string } = {};

  supplierObj.customFields.forEach(
    (customField) => (customFields[customField.name] = customField.value)
  );

  const supplierInfo = {
    Status: supplierObj.status,
    Code: supplierObj.supplierCode,
    "Address 1": supplierObj.address1,
    "Address 2": supplierObj.address2,
    City: supplierObj.city,
    Country: supplierObj.country,
    "ZIP/Postal Code": supplierObj.supplierZIPPostalCode,
    "Contact Person": supplierObj.supplierPrimaryContactPerson,
    "Contact Email": supplierObj.supplierCompanyContactPersonEmail,
    "Contact Email for Services": supplierObj.supplierPrimaryContactPersonforServicesEmail,
  };

  return supplierInfo;
}
