import { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import moment from "moment";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import TableChartIcon from "@material-ui/icons/TableChart";
import ApprovalIcon from "@material-ui/icons/AssignmentTurnedIn";

import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";

import avatar from "assets/img/default-avatar.png";
import Badge from "components/Badge/Badge";
import { AddAlert, ExpandMoreRounded } from "@material-ui/icons";
import {
  Tooltip,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Accordion,
} from "@material-ui/core";
import Snackbar from "components/Snackbar/Snackbar";

import "react-loading-skeleton/dist/skeleton.css";
import { useSubscription } from "hooks/useSubscription";
import ManualValidationModal from "components/ManualValidationModal/ManualValidationModal";
import TabSystem from "components/TabsRadix/TabSystem";
import TabContent from "components/TabsRadix/TabContent";
import WOSensitiveDataForm from "views/Pages/Workers/Components/WOSensitiveDataForm/WOSensitiveDataForm";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import FieldglassInfo from "components/FieldglassInfo/FieldglassInfo";
import ProfileSummary from "components/ProfileSummary/ProfileSummary";
import ActivityItemsWorker from "components/ActivityItemsTable/ActivityItemsWorker";
import ProcessTable from "components/ProcessTable/ProcessTable";
import { useWorkOrderByIdQuery } from "services/input-core/worker-orders/get-work-order";
import { useSensitiveData } from "services/input-core/sensitive-data/get-all-sensitive-data";
import { useAuth } from "hooks/useAuth";
import WOHRFields from "./Components/WOHRFields/WOHRFields";
import { useWorkOrderActivityItemsWithStatus } from "services/input-core/work-items/get-work-order-activity-items-with-status";
import { useWorkOrderProcessTableQuery } from "services/input-core/worker-orders/get-work-order-process-table";
import AnalysisModal from "components/Analysis/AnalysisModal";
import ButtonTS from "components/CustomButtons/ButtonTS";
import GridItemTS from "components/Grid/GridItemTS";
import { useGetFormStatus } from "services/input-core/sensitive-data/get-form-status";
import { useKeycloak } from "@react-keycloak/web";
import WorkOrdersRelated from "./Components/WorkOdersRelated/WorkOrdersRelated";
import { workerOrderRemap } from "helpers/WorkerOrderRemap";
import CustomSkeleton from "components/CustomSkeleton/CustomSkeleton";
import Stepper from "components/Stepper/Stepper";
import { WorkItemResultEnum, WorkItemStatusEnum, ProfileStatusEnum } from "utils/@types";
import {
  checkIfStringInArrayIgnoreCaseAndRemoveSpecialChars,
  compareStringsIgnoreCaseAndRemoveSpecialChars,
} from "utils/functions";
import { Box } from "@mui/material";
import { useClientConfig } from "hooks/useClientConfig";

const useStyles = makeStyles(styles as any);

const getTabsConfig = (hasRealmRole: any, canProcess: boolean) => {
  const tabs = [
    {
      id: "workers",
      title: "worker-profile-general",
    },
  ];

  if (
    (hasRealmRole("Health SME") || hasRealmRole("Human Resources - Org Data Structure")) &&
    canProcess
  )
    tabs.push({
      id: "workers2",
      title: "worker-profile-extra-data",
    });

  tabs.push({
    id: "workers3",
    title: "worker-profile-history-log",
  });

  return tabs;
};

const drawBadgeBasedOnStatus = (workItem: any, t: any) => {
  if (
    checkIfStringInArrayIgnoreCaseAndRemoveSpecialChars(workItem?.result, [
      WorkItemResultEnum.PENDING,
      WorkItemResultEnum.IN_PROGRESS,
    ])
  )
    return <Badge color='info'>{workItem?.result}</Badge>;

  if (
    checkIfStringInArrayIgnoreCaseAndRemoveSpecialChars(workItem?.result, [
      WorkItemResultEnum.APPROVED,
      WorkItemResultEnum.HOMOLOGADO,
    ])
  )
    return <Badge color='success'>{WorkItemResultEnum.APPROVED}</Badge>;

  return (
    <Badge
      color={
        checkIfStringInArrayIgnoreCaseAndRemoveSpecialChars(workItem?.result, [
          WorkItemResultEnum.APROVADO_MANUALMENTE,
          WorkItemResultEnum.HOMOLOGADO_MANUALMENTE,
        ])
          ? "success"
          : "danger"
      }
    >
      <Tooltip
        title={
          <div>
            <div>{workItem?.manualValidation?.approved ? t("approved") : t("rejected")}</div>
            <div>{`${t("observation")}: ${workItem?.manualValidation?.observation}`}</div>
            <div>{`${t("expiration-date")}: ${moment(
              workItem?.manualValidation?.expirationDate
            ).format("DD/MM/YYYY")}`}</div>
            <div>{`${t("approved-by")}: ${workItem?.manualValidation?.user}`}</div>
          </div>
        }
        disableHoverListener={
          !checkIfStringInArrayIgnoreCaseAndRemoveSpecialChars(workItem?.result, [
            WorkItemResultEnum.APROVADO_MANUALMENTE,
            WorkItemResultEnum.HOMOLOGADO_MANUALMENTE,
            WorkItemResultEnum.REJEITADO_MANUALMENTE,
          ])
        }
      >
        <span>{t(workItem?.result)}</span>
      </Tooltip>
    </Badge>
  );
};

const canShowDownloadAction = (processes: any[]): boolean =>
  !(
    processes?.find((process) => process?.type === "PD-0001" || process?.type === "PD-0004") ||
    (processes?.find((process) => process?.type === "PD-0007") &&
      processes?.find((process) => process?.type === "PD-0008"))
  );

export default function WorkOrderProfile() {
  const classes = useStyles();
  const { subscription } = useSubscription();
  const { realmURI } = useClientConfig();

  const location = useLocation();
  const navigate = useNavigate();
  const refId = new URLSearchParams(window.location.search).get("id");
  const { t } = useTranslation("worker-profile");
  const { hasRealmRole } = useAuth();
  const { keycloak } = useKeycloak();

  const [notification, setNotification] = useState({
    open: false,
    message: "",
    color: "primary",
  });

  const [modal, setModal] = useState(false);

  const [analyzedContent, setAnalyzedContent] = useState([]);
  const [document, setDocument] = useState();

  const [currentWorkOrder, setCurrentWorkOrder] = useState(
    new URLSearchParams(window.location.search).get("id")
  );

  const [openManualValidationModal, setOpenManualValidationModal] = useState(false);
  const [canProcess, setCanProcess] = useState(false);
  const [workItemSelected, setWorkItemSelected] = useState("");
  const [isExpanded, setIsExpanded] = useState(false);
  const { data: getFormStatus } = useGetFormStatus("WORK_ORDER_PROFILE_POSITION", refId);

  const [tabs, setTabs] = useState([
    {
      id: "workers",
      title: "worker-profile-general",
    },
    {
      id: "workers3",
      title: "worker-profile-history-log",
    },
  ]);

  useEffect(() => {
    setTabs(getTabsConfig(keycloak.hasRealmRole, getFormStatus?.canProcess));
    setCanProcess(getFormStatus?.canProcess);
  }, [getFormStatus?.canProcess]);

  useEffect(() => {
    if (subscription) {
      setCurrentWorkOrder(new URLSearchParams(window.location.search).get("id"));
    }
  }, [subscription, location.search]);

  const { data: workOrderSensitiveData, isLoading: workOrderSensitiveDataLoading } =
    useSensitiveData({
      formIdentifier: "WORK_ORDER_PROFILE",
      refId,
    });

  const { data: workOrderProfilePosition, isLoading: workOrderProfilePositionLoading } =
    useSensitiveData({
      formIdentifier: "WORK_ORDER_PROFILE_POSITION",
      refId,
    });

  const { data: workOrderActivityItems, isFetching: isFetchingWorkOrderActivityItems } =
    useWorkOrderActivityItemsWithStatus(refId);

  const { data: workOrderProcess, isFetching: isFetchingWorkOrderProcess } =
    useWorkOrderProcessTableQuery(refId);

  const { data: workOrderData, isFetching: isLoadingWorkerOrderData } =
    useWorkOrderByIdQuery(currentWorkOrder);

  useEffect(() => {
    if (workOrderData) {
      if (Object.keys(workOrderData).length == 0) navigateToWorkerNotFoundPage();

      if (
        workOrderData?.customFields?.find(
          (cf: any) => cf.name === "SOW Worker Type" && cf.value === "Subcontractor Company"
        )
      ) {
        setTabs([
          {
            id: "workers",
            title: "worker-profile-general",
          },
          {
            id: "workers2",
            title: "worker-profile-extra-data",
          },
          {
            id: "workers3",
            title: "worker-profile-history-log",
          },
        ]);
      }
    }
  }, [workOrderData]);

  const drawProcessTableRow = (workItem: any) => {
    return {
      code: workItem.type ? workItem.type.toUpperCase() : "Activity Item sin código",
      actionID: workItem?.activityItem?.activityItemAction,
      documentName: workItem?.documents ? (
        workItem?.documents.length
      ) : (
        <Badge color='warning'>No hay documentos analizados</Badge>
      ),
      status: <Badge color='info'>{t(workItem.status)}</Badge>,
      result: drawBadgeBasedOnStatus(workItem, t),
      date: moment(workItem.updatedAt).format("DD/MM/YYYY HH:mm"),
      actions: (
        <div className='actions-right'>
          {workItem.documents
            ? workItem.documents.map((doc: any) => (
                <Tooltip title={doc.name}>
                  <ButtonTS
                    justIcon
                    round
                    color='info'
                    href={doc.url}
                    download
                    disabled={!doc.url}
                    target='_blank'
                  >
                    <CloudDownloadIcon />
                  </ButtonTS>
                </Tooltip>
              ))
            : ""}
          <ButtonTS
            justIcon
            round
            color='success'
            target='_blank'
            onClick={() => handleAnalyzeDocument(workItem?.analyzes, workItem?.documents[0])}
            disabled={!workItem?.analyzes?.length}
          >
            <TableChartIcon />
          </ButtonTS>
          {!workItem.approved &&
            hasRealmRole(["Human Resources - Org Data Structure"]) &&
            !compareStringsIgnoreCaseAndRemoveSpecialChars(
              workItem.status,
              WorkItemStatusEnum.REJEITADO_MANUALMENTE
            ) &&
            !compareStringsIgnoreCaseAndRemoveSpecialChars(
              workItem.status,
              WorkItemStatusEnum.PENDING
            ) && (
              <ButtonTS
                justIcon
                round
                color='info'
                target='_blank'
                disabled={workItem?.result === WorkItemResultEnum.PENDING}
                onClick={() => {
                  setWorkItemSelected(workItem._id);
                  setOpenManualValidationModal(true);
                }}
              >
                <ApprovalIcon />
              </ButtonTS>
            )}
        </div>
      ),
    };
  };

  const handleAnalyzeDocument = (analyzes: any, document: any) => {
    setAnalyzedContent(analyzes);
    setDocument(document);
    setModal(true);
  };

  const onManualValidationFail = () => {
    setNotification({
      open: true,
      color: "danger",
      message: t("manual-validation-fail"),
    });
  };

  const onManualValidationSuccess = () => {
    setNotification({
      open: true,
      color: "success",
      message: t("manual-validation-success"),
    });
  };

  const navigateToWorkerNotFoundPage = () => {
    navigate(`./not-found?id=${refId}`, { replace: true });
  };

  const steps = {
    "Sensitive Data": workOrderData?.sensitiveDataStatus?.WORK_ORDER_PROFILE,
    "Docs Uploaded": canProcess,
    "HR Fields": workOrderData?.sensitiveDataStatus?.WORK_ORDER_PROFILE_POSITION,
    OREP: workOrderData?.sensitiveDataStatus?.WORK_ORDER_APPROVE_OREP,
    Completed:
      workOrderData?.sensitiveDataStatus?.WORK_ORDER_PROFILE &&
      workOrderData?.sensitiveDataStatus?.WORK_ORDER_PROFILE_POSITION &&
      workOrderData?.sensitiveDataStatus?.WORK_ORDER_APPROVE_OREP,
  };

  const handleChange = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div>
      <ManualValidationModal
        open={openManualValidationModal}
        OnSuccess={onManualValidationSuccess}
        OnFail={onManualValidationFail}
        workOrderOrWorkerID={refId}
        setOpen={setOpenManualValidationModal}
        workItemID={workItemSelected}
      />

      <Snackbar
        place='bc'
        color={notification.color}
        icon={AddAlert}
        message={notification.message}
        open={notification.open}
        closeNotification={() => setNotification({ open: false, message: "", color: "primary" })}
        close
      />

      <AnalysisModal
        analysis={analyzedContent}
        setModal={setModal}
        modal={modal}
        document={document}
      />

      <GridContainer>
        <GridItemTS xs={12} sm={12} md={9} style={{ marginBottom: "0px" }}>
          <TabSystem tabs={tabs}>
            <TabContent value='workers'>
              <GridContainer>
                <GridItemTS xs={12}>
                  <ProfileSummary
                    statusObj={{}}
                    data={{ ...workOrderData }}
                    isLoading={isLoadingWorkerOrderData}
                    classes={classes}
                    entityType='workOrder'
                  />
                  <Card>
                    <CardBody>
                      <div
                        style={{
                          padding: "10px 20px 20px 20px",
                        }}
                      >
                        {isLoadingWorkerOrderData ? (
                          <CustomSkeleton numberOfLines={1} height={40} width={200} />
                        ) : (
                          !workOrderData?.approvalAvailable && (
                            <Box
                              display='flex'
                              style={{ gap: "5px", marginBottom: "30px" }}
                              alignItems='center'
                            >
                              <Badge color={"warning"}>
                                {t("waiting-for-approval-on-fieldGlass")}
                              </Badge>
                            </Box>
                          )
                        )}

                        {isLoadingWorkerOrderData ? (
                          <CustomSkeleton numberOfLines={1} height={40} />
                        ) : (
                          <Stepper steps={steps} />
                        )}
                      </div>
                    </CardBody>
                  </Card>
                </GridItemTS>
                {workOrderSensitiveDataLoading ? (
                  <GridItemTS xs={12}>
                    <Card>
                      <Box p={4}>
                        <CustomSkeleton numberOfLines={1} height={40} />
                      </Box>
                    </Card>
                  </GridItemTS>
                ) : (
                  <GridItemTS xs={12}>
                    <Accordion
                      expanded={isExpanded}
                      onChange={handleChange}
                      style={{ margin: "16px 0px" }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreRounded />}
                        aria-controls='panel1a-content'
                        id='panel1a-header'
                      >
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <Typography>{"Sensitive Data"}</Typography>

                          {!workOrderData?.sensitiveDataStatus?.WORK_ORDER_PROFILE ? (
                            <Box style={{ marginLeft: "20px" }}>
                              <Badge color={"warning"}>{t("pending")}</Badge>
                            </Box>
                          ) : (
                            <Box style={{ marginLeft: "20px" }}>
                              <Badge color={"success"}>{t("completed")}</Badge>
                            </Box>
                          )}
                        </Box>
                      </AccordionSummary>

                      <AccordionDetails>
                        <WOSensitiveDataForm
                          isAllSensitiveDataLoading={workOrderSensitiveDataLoading}
                          sensitiveData={workOrderSensitiveData}
                          context={{ dateOfBirth: workOrderData?.dateOfBirth }}
                        />
                      </AccordionDetails>
                    </Accordion>
                  </GridItemTS>
                )}
                <GridItemTS xs={12}>
                  <ActivityItemsWorker
                    canShowDownloadAction={canShowDownloadAction(workOrderProcess)}
                    workerData={workOrderData}
                    data={workOrderActivityItems}
                    isFetching={isFetchingWorkOrderActivityItems}
                    enableUpload={steps["Sensitive Data"] === true}
                  />
                </GridItemTS>
                <GridItemTS xs={12}>
                  <ProcessTable
                    classes={classes}
                    processData={
                      workOrderProcess?.map((workOrderProcess: any) =>
                        drawProcessTableRow(workOrderProcess)
                      ) ?? []
                    }
                    isProcessLoading={isFetchingWorkOrderProcess}
                  />
                </GridItemTS>
              </GridContainer>
            </TabContent>
            <TabContent value='workers2'>
              <GridContainer>
                <GridItemTS xs={12}>
                  <WOHRFields
                    type='workOrder'
                    sowID={workOrderData?.sowID}
                    workOrder={workOrderData}
                    sensitiveData={workOrderProfilePosition}
                    isAllSensitiveDataLoading={workOrderProfilePositionLoading}
                  />
                </GridItemTS>
              </GridContainer>
            </TabContent>
            <TabContent value='workers3'>
              <GridContainer>
                <GridItemTS xs={12}>
                  <WorkOrdersRelated />
                </GridItemTS>
              </GridContainer>
            </TabContent>
          </TabSystem>
        </GridItemTS>
        <GridItemTS xs={12} sm={12} md={3}>
          <Card profile>
            <CardAvatar profile>
              <a onClick={(e) => e.preventDefault()}>
                <img src={avatar} alt='...' />
              </a>
            </CardAvatar>
            <CardBody profile>
              <h4 className={classes.cardTitle}>
                {workOrderData?.firstName} {workOrderData?.lastName}
              </h4>
              {workOrderData?.workOrderStatus === ProfileStatusEnum.CONFIRMED ||
              workOrderData?.workOrderStatus === ProfileStatusEnum.ACTIVE ? (
                <Badge color='success'>{workOrderData?.workOrderStatus}</Badge>
              ) : workOrderData?.workOrderStatus === ProfileStatusEnum.PENDING_APPROVAL ? (
                <Badge color='warning'>{workOrderData?.workOrderStatus}</Badge>
              ) : (
                <Badge color='danger'>{workOrderData?.workOrderStatus}</Badge>
              )}
              <p className={classes.description}>{t("card-description")}</p>
              <ButtonTS
                color='warning'
                round
                href={`${realmURI}/work_order_detail.do?id=${workOrderData?.workOrderZID}`}
                target='_blank'
              >
                {t("access-fieldglass")}
              </ButtonTS>
              <FieldglassInfo
                title='Worker Info'
                fieldGlassData={{ ...workOrderData }}
                remapper={workerOrderRemap}
              />
            </CardBody>
          </Card>
        </GridItemTS>
      </GridContainer>
    </div>
  );
}
