import { useQuery } from "@tanstack/react-query";
import apiInputService from "services/apiInputService";
import { DocumentAttachment, DocumentAttachmentQueryKey } from "./@types";

const fetchDocumentAttachmentsByActivityCode = async (
  queryKey: DocumentAttachmentQueryKey
): Promise<DocumentAttachment> => {
  const { activityItemCode, refID } = queryKey;

  const { data } = await apiInputService.get(
    `/documentattachment/SFGId/${refID}/${activityItemCode}`
  );

  return data;
};

export const useDocumentAttachmentsFromActivityItem = (queryKey: DocumentAttachmentQueryKey, options?: any) => {
  const keys = Object.values(queryKey);

  return useQuery<DocumentAttachment, Error>(
    ["document-attachments", ...keys],
    () => fetchDocumentAttachmentsByActivityCode(queryKey),
    {
      staleTime: 0,
      ...options
    }
  );
};
