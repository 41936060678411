import { useMutation } from "@tanstack/react-query";
import apiInputService from "services/apiInputService";
import { RejectHrFieldsPayload } from "./types";

const rejectHrFields = async (payload: RejectHrFieldsPayload) => {
  const { data } = await apiInputService.post("/workOrders/reject/", payload);

  return data;
};

export const useRejectHrFields = (options?: any) => {
  return useMutation(rejectHrFields, {
    ...options,
  });
};
