/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Grid, TextField, Typography } from "@mui/material";
import CustomDialog from "components/CustomDialog/CustomDialog";
import { useForm } from "react-hook-form";

type RejectHrFieldsModalProps = {
  open: boolean;
  handleClose: () => void;
  handleSubmit: (comment: string) => void;
};

export default function RejectHrFieldsModal({
  open,
  handleClose,
  handleSubmit,
}: RejectHrFieldsModalProps) {
  const { watch, register } = useForm();

  const buttonProps: any = {
    color: "primary",
    onClick: () => handleSubmit(watch("comment")),
  };

  return (
    <>
      <CustomDialog
        handleClose={handleClose}
        open={open}
        dialogTitle={
          <>
            <Typography
              style={{
                fontWeight: 700,
                fontSize: 18,
                textAlign: "center",
              }}
            >
              Reject Work Order
            </Typography>
          </>
        }
        dialogContent={
          <>
            <Grid container spacing={2} style={{ width: "100%" }}>
              <Grid item xs={12}>
                <TextField
                  variant='standard'
                  label='Comment'
                  multiline
                  style={{ width: "100%" }}
                  value={watch("comment") || ""}
                  required
                  {...register("comment", { required: true })}
                />
              </Grid>
            </Grid>
          </>
        }
        dialogAction={
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "end",
              padding: 10,
            }}
          >
            <Button {...buttonProps}>CONFIRM</Button>
          </div>
        }
      />
    </>
  );
}
