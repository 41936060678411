import { makeStyles } from '@material-ui/core/styles'

// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItemTS'

import Button from 'components/CustomButtons/ButtonTS'
import { useNavigate } from 'react-router-dom'

const styles: any = {
    calloutContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: "16px",
        width: '100%',
        padding: '8px 16px',
        borderRadius: '8px',
        border: '1px solid #E0E0E0',
        backgroundColor: '#F5F5F5',
    },
    calloutTitle: {
        fontWeight: "bold",
    },
    calloutText: {
        fontSize: "14px",
    },
}

const useStyles = makeStyles(styles);

export default function ProfileNotFound() {
    const classes = useStyles();
    const refID = new URLSearchParams(window.location.search).get('id') ?? ''
    const navigate = useNavigate();

    const navigateToWorkerList = () => {
        navigate('../../list')
    }

    return (
        <>
            <GridContainer>
                <GridItem
                    xs={12}
                    style={{ marginBottom: '0px' }}
                >
                    <div className={classes.calloutContainer}>
                        <div>
                            <h4 className={classes.calloutTitle}>{`Worker not found`}</h4>
                            <h5 className={classes.calloutText}>{`Appears that this worker ${refID} is not in our database. Please, verify if the Worker ID is correct and try again.`}</h5>

                        </div>
                        <div>
                            <Button onClick={()=> navigateToWorkerList()} color="primary" size="lg">
                                Return to Workers List
                            </Button>
                        </div>
                    </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={3}></GridItem>
            </GridContainer>
        </>
    )
}
