import { useQuery } from "@tanstack/react-query";
import apiInputService from "services/apiInputService";

const fetch = async () => {
  const { data } = await apiInputService.get(`/app-configs/realmUri`);
  return data as { realmUri: string };
};

export const useGetRealmUri = (subscription: string) => {
  return useQuery(["realmUri", subscription], fetch, {
    staleTime: Infinity,
    cacheTime: Infinity,
    refetchOnWindowFocus: false,
    enabled: !!subscription,
  });
};
