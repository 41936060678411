import { Breadcrumbs as MUIBreadcrumbs, Link, Typography } from "@material-ui/core";
import withRouter from "components/ComponentWithRouterProp/ComponentWithRouterProp";
import { NavigateNext } from "@material-ui/icons";

const Breadcrumbs = (props: any) => {
  const { navigate, location } = props.router;
  const pathnames = location?.pathname?.split("/").filter((x: string) => x);

  return (
    <MUIBreadcrumbs aria-label='breadcrumb' separator={<NavigateNext fontSize='small' />}>
      {pathnames?.map((name: string, index: number) => {
        let routeTo = `/${pathnames?.slice(0, index + 1).join("/")}`;

        if (routeTo === "/app") routeTo = "/app/dashboard";
        if (name === "app") name = "Home";
        if (name === "not-found") name = "Not Found";
        if (name === "no-access-permission") name = "Access Denied";

        const isLast = index === pathnames?.length - 1;
        return isLast ? (
          <Typography key={name}>{name}</Typography>
        ) : (
          <Link style={{ cursor: "pointer" }} key={name} onClick={() => navigate(routeTo)}>
            {name}
          </Link>
        );
      })}
    </MUIBreadcrumbs>
  );
};

export default withRouter(Breadcrumbs);
