import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

import {
  CircularProgress,
  Icon,
  InputAdornment,
  makeStyles,
} from "@material-ui/core";
import { AddAlert } from "@material-ui/icons";

import uverifyLogo from "assets/img/uverify-logo.jpg";

import CustomInput from "components/CustomInput/CustomInput";
import Snackbar from "components/Snackbar/Snackbar";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

import {
  Container,
  ContentActions,
  ContentForm,
  ContentLogo,
  ResetPasswordButton,
} from "./ResetPasswordForm.styles";
import { useNavigate, useParams } from "react-router-dom";
import { useResetPassword } from "services/input-core/auth/reset-password";


const useStyles = makeStyles(styles as any);

const ResetPasswordForm: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { token } = useParams();

  const { t } = useTranslation("login-page");

  const [notification, setNotification] = React.useState({
    open: false,
    message: "",
    color: "primary",
  });

  const resetPasswordSchema = z
    .object({
      password: z.string().min(6, { message: t("password-length-error") }),
      confirmPassword: z
        .string()
        .min(6, { message: t("confirm-password-error") }),
    })
    .refine((data: { password: any; confirmPassword: any; }) => {
      if (data.password !== data.confirmPassword) {
        throw new Error(t("passwords-do-not-match"));
      }

      return true;
    });

  type ResetPasswordFormFields = z.infer<typeof resetPasswordSchema>;

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<ResetPasswordFormFields>({
    resolver: zodResolver(resetPasswordSchema),
  });

  const { mutate: resetPassword } = useResetPassword();

  const [loading, setLoading] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const onSubmit = async (data: ResetPasswordFormFields) => {
    setLoading(true);

    try {
      await resetPasswordSchema.parse(data);
      resetPassword(
        {
          password: data.password,
          confirmPassword: data.confirmPassword,
          token: token,
        },
        {
          onSuccess: () => {
            setNotification({
              open: true,
              color: "success",
              message: t("reset-password-success", { ns: "login-page" }),
            });

            navigate("/auth/login");
          },
          onError: () => {
            setNotification({
              open: true,
              color: "danger",
              message: t("reset-password-success", { ns: "login-page" }),
            });
          },
        },
      );
    } catch (validationError) {
      if (validationError instanceof z.ZodError) {
        validationError.errors.forEach((error: { message: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | ((props: any) => React.ReactNode); }) => {
          setNotification({
            open: true,
            color: "warning",
            message: error.message.toString(),
          });
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>

      <Snackbar
        place='bc'
        color={notification.color}
        icon={AddAlert}
        message={notification.message}
        open={notification.open}
        closeNotification={() => setNotification({ open: false, message: "", color: "primary" })}
        close
      />

      <ContentLogo>
        <img src={uverifyLogo} alt="U-verify Logo" />
      </ContentLogo>

      <form onSubmit={handleSubmit(onSubmit)}>
        <ContentForm>
          <CustomInput
            labelText={t("password")}
            id="password"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              ...register("password", { required: true }),
              endAdornment: (
                <InputAdornment position="end" style={{ cursor: "pointer" }}>
                  <Icon
                    className={classes.inputAdornmentIcon}
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? "lock_open" : "lock"}
                  </Icon>
                </InputAdornment>
              ),
              type: showPassword ? "text" : "password",
              autoComplete: "off",
            }}
          />
          {errors.password && (
            <span className="error-message">{errors.password.message}</span>
          )}

          <CustomInput
            labelText={t("confirm-password")}
            id="confirm-password"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              ...register("confirmPassword", { required: true }),
              endAdornment: (
                <InputAdornment position="end" style={{ cursor: "pointer" }}>
                  <Icon
                    className={classes.inputAdornmentIcon}
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? "lock_open" : "lock"}
                  </Icon>
                </InputAdornment>
              ),
              type: showPassword ? "text" : "password",
              autoComplete: "off",
            }}
          />
          {errors.confirmPassword && (
            <span className="error-message">
              {errors.confirmPassword.message}
            </span>
          )}
        </ContentForm>

        <ContentActions>
          <ResetPasswordButton
            variant="contained"
            disabled={loading}
            type="submit"
          >
            {loading ? <CircularProgress size={20} /> : t("confirm")}
          </ResetPasswordButton>
        </ContentActions>
      </form>
    </Container>
  );
};

export default ResetPasswordForm;
