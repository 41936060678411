import { CircularProgress, makeStyles } from "@material-ui/core";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import Table from "components/ReactTable/ReactTable";
import { Group } from "@material-ui/icons";
import { cardTitle } from "assets/jss/material-dashboard-pro-react";
import { useActivityItemsFromSOW } from "services/input-core/activity-items/get-activity-items-from-sow";
import { useTranslation } from "react-i18next";
import { sortStatusWithBadge } from "helpers/customSortMethods";

const styles: any = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  cardStatusText: {
    marginTop: "10px",
    marginBottom: "0px",
    fontWeight: "400",
    lineHeight: "1",
  },
  cardSelect: {
    width: "200px",
    marginBottom: "10px",
    fontWeight: "400",
    lineHeight: "1",
  },
  cardBadge: {
    marginTop: "5px",
    marginBottom: "5px",
    paddingLeft: "10px",
  },
  cardStatusBlock: {
    width: "600px",
    display: "flex",
  },
};

const useStyles = makeStyles(styles);

const columns = [
  {
    Header: "User Role",
    accessor: "activityItemActorUserRole",
  },
  {
    Header: "Code",
    accessor: "activityItemCode",
  },
  {
    Header: "Comp",
    accessor: "activityItemCompletionType",
  },
  {
    Header: "Desc",
    accessor: "activityItemDescription",
  },
  {
    Header: "Due Date",
    accessor: "activityItemDueDate",
  },
  {
    Header: "Send Date",
    accessor: "activityItemSendWorkItemDate",
  },
  {
    Header: "Status",
    accessor: "activityItemStatus",
    sortType: sortStatusWithBadge,
  },
  {
    Header: "Type",
    accessor: "activityItemType",
  },
  {
    Header: "Actions",
    accessor: "actions",
  },
];

export default function ActivityItemsSow() {
  const classes = useStyles();

  const refId = new URLSearchParams(window.location.search).get("id");
  const { data, isLoading } = useActivityItemsFromSOW(refId);
  const { t } = useTranslation("activity-item-table");

  return (
    <Card>
      <CardHeader color='rose' icon>
        <CardIcon color='rose'>
          <Group />
        </CardIcon>
        <h4 className={classes.cardIconTitle}>{t("activityItems")}</h4>
      </CardHeader>
      <CardBody>
        {isLoading ? (
          <CircularProgress style={{ margin: 10 }} />
        ) : (
          <Table columns={columns} data={data ?? []} />
        )}
      </CardBody>
    </Card>
  );
}
