import { useMutation } from "@tanstack/react-query";
import apiInputService from "services/apiInputService";

type ResetPasswordPayload = {
  password: string;
  confirmPassword: string;
  token: string;
};

const resetPassword = async (resetPasswordPayload: ResetPasswordPayload) => {
  return await apiInputService.put(
    "/users/resetPassword",
    resetPasswordPayload
  );
};

export const useResetPassword = (options?: any) => {
  return useMutation(resetPassword, {
    ...options,
  });
};
