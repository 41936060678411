import { SxProps, Theme } from "@mui/material";

const actionsStyle: SxProps<Theme> = {
  display: "flex",
  justifyContent: "center",
};

const buyBtnStyle: SxProps<Theme> = {
  width: "140px",
};

const modalHeader: SxProps<Theme> = {
  background: "#08558d",
  color: "white",
  minWidth: "89%",
  borderRadius: "10px",
  textAlign: "center",
  paddingLeft: "50px",
  paddingRight: "50px",
  paddingTop: "10px",
  top: "-40px",
  position: "absolute",
  height: "100px",
};

export { actionsStyle, buyBtnStyle, modalHeader };
