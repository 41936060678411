import { useKeycloak } from "@react-keycloak/web";
import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

export default function PrivateRoutes() {
  const { keycloak, initialized } = useKeycloak();
  const navigate = useNavigate();

  useEffect(() => {
    if (initialized && !keycloak?.authenticated) {
      navigate("../auth/login", {
        replace: true,
        state: { from: window.location.pathname },
      });
    }
  }, [initialized, keycloak?.authenticated]);

  return keycloak?.authenticated ? <Outlet /> : null;
}
