import { useQuery } from "@tanstack/react-query";
import apiInputService from "services/apiInputService";

const fetchBadgeIsValid = async (workerID: string) => {
  try {
    const { data } = await apiInputService.get(`/worker/BadgeIsValid/${workerID}`);

    return data;
  } catch (error) {
    return error?.response;
  }
};

export const useBadgeIsValidQuery = (workerID: string, options?: any) => {
  return useQuery<any>(["badge-blocked", workerID], () => fetchBadgeIsValid(workerID), {
    ...options,
  });
};
