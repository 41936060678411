import styled from "@emotion/styled";
import React from "react";

import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import YouTubeIcon from "@material-ui/icons/YouTube";

interface SidebarFooterProps extends React.HTMLAttributes<HTMLDivElement> {
  collapsed?: boolean;
  stratesys?: string;
  stratesysMini?: string;
}

interface PropsStyledButton {
  type: "youtube" | "linkedin" | "twitter";
}

const StyledButton = styled.a<PropsStyledButton>`
  padding: 2px 4px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  display: flex;
  background-color: #fff;
  color: #484848;
  text-decoration: none;

  &:visited {
    color: #484848;
  }

  &:hover {
    color: ${(props) =>
      props.type == "youtube"
        ? "red"
        : props.type == "linkedin"
        ? "#0E76A8"
        : props.type == "twitter" && "#24a4f3"};
  }
`;

const StyledSidebarFooter = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 8px;
  color: white;
  background: linear-gradient(
    45deg,
    rgb(72, 77, 83) 0%,
    rgb(204, 217, 235) 100%
  );
`;

const StyledCollapsedSidebarFooter = styled.a`
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 0.5rem;
  color: white;
  background: linear-gradient(
    45deg,
    rgb(72, 77, 83) 0%,
    rgb(204, 217, 235) 100%
  );
`;

const stratesysUrl = "https://www.stratesys-ts.com";
const stratesysYoutubeUrl = "https://www.youtube.com/user/stratesys/feed";
const stratesysLinkedInUrl = "https://www.linkedin.com/company/31675/";
const stratesysTwitterUrl = "https://twitter.com/stratesys";

export const SidebarFooter: React.FC<SidebarFooterProps> = ({
  collapsed,
  stratesys,
  stratesysMini,
  ...rest
}) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        paddingBottom: "20px",
      }}
    >
      {collapsed ? (
        <StyledCollapsedSidebarFooter href={stratesysUrl} target="_blank">
          <img
            src={stratesysMini}
            alt="stratesys-mini"
            style={{ width: "2.18rem", verticalAlign: "middle", border: "0" }}
          />
        </StyledCollapsedSidebarFooter>
      ) : (
        <StyledSidebarFooter {...rest}>
          <a
            href={stratesysUrl}
            target="_blank"
            style={{ marginBottom: "12px" }}
          >
            <img
              src={stratesys}
              alt="stratesys"
              style={{
                display: "block",
                width: "5.36rem",
                verticalAlign: "middle",
                border: "0",
                padding: "5px 0px",
              }}
            />
          </a>

          <div style={{ display: "flex", gap: "0.4rem", marginTop: "8px" }}>
            <StyledButton
              href={stratesysYoutubeUrl}
              target="_blank"
              type="youtube"
            >
              <YouTubeIcon />
            </StyledButton>
            <StyledButton
              href={stratesysLinkedInUrl}
              target="_blank"
              type="linkedin"
            >
              <LinkedInIcon />
            </StyledButton>
            <StyledButton
              href={stratesysTwitterUrl}
              target="_blank"
              type="twitter"
            >
              <TwitterIcon />
            </StyledButton>
          </div>
        </StyledSidebarFooter>
      )}
    </div>
  );
};
