import { useQuery } from "@tanstack/react-query";
import apiInputService from "services/apiInputService";

const fetchWorkersBySowID = async (sowID: string) => {
  const workers = await apiInputService.get(`/Fieldglass/worker/sow/${sowID}`);
  const { data } = workers;

  return data;
};

export const useWorkersBySowIDQuery = (sowID: string, options?: any) => {
  return useQuery<any[]>(["workers-sow", sowID], () => fetchWorkersBySowID(sowID), {
    ...options,
  });
};
