import { useKeycloak } from "@react-keycloak/web";
import { useAuth } from "hooks/useAuth";
import { PrefetchingScreen } from "layouts/Prefetcher";
import { useEffect } from "react";
import { useNavigate, Outlet } from "react-router-dom";

export default function CheckSSO() {
  const { keycloak, initialized } = useKeycloak();
  const { getUserInfo } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (initialized && keycloak?.authenticated) {
      getUserInfo().then((userInfo: any) => {
        if (userInfo?.jsonCustomAttributes) navigate("/app/dashboard");
        else navigate("/blocked");
      });
    }
  }, [initialized, keycloak]);

  return !keycloak?.authenticated ? <Outlet /> : <PrefetchingScreen />;
}
