import { useMutation } from "@tanstack/react-query";
import apiInputService from "services/apiInputService";

const deleteATOStatus = async (workerId: string) => {
  const { data } = await apiInputService.delete(`/accessToOperation/${workerId}`);

  return data;
};

export const useDeleteATOStatus = (options?: any) => {
  return useMutation(deleteATOStatus, {
    ...options,
  });
};
