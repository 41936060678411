import React from "react";
import cx from "classnames";
import { Outlet, useLocation } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import SidebarPro from "components/SidebarPro";

import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";
import sidebar2 from "assets/img/sidebar-2.jpg";
import uVerifyMarcaNegativa from "assets/img/uVerifyMarcaNegativa.png";
import uVerify_S_TextoNegativo from "assets/img/uVerify_S_TextoNegativo.png";
import StratesysLogo from "assets/img/stratesys.svg";
import StratesysMiniLogo from "assets/img/str.svg";

let ps: PerfectScrollbar;

const useStyles = makeStyles(styles as any);

export default function Dashboard(props: { [x: string]: any; routes?: any }) {
  const { pathname } = useLocation();

  const { ...rest } = props;
  const routes = props.routes;

  const [image] = React.useState(sidebar2);
  const [logo] = React.useState(uVerifyMarcaNegativa);
  const [logoText] = React.useState(uVerify_S_TextoNegativo);
  const [logoStratesys] = React.useState(StratesysLogo);
  const [logoStratesysMini] = React.useState(StratesysMiniLogo);

  const classes = useStyles();
  const mainPanelClasses =
    classes.mainPanel +
    " " +
    cx({
      [classes.mainPanelWithPerfectScrollbar]: navigator.platform.indexOf("Win") > -1,
    });
  // ref for main panel div
  const mainPanel = React.createRef<any>();

  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
    };
  }, []);

  React.useEffect(() => {
    mainPanel.current.scrollTop = 0;
    if (navigator.platform.indexOf("Win") > -1) ps.update();
  }, [pathname]);

  return (
    <div className={classes.wrapper}>
      <SidebarPro
        propsRoutes={routes}
        image={image}
        logo={logo}
        logoText={logoText}
        logoStratesys={logoStratesys}
        logoStratesysMini={logoStratesysMini}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
          }}
          className={mainPanelClasses}
          ref={mainPanel}
        >
          <AdminNavbar {...rest} />
          {
            <div className={classes.content}>
              <div className={classes.container}>
                <Outlet />
              </div>
            </div>
          }
          <Footer fluid />
        </div>
      </SidebarPro>
    </div>
  );
}
