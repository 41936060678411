import styled from "@emotion/styled";
import { MenuItemStyles, menuClasses } from "react-pro-sidebar";

// hex to rgba converter
export const hexToRgba = (hex: string, alpha: number) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const themes = {
  light: {
    sidebar: {
      backgroundColor: "#ffffff",
      color: "#607489",
    },
    menu: {
      menuContent: "#fbfcfd",
      icon: "#0098e5",
      hover: {
        backgroundColor: "#c5e4ff",
        color: "#44596e",
      },
      disabled: {
        color: "#9fb6cf",
      },
      active: {
        backgroundColor: "#0098e5",
        color: "#eeeeee",
      },
    },
  },
  dark: {
    sidebar: {
      backgroundColor: "#323a54",
      color: "#ffffff",
    },
    menu: {
      menuContent: "#082440",
      icon: "#eeeeee",
      hover: {
        backgroundColor: "#00458b",
        color: "#ffffff",
      },
      disabled: {
        color: "#3e5e7e",
      },
      active: {
        backgroundColor: "#165fa2",
        color: "#ffffff",
        hover: {
          backgroundColor: "#00458b",
          color: "#eeeeee",
        },
      },
    },
  },
};

export const menuItemStyles = (theme: "dark" | "light", collapsed: boolean, hasImage: boolean) => {
  const stylesItems: MenuItemStyles = {
    root: {
      fontSize: "0.9rem",
      fontWeight: 400,
    },
    icon: {
      color: themes[theme].menu.icon,
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme].menu.disabled.color,
      },
    },
    SubMenuExpandIcon: {
      color: "#b6b7b9",
    },
    subMenuContent: ({ level }: { level: number }) => ({
      backgroundColor:
        level === 0
          ? hexToRgba(themes[theme].menu.menuContent, hasImage && !collapsed ? 0.4 : 1)
          : "transparent",
    }),
    button: {
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme].menu.disabled.color,
      },
      "&:hover": {
        backgroundColor: hexToRgba(themes[theme].menu.hover.backgroundColor, hasImage ? 0.8 : 1),
        color: themes[theme].menu.hover.color,
        boxShadow: "0 10px 20px rgba(0, 0, 0, 0.2)",
        // transition: "all 0.3s ease-in-out",
      },
      [`&.active`]: {
        fontWeight: 500,
        backgroundColor: themes[theme].menu.active.backgroundColor,
        color: themes[theme].menu.active.color,
        svg: {
          color: themes[theme].menu.active.color,
        },
        "&:hover": {
          backgroundColor: hexToRgba(themes[theme].menu.hover.backgroundColor, hasImage ? 0.8 : 1),
          color: themes[theme].menu.hover.color,
          svg: {
            color: themes[theme].menu.hover.color,
          },
        },
      },
    },
    label: ({ open }: { open?: boolean }) => ({
      fontWeight: open ? 600 : undefined,
    }),
  };
  return stylesItems;
};

interface IPropsWrapperSidebar {
  rtl: boolean;
  backgroundScroll: string;
}

export const WrapperSidebar = styled.div<IPropsWrapperSidebar>`
  /* Works on Firefox */
  .sidebar {
    scrollbar-width: thin;
    scrollbar-color: ${(props) => `#c5c5c5 ${props.backgroundScroll}`};

    /* Works on Chrome, Edge, and Safari */
    *::-webkit-scrollbar {
      width: 10px;
    }

    *::-webkit-scrollbar-track {
      background: ${(props) => `${props.backgroundScroll}`};
    }

    *::-webkit-scrollbar-thumb {
      padding: 4px;
      background-color: #c5c5c5;
      background-size: contain;
      border-radius: 20px;
      border: 3px solid ${(props) => `${props.backgroundScroll}`};
    }
  }

  position: relative;
  display: flex;
  height: 100%;
  /* direction: ${(props) => (props.rtl ? "rtl" : "ltr")}; */
`;

interface IPropsGroupsMenu {
  collapsed: boolean;
}

export const GroupsMenu = styled.div<IPropsGroupsMenu>`
  display: flex;
  flex-direction: column;
  height: 100%;

  .groupTitle {
    padding: 0rem 1.5rem;
    margin-bottom: 0.5rem;
    margin-top: 2rem;
  }

  .groupTitle:first-child {
    margin-top: 0rem;
  }

  .photo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${(props) => (props.collapsed ? "28px" : "34px")};
    height: ${(props) => (props.collapsed ? "28px" : "34px")};
    overflow: hidden;

    border-radius: 50%;
    border: 1px solid #c5c5c5;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
  }

  .avatarImg {
    width: 110%;
    height: 110%;
    vertical-align: middle;
    border: 0;
    object-fit: contain;
    object-position: center;
  }

  .itemIcon {
    color: inherit;
    width: 35px;
    width: 35px;
    float: left;
    position: inherit;

    text-align: center;
    vertical-align: middle;
    opacity: 0.8;
  }
`;

export const SidebarMain = styled.div`
  flex: 1;
  margin-bottom: 2rem;
`;

export const StyledLogo = styled.div<{ rtl?: boolean }>`
  width: 35px;
  min-width: 35px;
  height: 35px;
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  color: white;
  font-size: 24px;
  font-weight: 700;
  background-color: #009fdb;
  background: linear-gradient(45deg, rgb(21 87 205) 0%, rgb(90 225 255) 100%);
  /* ${({ rtl }) =>
    rtl
      ? `
      margin-left: 10px;
      margin-right: 4px;
      `
      : `
      margin-right: 10px;
      margin-left: 4px;
      `} */
`;
