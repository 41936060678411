import { Box, Button, styled } from "@mui/material";

export const Container = styled(Box)`
  width: 400px;
`;

export const ContentLogo = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
`;

export const ContentForm = styled(Box)`
  margin-top: 40px;
`;

export const ContentActions = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const ResetPasswordButton = styled(Button)`
  width: 400px;
  padding: 10px;
  margin-top: 10px;
  background-color: #ff6700;
  border-color: transparent;
  color: #ffffff;

  &:hover {
    background-color: #ff6700;
  }
`;
