import { makeStyles } from "@material-ui/core/styles";

import GridContainer from "components/Grid/GridContainer.js";

import styles from "assets/jss/material-dashboard-pro-react/views/errorPageStyles.js";
import GridItemTS from "components/Grid/GridItemTS";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(styles as never);

export default function ErrorPage() {
  const classes = useStyles();
  const { t } = useTranslation("common");

  return (
    <div className={classes.contentCenter}>
      <GridContainer>
        <GridItemTS md={12}>
          <h2 className={classes.subTitle}>{t("not-found")}</h2>
        </GridItemTS>
      </GridContainer>
    </div>
  );
}
