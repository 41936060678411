import { Box, TextField } from '@mui/material'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Button, CircularProgress } from '@material-ui/core'
import { IFormChangeATOInput } from './interface'
import { useChangeATOStatus } from 'services/input-core/activity-items/change-ato-status'
import { format } from 'date-fns'

type dataProps = {
    showNotification?: (color: string, message: string) => void
    setModalOpen?: React.Dispatch<React.SetStateAction<boolean>>
    workerId: string
    refetchATOStatus?: any
}

const stepsButtonStyle = {
    backgroundColor: '#2f87ee',
    color: 'white',
    width: '140px',
}

export default function ChangeATOModal({
    setModalOpen,
    showNotification,
    refetchATOStatus,
    workerId
}: dataProps) {
    const { mutate, isLoading } = useChangeATOStatus()
    const dateFormatted = new Date();
    const dateFormattedPlus7Days = new Date(dateFormatted.setDate(dateFormatted.getDate() + 7));


    const {
        register,
        handleSubmit,
    } = useForm()

    const handleActivityItemsFormChange: SubmitHandler<
        IFormChangeATOInput
    > = ({ description, expiresAt }: IFormChangeATOInput) => {

        const payload = {
            workerId,
            description,
            expiresAt
        }

        mutate(payload, {
            onSuccess: () => {
                showNotification(
                    'success',
                    'Change Access to Operation successfully!'
                )
                refetchATOStatus()
                setModalOpen(false)
            },
            onError: (error) => {
                showNotification('danger', 'Change Access to Operation failed!')
            },
        })
    }

    return (
        <form onSubmit={handleSubmit(handleActivityItemsFormChange)}>
            <Box
                sx={{
                    marginBottom: '20px',
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <TextField
                    id='description'
                    label='Description'
                    multiline
                    rows={6}
                    fullWidth
                    required
                    variant='outlined'
                    style={{ padding: '10px' }}
                    {...register('description')}
                />
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'start',
                    marginBottom: '20px',
                    marginLeft: '10px',
                }}
            >
                <TextField
                    id='standard-basic'
                    name='expiresAt'
                    InputProps={{ inputProps: { max: format((dateFormattedPlus7Days), "dd-MM-yyyy")}}}
                    variant='standard'
                    disabled={isLoading}
                    label='Expiration Date'
                    type='date'
                    required
                    InputLabelProps={{ shrink: true }}
                    style={{ width: '200px', marginBottom: '10px' }}
                    {...register('expiresAt')}
                />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                <Button
                    disabled={isLoading}
                    variant='contained'
                    style={stepsButtonStyle}
                    type='submit'
                >
                    {isLoading ? (
                        <CircularProgress size={20} color='inherit' />
                    ) : (
                        'Confirm'
                    )}
                </Button>
            </Box>
        </form>
    )
}
