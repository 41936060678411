import { useQuery } from "@tanstack/react-query";
import apiInputService from "services/apiInputService";

const fetchSOW = async (sowID: string) => {
  const sow = await apiInputService.get(`/sow/sow/${sowID}`);

  const { data } = sow;

  return data;
};

export const useSOWQuery = (sowID: string, options?: any) => {
  return useQuery<any>(["sow", sowID], () => fetchSOW(sowID), {
    ...options,
  });
};
