import { useQuery } from "@tanstack/react-query";
import apiInputService from "services/apiInputService";
import { ATOStatusPayload } from "./@types";

const getATOStatus = async (workerId: string) => {
  const { data } = await apiInputService.get<ATOStatusPayload>(`/accessToOperation/${workerId}`);

  return data as ATOStatusPayload;
};

export const useATOStatus = (workerId: string, options?: any) => {
  return useQuery<ATOStatusPayload>(["ato-status", workerId], () => getATOStatus(workerId), {
    ...options,
  });
};
