import * as React from 'react'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { Breakpoint } from '@mui/material'
import { useCustomDialogStyles } from './styles'

interface CustomDialogProps extends DialogProps {
    dialogTitle?: React.ReactNode
    open: boolean
    handleClose: () => void
    dialogContent: React.ReactNode
    dialogAction: React.ReactNode
    maxWidth?: Breakpoint
}

const maxWidthTitleMapper: { [key in Breakpoint]: number } = {
    xs: 300,
    sm: 500,
    md: 700,
    lg: 900,
    xl: 1100,
}

export default function CustomDialog({
    open,
    handleClose,
    dialogTitle,
    dialogContent,
    dialogAction,
    maxWidth,
}: CustomDialogProps) {
    const descriptionElementRef = React.useRef<HTMLElement>(null)

    const classes = useCustomDialogStyles()

    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef
            if (descriptionElement !== null) {
                descriptionElement.focus()
            }
        }
    }, [open])

    return (
        <Dialog
            open={open}
            onClose={() => handleClose()}
            scroll='body'
            fullWidth
            maxWidth={maxWidth ?? 'sm'}
        >
            <DialogTitle id='scroll-dialog-title'>
                <div
                    className={classes.titleDiv}
                    style={{ width: maxWidthTitleMapper[maxWidth ?? 'sm'] }}
                >
                    {dialogTitle}
                </div>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                {dialogContent}
            </DialogContent>
            <DialogActions className={classes.dialogAction}>
                {dialogAction}
            </DialogActions>
        </Dialog>
    )
}
