import axios, { AxiosInstance, AxiosRequestConfig } from "axios";

const token = localStorage.getItem("token");

const config: AxiosRequestConfig = {
  baseURL: `${process.env.REACT_APP_API_AUTH}/auth/realms/${process.env.REACT_APP_API_AUTH_REALM}/protocol/openid-connect`,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
    Authorization: `Bearer ${token}`
  },
};

export const keycloakService: AxiosInstance = axios.create(config);