import { Button, CircularProgress, makeStyles, TextField } from "@material-ui/core";
import { BusinessCenter } from "@material-ui/icons";
import AddAlert from "@material-ui/icons/AddAlert";
import { Box, Grid } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { cardTitle, primaryColor } from "assets/jss/material-dashboard-pro-react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import Snackbar from "components/Snackbar/Snackbar";
import { useSubscription } from "hooks/useSubscription";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { getConfigFromClientConfig } from "services/input-core/clientConfig/get-client-config";
import { FieldsProps } from "services/input-core/clientConfig/type";
import { useCreateSensitiveData } from "services/input-core/sensitive-data/create-sensitive-data";
import { useAuth } from "hooks/useAuth";
import { getStrategy, StrategyArgs } from "helpers/formStrategyHelper/strategy-mapper";
import Searcher from "./Seacher";
import { useTranslation } from "react-i18next";
import CustomSkeleton from "components/CustomSkeleton/CustomSkeleton";
import { useRejectHrFields } from "services/input-core/workers/reject-hr-fields";
import RejectHrFieldsModal from "./RejectHrFieldsModal/RejectHrFieldsModal";
import { WOOrepForm } from "../WOOrepForm/WOOrepForm";
import keycloak from "keycloak";

type HRFieldsProps = {
  sowID?: string;
  children?: React.ReactNode;
  workOrder?: any;
  workOrderId?: string;
  sensitiveData: any;
  isAllSensitiveDataLoading: boolean;
  type?: "workOrder" | "worker";
  canProcess?: boolean;
};

type apiResponseObject = {
  name: {
    default: string;
  };
  value: string;
};

type ApiResponseProps = {
  data: {
    orgUnit: apiResponseObject;
    contractorCategory: apiResponseObject;
    personnelArea: apiResponseObject;
    personnelSubArea: apiResponseObject;
    discipline: apiResponseObject;
    businessArea: apiResponseObject;
    supervisorEmail: apiResponseObject;
    reportToPosition: apiResponseObject;
    responseStatusCode: number;
    location: number;
    businessEntity: number;
    businessOperation: number;
  };
};

export const styles: any = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  cardHeader: {
    display: "flex",
    flexDirection: "column",
  },
  cardHeaderTag: {
    fontSize: "12px",
    fontWeight: "400",
    display: "flex",
    color: primaryColor[0],
    padding: "1px 2px !important",
    border: "1px solid",
    borderColor: primaryColor[0],
    borderRadius: "999px",
    width: "107px",
    height: "18px",
    justifyContent: "center",
    alignItems: "center",
  },
  label: {
    fontSize: "16px",
    fontWeight: "400",
    color: "Black",
    webkitTouchCallout: "none",
    webkitUserSelect: "none",
    khtmlUserSelect: "none",
    mozUserSelect: "none",
    msUserSelect: "none",
    userSelect: "none",
  },
  saveButton: {
    color: primaryColor[0],
    variant: "outlined",
    BorderColor: primaryColor[0],
  },
};

const useStyles = makeStyles(styles);

interface SnackbarPayload {
  color: "success" | "warning" | "danger" | "info";
  message: string;
}

interface ApiResponse {
  data: {
    [key: string]: any;
  };
}

export default function WOHRFields({
  isAllSensitiveDataLoading,
  sensitiveData,
  workOrder,
  workOrderId,
  type,
  canProcess,
}: HRFieldsProps) {
  const {
    register,
    watch,
    formState: { errors },
    setValue,
    reset,
    control,
  } = useForm();

  const classes = useStyles();
  const { subscription } = useSubscription();
  const queryClient = useQueryClient();
  const { hasRealmRole } = useAuth();
  const sessionToken = localStorage.getItem("token");
  const keycloakToken = keycloak.token;

  const { t } = useTranslation("worker-profile");

  const [notification, setNotification] = useState({ open: false, message: "", color: "success" });

  const [clientConfig, setClientConfig] = useState();
  const [getValuesFromApi, setGetValuesFromApi] = useState(null);
  const [isLoadingRequest, setIsLoadingRequest] = useState(null);
  const [allSensitiveData, setAllSensitiveData] = useState({});

  const [showRejectHrFieldsModal, setShowRejectHrFieldsModal] = useState(false);

  useEffect(() => {
    setClientConfig(JSON.parse(localStorage.getItem("clientConfig")));
  }, []);

  function applyStrategy<T>(strategyKey: string, args: any): T {
    const strategy: StrategyArgs = getStrategy(strategyKey);
    return strategy?.handle(args) as T;
  }

  const sensitiveDataOptions = getConfigFromClientConfig(
    clientConfig,
    "WORK_ORDER_PROFILE_POSITION"
  );

  const fields = useMemo(
    () => sensitiveDataOptions?.steps?.reduce((acc, step) => [...acc, ...step.fields], []),
    [sensitiveDataOptions]
  );

  const { mutate, isLoading: isSendingData } = useCreateSensitiveData();
  const { mutate: mutateRejectHrFields, isLoading: isLoadingRejectHrFields } = useRejectHrFields();

  useEffect(() => {
    if (sensitiveData) {
      setAllSensitiveData(sensitiveData);
    }
  }, [sensitiveData]);

  const [formData, setFormData] = useState<any>({
    initialState: {},
    currentState: {},
  });

  useEffect(() => {
    if (allSensitiveData) {
      let dataFormatted: any = null;

      if (sensitiveData) {
        dataFormatted = sensitiveData?.reduce((acc: any, item: any) => {
          acc[item.label.default] = item._id ?? item.value;
          return acc;
        }, {});
      }

      setFormData(() => ({
        initialState: dataFormatted,
        currentState: dataFormatted,
      }));
    }
  }, [isAllSensitiveDataLoading, allSensitiveData]);

  useEffect(() => {
    if (!fields) return;

    if (allSensitiveData)
      if (sensitiveData?.length > 0) {
        const loadedState: unknown = sensitiveData.reduce((prev: any, current: any) => {
          return {
            ...prev,
            [getNameByDisplayName(current?.label?.default)]: current?._id ?? current?.value,
          };
        }, {});

        return reset(loadedState);
      }
  }, [fields, allSensitiveData]);

  const buttonProps: any = {
    color: "primary",
    onClick: () => handleSubmit(),
    disabled: isSendingData || isLoadingRequest || isLoadingRejectHrFields,
  };

  const rejectButtonProps: any = {
    disabled: isLoadingRejectHrFields,
    onClick: () => setShowRejectHrFieldsModal(true),
    style: {
      marginRight: 10,
      backgroundColor: "#f44336",
      color: "white",
    },
  };

  const positionCodeButtonProps: any = {
    color: "primary",
    variant: "outlined",
    size: "small",
  };

  const disabledSearchButton =
    !hasRealmRole(["Human Resources - Org Data Structure"]) ||
    !watch("positionCode") ||
    isLoadingRequest;

  const handleSubmit = () => {
    if (!getValuesFromApi?.data) return;

    const modifiedObject = {
      ...getValuesFromApi?.data,
      positionCode: watch("positionCode"),
    };

    const formDataFormatted = Object.keys(modifiedObject).map((key) => ({
      name: key,
      value: modifiedObject[key],
    }));

    mutate(
      {
        refId:
          type === "worker" ? workOrderId : new URLSearchParams(window.location.search).get("id"),
        subscriptionId: subscription,
        formIdentifier: "WORK_ORDER_PROFILE_POSITION",
        formData: formDataFormatted,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["sensitiveData"]);
          queryClient.invalidateQueries([
            "WorkOrder",
            new URLSearchParams(window.location.search).get("id"),
          ]);
          setFormData((prev: any) => ({
            ...prev,
            initialState: formData.currentState,
          }));
          showNotification({
            color: "success",
            message: "Saved successfully!",
          });
        },
        onError: (error: any) => {
          showNotification({
            color: "danger",
            message: error.response.data.error.checkIfPropInListIsEmpty,
          });
        },
      }
    );
  };

  const handleRejectSubmit = (comment: string) => {
    mutateRejectHrFields(
      {
        workOrderID: new URLSearchParams(window.location.search).get("id"),
        comments: comment,
      },
      {
        onSuccess: () => {
          setShowRejectHrFieldsModal(false);
          showNotification({
            color: "success",
            message: "Saved successfully!",
          });
          window.location.reload();
        },
        onError: (error: any) => {
          setShowRejectHrFieldsModal(false);
          showNotification({
            color: "danger",
            message: error.response.data.message || "Save failed!",
          });
        },
      }
    );
  };

  const getNameByDisplayName = (displayName: string) => {
    return fields?.find((field) => field.displayName.default === displayName)?.name;
  };

  function showNotification(payload: SnackbarPayload) {
    setNotification({ message: payload.message, color: payload.color, open: true });
  }

  const handleSetData = useCallback(
    (response: ApiResponse) => {
      if (response?.data) {
        Object.keys(response.data).forEach((key) => {
          setValue(key, response.data[key]);
        });
      }
    },
    [setValue]
  );

  async function handleGetPositionCode(strategyArgsMapper: any) {
    const token = sessionToken ? sessionToken : keycloakToken;

    if (!strategyArgsMapper || !watch("positionCode")) return;
    setIsLoadingRequest(true);

    try {
      const response = await applyStrategy<ApiResponseProps>(strategyArgsMapper[0].type, [
        strategyArgsMapper,
        watch("positionCode"),
        subscription,
        token,
      ]);
      setGetValuesFromApi(response);
      setIsLoadingRequest(false);
      handleSetData(response);
    } catch (error) {
      showNotification({
        color: "danger",
        message: t("position-code-not-found"),
      });
      setIsLoadingRequest(false);
    }
  }

  const renderFields = (field: FieldsProps) => {
    switch (field.fieldType) {
      case "string":
        return (
          <TextField
            id='standard-basic'
            variant='standard'
            label={field.displayName.default}
            style={{ width: "100%" }}
            value={watch(field.name) || ""}
            required={field.required}
            {...register(field.name, { required: field.required })}
            disabled={field?.readonly || !hasRealmRole(["Human Resources - Org Data Structure"])}
          />
        );

      case "stringWithStrategy":
        return (
          <div
            style={{
              display: "flex",
              width: "100%",
              gap: "16px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TextField
              id='standard-basic'
              variant='standard'
              label={field.displayName.default}
              style={{ width: "100%" }}
              value={watch(field.name) || ""}
              required={field.required}
              {...register(field.name, { required: field.required })}
              disabled={!hasRealmRole(["Human Resources - Org Data Structure"])}
            />

            <Button
              {...positionCodeButtonProps}
              onClick={() => handleGetPositionCode(field.strategyArgsMapper)}
              disabled={disabledSearchButton}
              style={{
                height: 30,
                width: "40%",
              }}
            >
              {isLoadingRequest ? <CircularProgress size={20} /> : "SEARCH"}
            </Button>
          </div>
        );

      case "readonly":
        return (
          <TextField
            id='standard-basic'
            label={field.displayName.default}
            variant='standard'
            value={watch(field.name) || ""}
            type='button'
            inputProps={{ readOnly: true, style: { textAlign: "left" } }}
            disabled
            {...register(field.name)}
            style={{ width: "100%" }}
          />
        );

      case "select":
        return (
          <Searcher
            fieldData={field}
            value={watch(field.name) || ""}
            hookFormWatch={watch}
            hookFormControl={control}
            disabled={field?.readonly || !hasRealmRole(["Human Resources - Org Data Structure"])}
          />
        );

      case "searcher":
        return (
          <Searcher
            fieldData={field}
            value={watch(field.name) || ""}
            hookFormWatch={watch}
            hookFormControl={control}
            disabled={field?.readonly || !hasRealmRole(["Human Resources - Org Data Structure"])}
          />
        );
    }
  };

  return (
    <>
      {notification.open && (
        <Snackbar
          place='bc'
          color={notification.color}
          icon={AddAlert}
          message={notification.message}
          open={notification.open}
          closeNotification={() => setNotification({ open: false, message: "", color: "success" })}
          close
        />
      )}
      {showRejectHrFieldsModal && (
        <RejectHrFieldsModal
          open={showRejectHrFieldsModal}
          handleClose={() => setShowRejectHrFieldsModal(false)}
          handleSubmit={handleRejectSubmit}
        />
      )}
      <Card>
        <CardHeader {...{ color: "warning", icon: true }}>
          <CardIcon {...{ color: "warning" }}>
            <BusinessCenter />
          </CardIcon>
          <div className={classes.cardHeader}>
            <h4 className={classes.cardIconTitle}>HR Fields</h4>
          </div>
        </CardHeader>
        <CardBody>
          {isAllSensitiveDataLoading || !fields ? (
            <CustomSkeleton numberOfLines={5} />
          ) : (
            <>
              <Grid container spacing={2} style={{ width: "100%" }}>
                {fields?.map((field: FieldsProps) => (
                  <Grid
                    key={field.name}
                    item
                    xs={field.gridSize ?? 12}
                    style={{
                      display: !field!.showWhen
                        ? "inherit"
                        : field.showWhen[0].value[0] === watch(field.showWhen[0].key)
                        ? "inherit"
                        : "none",
                      paddingTop: field.fieldType === "checkbox" ? 12 : 24,
                    }}
                  >
                    {field.showWhen
                      ? field.showWhen[0].value[0] === watch(field.showWhen[0].key) &&
                        renderFields(field)
                      : renderFields(field)}
                    {errors[field.name] && (
                      <Grid key={field.name} item xs={field.gridSize ?? 12}>
                        This field is required
                      </Grid>
                    )}
                  </Grid>
                ))}
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {hasRealmRole(["Human Resources - Org Data Structure"]) && type === "workOrder" && (
                    <Button variant='contained' {...rejectButtonProps}>
                      REJECT
                    </Button>
                  )}
                  {hasRealmRole(["Human Resources - Org Data Structure"]) && (
                    <Button variant='contained' {...buttonProps}>
                      SAVE
                    </Button>
                  )}
                </Grid>
              </Grid>
            </>
          )}
        </CardBody>
      </Card>
      <Box>
        <WOOrepForm
          canProcess={canProcess}
          type={type}
          workOrderId={workOrderId}
          workOrder={workOrder}
        />
      </Box>
    </>
  );
}
