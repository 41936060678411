import React from "react";

import TranslateIcon from "@material-ui/icons/Translate";

import { Button, Grid } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import i18next from "i18next";

import { Container, ContainerTranslateButton, FormContent } from "./components/Auth.styles";
import Footer from "./components/Footer";
import NewUserForm from "./components/NewUserForm/NewUserForm";

function NewUserPage() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const changeLanguage = (lng: string) => {
    i18next.changeLanguage(lng);
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickLanguage = (language: string) => {
    changeLanguage(language);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Container>
      <ContainerTranslateButton>
        <Button aria-controls='simple-menu' aria-haspopup='true' onClick={handleClick}>
          <TranslateIcon />
        </Button>
        <Menu
          id='simple-menu'
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => handleClickLanguage("en")}>English</MenuItem>
          <MenuItem onClick={() => handleClickLanguage("es")}>Español</MenuItem>
          <MenuItem onClick={() => handleClickLanguage("it")}>Italiano</MenuItem>
        </Menu>
      </ContainerTranslateButton>
      <FormContent>
        <Grid>
          <NewUserForm />
        </Grid>
      </FormContent>
      <Footer />
    </Container>
  );
}

export default NewUserPage;
