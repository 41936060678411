import { CircularProgress, Icon, InputAdornment, makeStyles } from "@material-ui/core";

import uverifyLogo from "assets/img/uverify-logo.jpg";
import CustomInput from "components/CustomInput/CustomInput";

import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as z from "zod";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import {
  Container,
  ContentActions,
  ContentForm,
  ContentLogo,
  NewUserFormButton,
} from "./NewUserForm.styles";
import { PersonSharp } from "@material-ui/icons";
import { useCreateUserBySupplierInvite } from "services/input-core/auth/create-user-supplier-invitation";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const useStyles = makeStyles(styles as any);

const NewUserForm: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { token } = useParams();

  const { t } = useTranslation("login-page");

  const newUserSchema = z.object({
    name: z.string().min(3, t("name-length-error")),
    surname: z.string().min(3, t("surname-length-error")),
    password: z.string().min(6, t("new-user-schema-password-error")),
    confirmPassword: z.string().min(6, t("new-user-schema-confirmPassword-error")),
  });

  type NewUserFormFields = z.infer<typeof newUserSchema>;

  const { handleSubmit, register } = useForm<NewUserFormFields>();

  const [loading, setLoading] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const { mutate: createUserBySupplierInvite } = useCreateUserBySupplierInvite();

  const onSubmit = async (data: NewUserFormFields) => {
    if (data.password !== data.confirmPassword) {
      toast.warning("passwords-do-not-match");
      return;
    }

    try {
      setLoading(true);
      await newUserSchema.parse(data);
      createUserBySupplierInvite(
        {
          firstName: data.name,
          lastName: data.surname,
          password: data.password,
          confirmPassword: data.confirmPassword,
          token: token,
        },
        {
          onSuccess: () => {
            setLoading(false);
            toast.success(t("reset-password-success", { ns: "login-page" }));
            navigate("/auth/login");
          },
          onError: ({ response }) => {
            console.log("[error] =>", response?.data?.message);
            setLoading(false);
            toast.error(t("reset-password-error", { ns: "login-page" }));
          },
        }
      );
    } catch (validationError) {
      if (validationError instanceof z.ZodError) {
        validationError.errors.forEach((error) => {
          toast.warning(error.message);
          console.log(error);
        });
      }
      setLoading(false);
    }
  };

  return (
    <Container>
      <ContentLogo>
        <img src={uverifyLogo} alt='U-verify Logo' />
      </ContentLogo>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ContentForm>
          <CustomInput
            labelText={t("name")}
            id='name'
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              ...register("name", { required: true }),
              endAdornment: (
                <InputAdornment position='end'>
                  <PersonSharp className={classes.inputAdornmentIcon} />
                </InputAdornment>
              ),
            }}
          />

          <CustomInput
            labelText={t("surname")}
            id='surname'
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              ...register("surname", { required: true }),
              endAdornment: (
                <InputAdornment position='end'>
                  <PersonSharp className={classes.inputAdornmentIcon} />
                </InputAdornment>
              ),
            }}
          />

          <CustomInput
            labelText={t("password")}
            id='password'
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              ...register("password", { required: true }),
              endAdornment: (
                <InputAdornment position='end'>
                  <Icon className={classes.inputAdornmentIcon} onClick={togglePasswordVisibility}>
                    {showPassword ? "lock_open" : "lock"}
                  </Icon>
                </InputAdornment>
              ),
              type: showPassword ? "text" : "password",
              autoComplete: "off",
            }}
          />

          <CustomInput
            labelText={t("confirm-password")}
            id='confirmPassword'
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              ...register("confirmPassword", { required: true }),
              endAdornment: (
                <InputAdornment position='end'>
                  <Icon className={classes.inputAdornmentIcon} onClick={togglePasswordVisibility}>
                    {showPassword ? "lock_open" : "lock"}
                  </Icon>
                </InputAdornment>
              ),
              type: showPassword ? "text" : "password",
              autoComplete: "off",
            }}
          />
        </ContentForm>

        <ContentActions>
          <NewUserFormButton variant='outlined' disabled={loading} type='submit'>
            {loading ? <CircularProgress size={20} /> : t("create")}
          </NewUserFormButton>
        </ContentActions>
      </form>
    </Container>
  );
};

export default NewUserForm;
