import React from 'react';

import PropTypes from 'prop-types';
// material-ui components
import { makeStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
// @material-ui/icons
import Close from '@material-ui/icons/Close';
// core components
import Button from 'components/CustomButtons/Button.js';

import styles from 'assets/jss/material-dashboard-pro-react/modalStyle.js';
import { Box, Typography } from '@material-ui/core';
import LoadingBar from 'react-top-loading-bar';
import Danger from 'components/Typography/Danger';
import { useSubscription } from 'hooks/useSubscription';
import { useSync } from 'hooks/useSync';
import { useTranslation } from 'react-i18next';
import { useIsFetching, useQueryClient } from '@tanstack/react-query';

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />;
});

export default function Modal({ open, onClose, setSyncSnackOpen }) {
  const queryClient = useQueryClient();
  const loadingBarRef = React.useRef(null);
  const [failMessage, setFailMessage] = React.useState(false);
  const { t } = useTranslation("common")
  const [type, setType] = React.useState('');

  const hasSow = true;

  const { isSuccess: isSyncWorkOrderSuccess } = useSync('workOrders', { enabled: type === 'workOrders' });
  const { isSuccess: isSyncWorkerSuccess } = useSync('worker', { enabled: type === 'worker' });
  const { isSuccess: isSyncSupplierSuccess } = useSync('supplier', { enabled: type === 'supplier' });
  const { isSuccess: isSyncSowSuccess } = useSync('sow', { enabled: type === 'sow' });

  const isFetchingPostsWorkOrders = useIsFetching(['sync', 'workOrders']);
  const isFetchingPostsWorker = useIsFetching(['sync', 'worker']);
  const isFetchingPostsSupplier = useIsFetching(['sync', 'supplier']);
  const isFetchingPostsSow = useIsFetching(['sync', 'sow']);

  const isSyncing =
    isFetchingPostsWorkOrders ||
    isFetchingPostsWorker ||
    isFetchingPostsSupplier ||
    isFetchingPostsSow;

  React.useEffect(() => {
    if (type) onClose();

    return () => {
      setType('');
      setSyncSnackOpen(true);
      setTimeout(() => {
        setSyncSnackOpen(false);
      }, 5000);
    };
  }, [type]);

  React.useEffect(() => {
    if (isSyncWorkOrderSuccess)
      queryClient.invalidateQueries(["WorkOrders"]);
  }, [isSyncWorkOrderSuccess]);

  React.useEffect(() => {
    if (isSyncWorkerSuccess)
      queryClient.invalidateQueries(["workers"]);
  }, [isSyncWorkerSuccess]);

  React.useEffect(() => {
    if (isSyncSupplierSuccess)
      queryClient.invalidateQueries(["suppliers"]);
  }, [isSyncSupplierSuccess]);

  React.useEffect(() => {
    if (isSyncSowSuccess)
      queryClient.invalidateQueries(["sows"]);
  }, [isSyncSowSuccess]);



  const classes = useStyles();
  return (
    <div>
      <LoadingBar ref={loadingBarRef} />
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal,
        }}
        open={open}
        transition={Transition}
        keepMounted
        onClose={onClose}
        disableBackdropClick={isSyncing}
        aria-labelledby='modal-slide-title'
        aria-describedby='modal-slide-description'
      >
        <DialogTitle
          id='classic-modal-slide-title'
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key='close'
            aria-label='Close'
            color='transparent'
            onClick={onClose}
            disabled={isSyncing}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>{t("sync-menu")}</h4>
        </DialogTitle>
        <DialogContent
          id='modal-slide-description'
          className={classes.modalBody}
        >
          <p>{t("sync-text-1")}</p>
          <p>{t("sync-text-2")}</p>
          <Typography variant='caption'>
            {t("sync-caption")}
          </Typography>
          <Box
            display='flex'
            flexDirection='column'
            marginX='24px'
            marginTop='16px'
          >
            <Button
              color='danger'
              onClick={() => setType('workOrders')}
              disabled={isSyncing}
            >
              {t("sync-work-order")}
            </Button>
            <Button
              color='danger'
              onClick={() => setType('worker')}
              disabled={isSyncing}
            >
              {t("sync-workers")}
            </Button>
            <Button
              color='danger'
              onClick={() => setType('supplier')}
              disabled={isSyncing}
            >
              {t("sync-suppliers")}
            </Button>
            {hasSow && (
              <Button
                color='danger'
                onClick={() => setType('sow')}
                disabled={isSyncing}
              >
                {t("sync-sows")}
              </Button>
            )}
          </Box>
        </DialogContent>
        {failMessage && (
          <Box display='flex' justifyContent='center' paddingY='16px'>
            <Danger>{t("sync-failed")}</Danger>
          </Box>
        )}
      </Dialog>
    </div>
  );
}

Modal.propTypes = {
  // index of the default active collapse
  onConfirm: PropTypes.func,
  onDenied: PropTypes.func,
  setSyncSnackOpen: PropTypes.func,
};
