import { useMutation } from '@tanstack/react-query'
import apiInputService from 'services/apiInputService'

const createActivityUploadFile = async (formData: FormData) => {
    return await apiInputService.post('/documentAttachment/upload', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
}

export const useCreateActivityUploadFile = (options?: any) => {
    return useMutation(createActivityUploadFile, {
        ...options,
    })
}
