import React from 'react'
import * as Tabs from '@radix-ui/react-tabs';
import { useStyles } from './style';

export type TabContentProps = React.ComponentProps<typeof Tabs.Content> & {
  variant?: "card" | "page";
};

export default function TabContent({ variant = "page", ...rest }: TabContentProps) {
    const classes = useStyles({variant, ...rest});

  return (
    <Tabs.Content {...rest} className={classes.root}>
            {rest.children}
    </Tabs.Content>
  )
}
