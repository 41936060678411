import { useQuery } from "@tanstack/react-query";
import apiInputService from "services/apiInputService";
import { WorkerRelatedQuery } from "./types";

const fetchWorkersRelated = async (workerRelatedQuery: WorkerRelatedQuery) => {
  const { workerID } = workerRelatedQuery;

  const workersRelated = await apiInputService.get(`/worker/relatedWorkers/${workerID}`);
  const { data } = workersRelated;

  return data;
};

export const useWorkersRelatedQuery = (workerRelatedQuery: WorkerRelatedQuery, options?: any) => {
  const { workerID } = workerRelatedQuery;

  return useQuery<any[]>(
    ["workers-related", workerID],
    () => fetchWorkersRelated(workerRelatedQuery),
    {
      ...options,
    }
  );
};
