
import { useTranslation } from "react-i18next";
import {
  Container,
  ContainerFooter,
  Content,
  DataContent,
  SocialMediaContent,
  TypographyDescriptionStyle,
  TypographyTitleStyle,
} from "./Footer.styles";

import { Facebook, LinkedIn, YouTube } from "@material-ui/icons";

export default function Footer() {
  const { t } = useTranslation("login-page");

  function RightsReserved() {
    return (
      <ContainerFooter>
        <TypographyTitleStyle>U-Verify by Stratesys</TypographyTitleStyle>
        <TypographyDescriptionStyle>
          © Stratesys {new Date().getFullYear()} – {t("allRightsReserved")}
        </TypographyDescriptionStyle>
      </ContainerFooter>
    );
  }

  function StratesysLinks() {
    return (
      <ContainerFooter>
        <TypographyTitleStyle>Links</TypographyTitleStyle>
        <a
          href="https://www.stratesys-ts.com/pt/#aboutus"
          style={{ color: "inherit" }}
          target="_blank"
        >
          Stratesys
        </a>
        <a
          href="https://www.stratesys-ts.com/pt/#aboutus"
          style={{ color: "inherit" }}
          target="_blank"
        >
          {t("policyPrivacy")}
        </a>
      </ContainerFooter>
    );
  }

  function StratesysContact() {
    return (
      <ContainerFooter>
        <TypographyTitleStyle>{t("Contact")}</TypographyTitleStyle>
        <TypographyDescriptionStyle>
          +55 (11) 4280-8482
        </TypographyDescriptionStyle>
        <TypographyDescriptionStyle>
          info.sao@stratesys-ts.com
        </TypographyDescriptionStyle>
      </ContainerFooter>
    );
  }

  function SocialMedias() {
    return (
      <ContainerFooter>
        <TypographyTitleStyle>{t("followUp")}</TypographyTitleStyle>
        <SocialMediaContent>
          <a href="https://www.facebook.com/stratesysbrasil" target="_blank">
            <Facebook fontSize="large" color="action" />
          </a>
          <a
            href="https://www.linkedin.com/company/stratesys-brasil/"
            target="_blank"
          >
            <LinkedIn color="action" fontSize="large" />
          </a>
          <a href="https://www.youtube.com/user/stratesys" target="_blank">
            <YouTube color="action" fontSize="large" />
          </a>
        </SocialMediaContent>
      </ContainerFooter>
    );
  }

  return (
    <Container>
      <Content />
      <DataContent>
        {RightsReserved()}
        {StratesysLinks()}
        {StratesysContact()}
        {SocialMedias()}
      </DataContent>
    </Container>
  );
}
