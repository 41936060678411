import { makeStyles } from '@material-ui/core/styles';
import { TabContentProps } from '.';

const variantMargins = {
    page: 32,
    card: 8
} 

export const useStyles = makeStyles(() => ({
    root: ({ variant }: TabContentProps) => ({
        marginTop: variantMargins[variant], 
    }),
}))