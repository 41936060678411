import Keycloak from "keycloak-js";
import { keycloakService } from "services/authService";

export interface IKeycloakSSOConfig {
  usernamePattern?: RegExp;
  idpHint?: string;
}

export const keycloakSSOLoginStrategy = (config: IKeycloakSSOConfig) => {
  return (username: string) => {
    if (config.usernamePattern.test(username)) {
      const keycloak = new Keycloak({
        url: process.env.REACT_APP_API_AUTH + "/auth",
        realm: process.env.REACT_APP_API_AUTH_REALM,
        clientId: process.env.REACT_APP_API_AUTH_CLIENT_ID,
      });

      return keycloak
        .init({
          checkLoginIframe: true,
        })
        .then((authenticated) => {
          if (authenticated) return true;
          else
            keycloak
              .login({
                redirectUri: window.location.origin + "/checkSSO",
                idpHint: config.idpHint,
              })
              .then(() => {
                const access_token = keycloak.token;
                keycloakService.defaults.headers["Authorization"] = `Bearer ${access_token}`;
              });
        });
    }
  };
};

export const tryRedirectToSSO = (username: string) => {
  const config = keycloakSSOConfigs.find((config) => config.usernamePattern.test(username));

  if (config) {
    return keycloakSSOLoginStrategy(config)(username);
  }

  return false;
};

const AngloLoginConfig: IKeycloakSSOConfig = {
  usernamePattern: /@angloamerican\.com$/i,
  idpHint: "anglo-sso",
};

export const keycloakSSOConfigs = [AngloLoginConfig];
