import axios from "axios";
import keycloak from "keycloak";

const apiInputService = axios.create({
  baseURL: process.env.REACT_APP_INPUT_CORE,
  headers: {
    "Content-Type": "application/json",
  },
});

apiInputService.interceptors.request.use(
  function (config) {
    const subs = sessionStorage.getItem("subscription");
    const token = localStorage.getItem("token");
    const keycloakToken = keycloak.token;

    config.headers = {
      Authorization: `Bearer ${token ? token : keycloakToken}`,
    };
    config.params = {
      subscription: subs,
      ...config.params,
    };
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

apiInputService.interceptors.response.use(
  (response) => response,
  async (error) => {
    const prevRequest = error?.config;
    if (error?.response?.status === 401 && !prevRequest?.sent) {
      prevRequest.sent = true;

      keycloak.updateToken(30).then((refreshed) => {
        if (refreshed) {
          return apiInputService(prevRequest);
        }
      });
    }

    return Promise.reject(error);
  }
);

export default apiInputService;
