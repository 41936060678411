import { useMutation } from "@tanstack/react-query";
import apiInputService from "services/apiInputService";
import { SensitiveDataPayload } from "./type";

const createSensitiveData = async (sensitiveData: SensitiveDataPayload) => {
  return await apiInputService.post("/clientSensitiveData", {
    ...sensitiveData,
  });
};

export const useCreateSensitiveData = (options?: any) => {
  return useMutation(createSensitiveData, {
    ...options,
  });
};
