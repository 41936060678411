import { Box, styled } from "@mui/material";

export const Container = styled(Box)`
  width: 100vw;
  height: 100%;
  min-height: 100vh;
  background-color: #fafafb;
  overflow-y: hidden;
`;

export const ContainerTranslateButton = styled(Box)`
  position: relative;
  top: 0;
  right: 0;
  display: inline-block;
  left: 92%;
`;

export const FormContent = styled(Box)`
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fafafb;
`;
