import React, { useState } from "react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import Badge from "components/Badge/Badge";
import ReactTable from "components/ReactTable/ReactTable";
import { CloudUpload, Group, Visibility } from "@material-ui/icons";
import AddAlert from "@material-ui/icons/AddAlert";
import Snackbar from "components/Snackbar/Snackbar";
import {
  CircularProgress,
  makeStyles,
  TextField,
  Tooltip,
  Button as MuiButton,
} from "@material-ui/core";
import { Box, Typography } from "@mui/material";
import { cardTitle } from "assets/jss/material-dashboard-pro-react";
import Button from "components/CustomButtons/Button";
import { useWorkerActivityItemsWithStatus } from "services/input-core/work-items/get-worker-activity-items-with-status";
import { useTranslation } from "react-i18next";
import {
  ActivityItemsWorkerWithLocation,
  ActivityItemWorker,
  DocumentAttachmentQueryKey,
} from "services/input-core/work-items/@types";
import { ModalForm } from "components/ModalForm/ModalForm";
import { sortStatusWithBadge } from "helpers/customSortMethods";
import ChangeATOModal from "./ChangeATOModal";
import { useATOStatus } from "services/input-core/activity-items/get-ATO-status";
import { useDeleteATOStatus } from "services/input-core/activity-items/delete-ATO-status";
import { useAuth } from "hooks/useAuth";
import UploadDocumentModal from "components/UploadDocumentModal";
import { useCreateSensitiveData } from "services/input-core/sensitive-data/create-sensitive-data";
import { useSubscription } from "hooks/useSubscription";
import { useQueryClient } from "@tanstack/react-query";
import CustomSkeleton from "components/CustomSkeleton/CustomSkeleton";
import moment from "moment";
import ActivityItemForm from "./ActivityItemForm";
import { useDocumentAttachmentsFromActivityItem } from "services/input-core/work-items/get-file-of-activity-item";
import { ActivityItemModalViewer } from "./ActivityItemModalViewer";
import { getBadgeFromStatus } from "utils/functions";
import { StatusBadgeEnum } from "utils/@types";

const styles: any = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  cardStatusText: {
    marginTop: "10px",
    marginBottom: "0px",
    fontWeight: "400",
    lineHeight: "1",
  },
  cardSelect: {
    width: "200px",
    marginBottom: "10px",
    fontWeight: "400",
    lineHeight: "1",
  },
  cardBadge: {
    marginTop: "5px",
    marginBottom: "5px",
    paddingLeft: "10px",
  },
  cardStatusBlock: {
    display: "flex",
  },
  cardBox: {
    display: "flex",
    width: "75%",
    justifyContent: "space-between",
  },
};

const stepsButtonStyle = {
  backgroundColor: "#2f87ee",
  color: "white",
  borderRadius: "20px",
};
const confirmButton = {
  backgroundColor: "#2f87ee",
  color: "white",
};

const ButtonStyle = {
  backgroundColor: "#e43",
  color: "white",
  borderRadius: "20px",
};

const spanStyle = {
  marginLeft: "10px",
  fontWeight: "500",
  fontSize: "12px",
};

const useStyles = makeStyles(styles);

interface ActivityItemsWorkerProps {
  workerData: any;
  data: ActivityItemsWorkerWithLocation;
  isFetching: boolean;
  canShowDownloadAction: boolean;
  enableUpload?: boolean;
}

export default function ActivityItemsWorker({
  workerData,
  data,
  isFetching,
  canShowDownloadAction,
  enableUpload = true,
}: ActivityItemsWorkerProps) {
  const classes = useStyles();
  const { subscription } = useSubscription();
  const { t } = useTranslation("activity-item-table");
  const queryClient = useQueryClient();

  const refID = new URLSearchParams(window.location.search).get("id");

  const {
    data: ATOData,
    isLoading: isLoadingATOStatus,
    refetch: refetchATOStatus,
  } = useATOStatus(refID);

  const { mutate, isLoading } = useDeleteATOStatus(refID);
  const locationData = data?.locations;

  const { mutate: mutateSensitiveData, isLoading: isSubmittingDocuments} = useCreateSensitiveData();

  const [uploadDocumentModal, setUploadDocumentModal] = useState({
    open: false,
    activityItem: undefined,
    formIdentifier: undefined,
  });

  const [notification, setNotification] = React.useState({
    open: false,
    message: "",
    color: "primary",
  });

  const [activityItem, setActivityItem] = useState(null);
  const [currentActivityFile, setCurrentActivityFile] = useState(null);
  const [modalActivityItemOpen, setActivityItemModalOpen] = useState(false);
  const [changeATOmodalOpen, setChangeATOModalOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openFileViewModal, setOpenFileViewModal] = useState(false);
  const { hasRealmRole } = useAuth();

  const { refetch: refetchActivityItem } = useWorkerActivityItemsWithStatus(refID);

  const handleUpdateStatusATO = () => {
    mutate(refID, {
      onSuccess: () => {
        showNotification("success", "Change status Access to Operation successfully!");
        refetchATOStatus();
        setOpenDialog(false);
      },
      onError: (error) => {
        showNotification("danger", "Change status Access to Operation failed!");
      },
    });
  };

  const attachmentsQuery: DocumentAttachmentQueryKey = {
    refID,
    activityItemCode: currentActivityFile,
  };

  const { data: documentsFromActivity, isLoading: isLoadingFile } =
    useDocumentAttachmentsFromActivityItem(attachmentsQuery, {enabled: !!attachmentsQuery?.refID && !!currentActivityFile});

  const locations = locationData?.filter(function (a: any) {
    return !this[JSON.stringify(a)] && (this[JSON.stringify(a)] = true);
  }, Object.create(null));

  const buttonProps = (color: any, action?: () => void, href?: string): any => ({
    justIcon: true,
    round: true,
    color,
    size: "sm",
    href: href ?? "",
    onClick: action ? () => action() : undefined,
  });

  const locationsMapped = [
    {
      value: "all",
      label: "All",
    },
    ...(locations ? locations : []),
  ];

  function showNotification(color: string, message: string) {
    setNotification({
      open: true,
      color: color,
      message: message,
    });
  }

  const [location, setLocations] = React.useState("all");

  const onSubmitUploadDocumentModal = (data: any, zIdentifier: string, formIdentifier: string) => {
    const body = Object.keys(data).map((key: string) => ({
      name: key,
      value: key.includes("Date") ? moment(data[key]).format("YYYY-MM-DD") : data[key],
    }));

    mutateSensitiveData(
      {
        subscriptionId: subscription,
        refId: workerData.workOrderID,
        formIdentifier: formIdentifier,
        formData: body,
      },
      {
        onSuccess: () => {
          setNotification({
            open: true,
            color: "success",
            message: t("submit-form-success"),
          });
          setUploadDocumentModal({
            open: false,
            activityItem: undefined,
            formIdentifier: "",
          });
          queryClient.invalidateQueries(["work-order-activity-items-status", refID]);
          queryClient.invalidateQueries(["worker-activity-items-status", refID]);
          queryClient.invalidateQueries(["workOrder-process-table", refID]);
          queryClient.invalidateQueries(["worker-process-table", refID]);
        },
        onError: (error: any) => {
          const errorsMessage = Object.values(error?.response?.data?.error);
          setNotification({
            open: true,
            color: "danger",
            message: `${t("submit-form-fail")}: ${errorsMessage.map((error) => error + ";")}`,
          });
        },
      }
    );
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLocations(event.target.value);
  };

  const handleOpenUploadFileModal = (activityItemData: any) => {
    setActivityItemModalOpen(true);
    setActivityItem(activityItemData);
  };

  const handleOpenChangeATOModal = () => {
    setChangeATOModalOpen(true);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleOpenViewFile = (activityItemData: any) => {
    setCurrentActivityFile(activityItemData);
    if (!isLoadingFile) setOpenFileViewModal(true);
  };

  const renderActivityItemModal = () => {
    return (
      <ModalForm
        title={"WORKERS"}
        formName={"Upload Service Item"}
        open={modalActivityItemOpen}
        handleClose={() => setActivityItemModalOpen(false)}
        children={
          <ActivityItemForm
            profile='worker'
            refetchList={refetchActivityItem}
            setModalOpen={setActivityItemModalOpen}
            activityItem={activityItem}
            showNotification={showNotification}
            refID={refID}
          />
        }
      />
    );
  };

  const renderChangeATOModal = () => {
    return (
      <ModalForm
        title={"WORKERS"}
        formName={"Approve ATO"}
        open={changeATOmodalOpen}
        handleClose={() => setChangeATOModalOpen(false)}
        children={
          <ChangeATOModal
            workerId={refID}
            refetchATOStatus={refetchATOStatus}
            setModalOpen={setChangeATOModalOpen}
            showNotification={showNotification}
          />
        }
      />
    );
  };

  const handleOpenChangeATODialog = () => {
    return (
      <ModalForm
        open={openDialog}
        formName='ATO Status'
        handleClose={() => setOpenDialog(false)}
        children={
          <>
            <Box sx={{ marginBottom: "20px" }}>
              <Typography variant='subtitle1' textTransform='inherit'>
                Are you sure you want to revert status?
              </Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "end" }}>
              <MuiButton style={{ color: "#e43" }} onClick={() => setOpenDialog(false)}>
                Close
              </MuiButton>
              <MuiButton
                onClick={() => handleUpdateStatusATO()}
                disabled={isLoading}
                style={confirmButton}
                variant='contained'
              >
                {isLoading ? <CircularProgress size={20} color='inherit' /> : "Confirm"}
              </MuiButton>
            </Box>
          </>
        }
      />
    );
  };

  const handleOpenFileImage = () => {
    return (
      <ModalForm
        open={openFileViewModal}
        formName='File Uploaded'
        title='Worker'
        step={`${currentActivityFile}`}
        handleClose={() => setOpenFileViewModal(false)}
        children={
          <ActivityItemModalViewer
            documentsFromActivity={documentsFromActivity}
            isLoadingFile={isLoadingFile}
          />
        }
      />
    );
  };

  const getFormIdentifierByActivityItemCode = (activityItemCode: string) => {
    switch (activityItemCode) {
      case "PD-0001":
        return "WORK_ORDER_ID_DOCUMENT";

      case "PD-0004":
        return "WORK_ORDER_DRIVER_LICENSE";

      case "PD-0007":
        return "WORK_ORDER_PASSPORT";

      case "PD-0008":
        return "WORK_ORDER_WORK_PERMIT";

      default:
        return;
    }
  };

  const filterTable: ActivityItemWorker[] = !data
    ? []
    : location == "all"
    ? data.activitiesItems
    : data?.activitiesItems?.filter((row) => row?.location?.toLowerCase() == location);

  const hasUploadButton = (code: string) => {
    switch (code) {
      case "PD-0001":
      case "PD-0004":
      case "PD-0007":
      case "PD-0008":
        return true;
      default:
        return false;
    }
  };

  const dataStatus =
    filterTable
      ?.map((activityItem) => {
        return {
          codeID: activityItem.activityItemCode,
          actionID: activityItem.activityItemAction,
          status: getBadgeFromStatus(activityItem.activityItemStatus),
          date: activityItem.activityItemDueDate,
          createDate: activityItem.activityItemCreateDate,
          markAsCompleteDate: activityItem.activityItemMarkasCompleteDate,
          completionType: activityItem.activityItemCompletionType,
          description: activityItem.activityItemDescription,
          location: activityItem.location,
          actions: hasRealmRole(["Supplier Company Rep"]) && (
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              {activityItem.activityItemCode === "HE-0003" &&
              activityItem.activityItemStatus === "Pending" ? (
                <div className='actions-right'>
                  <Tooltip title='Upload Document'>
                    <Button
                      {...buttonProps("blue", () => {
                        return enableUpload
                          ? handleOpenUploadFileModal(activityItem)
                          : showNotification("warning", "Please fill in the sensitive data!");
                      })}
                    >
                      <CloudUpload />
                    </Button>
                  </Tooltip>
                </div>
              ) : (
                hasUploadButton(activityItem.activityItemCode) &&
                activityItem.activityItemStatus === "Pending" && (
                  <div className='actions-right'>
                    <Tooltip title='Upload Document'>
                      <Button
                        {...buttonProps("blue", () => {
                          return enableUpload
                            ? setUploadDocumentModal({
                                open: true,
                                activityItem: activityItem,
                                formIdentifier: getFormIdentifierByActivityItemCode(
                                  activityItem.activityItemCode
                                ),
                              })
                            : showNotification("warning", "Please fill in the sensitive data!");
                        })}
                      >
                        <CloudUpload />
                      </Button>
                    </Tooltip>
                  </div>
                )
              )}
              {activityItem.activityItemCode === "HE-0003" &&
                activityItem.activityItemStatus === "Completed" && (
                  <div className='actions-right'>
                    <Tooltip title='View Document'>
                      <Button
                        {...buttonProps("primary", () =>
                          handleOpenViewFile(activityItem.activityItemCode)
                        )}
                      >
                        <Visibility />
                      </Button>
                    </Tooltip>
                  </div>
                )}
            </div>
          ),
          authType: activityItem.authorizationType,
        };
      })
      .sort((a) => (a.actions?.props?.children[0] ? -1 : 1)) ?? [];

  const authorizationLocation = filterTable?.reduce(
    (prev, current, index) => {
      const refType = current.authorizationType;

      if (!prev[refType] && current.activityItemStatus == "Completed") {
        prev[refType] = "Granted";
      }

      if (prev[refType] != "Blocked") {
        if (current.activityItemStatus == "Canceled") {
          prev[refType] = "Blocked";
        }

        if (current.activityItemStatus == "Pending") {
          prev[refType] = "Pending";
        }
      }

      if (index == filterTable.length - 1) {
        prev.isCalculatingStatus = false;
        prev.ATO = prev.ATO ?? "No Activities";
        prev.ATW = prev.ATW ?? "No Activities";
      }

      return prev;
    },
    {
      ATW: null,
      ATO: null,
      isCalculatingStatus: true,
    }
  );

  const columnsStatus = React.useMemo(
    () => [
      {
        Header: "Code",
        accessor: "codeID",
      },
      {
        Header: "Action",
        accessor: "actionID",
      },
      {
        Header: "Status",
        accessor: "status",
        sortType: sortStatusWithBadge,
      },
      {
        Header: "Due Date",
        accessor: "date",
      },
      {
        Header: "Created At",
        accessor: "createDate",
      },
      {
        Header: "Completed In",
        accessor: "markAsCompleteDate",
      },
      {
        Header: "Completion",
        accessor: "completionType",
      },
      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "Actions",
        accessor: "actions",
      },
    ],
    []
  );

  return (
    <>
      {notification.open && (
        <Snackbar
          place='bc'
          color={notification.color}
          icon={AddAlert}
          message={notification.message}
          open={notification.open}
          closeNotification={() => setNotification({ open: false, message: "", color: "primary" })}
          close
        />
      )}
      <Card>
        <CardHeader color='rose' icon>
          <CardIcon color='rose'>
            <Group />
          </CardIcon>
          <h4 className={classes.cardIconTitle}>{t("activityItems")}</h4>
        </CardHeader>
        {uploadDocumentModal?.formIdentifier && (
          <UploadDocumentModal
            formIdentifier={uploadDocumentModal.formIdentifier}
            activityItem={uploadDocumentModal.activityItem}
            active={uploadDocumentModal.open}
            onSubmit={onSubmitUploadDocumentModal}
            isSubmittingDocuments={isSubmittingDocuments}
            handleActive={() =>
              setUploadDocumentModal({
                open: false,
                activityItem: undefined,
                formIdentifier: undefined,
              })
            }
          />
        )}
        <CardBody>
          <TextField
            className={classes.cardSelect}
            id='standard-select-currency-native'
            select
            label='Choose Location'
            value={location}
            defaultValue={"all"}
            onChange={handleChange}
            SelectProps={{
              native: true,
            }}
          >
            {locationsMapped?.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </TextField>
          <Box display='flex' justifyContent='space-between'>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                <p>{t("atoStatus")}</p>
                <Box sx={{ m: " 0 0 10px  10px" }}>
                  {ATOData?.granted === true
                    ? getBadgeFromStatus(
                        isLoadingATOStatus ? StatusBadgeEnum.LOADING : StatusBadgeEnum.GRANTED
                      )
                    : getBadgeFromStatus(
                        isLoadingATOStatus
                          ? StatusBadgeEnum.LOADING
                          : authorizationLocation.ATO ?? StatusBadgeEnum.NO_ACTIVITIES
                      )}
                </Box>
              </Box>
              {ATOData?.granted === true ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    m: "0 0 10px 0",
                    justifyContent: "start",
                    alignItems: "start",
                  }}
                >
                  <span style={spanStyle}>
                    {"Expires at: " + new Date(ATOData?.expiresAt).toLocaleDateString()}
                  </span>
                  <span style={spanStyle}>{"Changed by: " + ATOData?.createdBy?.fullName}</span>
                  <span style={spanStyle}>{"Description: " + ATOData?.description}</span>
                </Box>
              ) : (
                ""
              )}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                <p>{t("atwStatus")}</p>
                <Box sx={{ m: " 0 0 10px  10px" }}>
                  <span>
                    {getBadgeFromStatus(
                      isLoadingATOStatus
                        ? StatusBadgeEnum.LOADING
                        : authorizationLocation.ATO ?? StatusBadgeEnum.NO_ACTIVITIES
                    )}
                  </span>
                </Box>
              </Box>
            </Box>
            <Box>
              {ATOData?.granted ? (
                <MuiButton
                  variant='contained'
                  style={ButtonStyle}
                  onClick={() => handleOpenDialog()}
                >
                  <Typography
                    sx={{ fontSize: "12px", width: "120px" }}
                    variant='button'
                    textTransform='uppercase'
                  >
                    Revert ATO status
                  </Typography>
                </MuiButton>
              ) : (
                hasRealmRole(["Access to Operation by Exception Approver"]) &&
                authorizationLocation.ATO === "Pending" &&
                workerData?.status === "Active" && (
                  <MuiButton
                    variant='contained'
                    style={stepsButtonStyle}
                    onClick={() => handleOpenChangeATOModal()}
                  >
                    <Typography
                      sx={{ fontSize: "12px" }}
                      variant='button'
                      textTransform='uppercase'
                    >
                      Approve ATO
                    </Typography>
                  </MuiButton>
                )
              )}
              {handleOpenChangeATODialog()}
              {renderChangeATOModal()}
              {renderActivityItemModal()}
              {handleOpenFileImage()}
            </Box>
          </Box>
          {isFetching ? (
            <CustomSkeleton numberOfLines={5} />
          ) : (
            <ReactTable columns={columnsStatus} data={dataStatus} />
          )}
        </CardBody>
      </Card>
    </>
  );
}
