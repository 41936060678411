import React, { createContext, useContext, useState } from 'react';

interface TabInfo {
    name: string;
    counter: number;
}

interface TabData {
    createTab(name: string): void;
    setTabCounter(tabName: string, value: number): void;
    getTabCounter(tabName: string): number | undefined;
}

const TabContext = createContext({});

export const TabProvider: React.FC = ({children}) => {
    const [tabs, setTabs] = useState<TabInfo[]>([]);

    const createTab = (name: string) => {
        setTabs(prev => [...prev, {name, counter: 0}]);
    }

    const setTabCounter = (tabName: string, value: number) => {
        setTabs(prev => prev.map(tab => tab.name === tabName ? {...tab, counter: value} : tab));        
    }

    const getTabCounter = (tabName: string): number => {
        return tabs.find(tab => tab.name === tabName)?.counter ?? 0;
    }
    
    return (
        <TabContext.Provider value={{ createTab, setTabCounter, getTabCounter}}>
            {children}
        </TabContext.Provider>
    );
}

export function useTab(): TabData {
    const context: any = useContext(TabContext);

    return context;
}
