import { useQuery } from "@tanstack/react-query";
import apiInputService from "services/apiInputService";

const fetchWorkOrderById = async (workOrderID: string) => {
  const { data } = await apiInputService.get(`/WorkOrders/${workOrderID}`);

  return data;
};

export const useWorkOrderByIdQuery = (workOrderID: string, options?: any) => {
  return useQuery<any>(["WorkOrder", workOrderID], () => fetchWorkOrderById(workOrderID), {
    ...options,
  });
};
