import { makeStyles } from '@material-ui/core/styles'

export const useCustomDialogStyles = makeStyles({
    titleDiv: {
        display: 'flex ',
        flexDirection: 'column',
        alignItems: 'center !important',
        justifyContent: 'center !important',
        backgroundColor: '#08558D',
        color: 'white',
        position: 'fixed',
        padding: 10,
        borderRadius: 10,
        left: '50% ',
        transform: 'translate(-50%, -50%) ',
    },
    dialogContent: {
        display: 'flex ',
        flexDirection: 'column',
        alignItems: 'center !important',
        justifyContent: 'center !important',
        paddingTop: '10% !important',
        width: '100%',
    },
    dialogAction: {
        display: 'flex',
        width: '100% !important',
        justifyContent: 'center !important',
        marginBottom: '2%',
    },
})
