import { parseISO } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import Badge from "components/Badge/Badge";
import { useRef } from "react";
import { StatusBadgeMapping } from "./@types";

const getBadgeFromStatus = (status: string) => {
  const badgeInfo = StatusBadgeMapping[status] || { color: "info", text: status };
  return <Badge {...{ color: badgeInfo.color }}>{badgeInfo.text}</Badge>;
};

function formateDateWithTZ(date: any) {
  const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  let getOnlyDate;

  if (date === null) return;

  if (date?.length === 1) {
    getOnlyDate = date[0]?.split("T")[0];
  } else {
    getOnlyDate = date?.split("T")[0];
  }

  const parsedDate = parseISO(getOnlyDate);
  const dateOfBirth = zonedTimeToUtc(parsedDate, browserTimeZone);

  return dateOfBirth.toLocaleDateString("pt", {
    timeZone: browserTimeZone,
  });
}

function useDebounce(fn: any, delay: number) {
  const timeoutRef = useRef(null);
  function debouncedFn(...args: any) {
    clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(() => {
      fn(...args);
    }, delay);
  }
  return debouncedFn;
}

const normalizeString = (str: string) =>
  str
    ?.toUpperCase()
    .replace(/[ÀÁÂÃÄÅ]/g, "A")
    .replace(/[ÈÉÊË]/g, "E")
    .replace(/[ÌÍÎÏ]/g, "I")
    .replace(/[ÒÓÔÕÖØ]/g, "O")
    .replace(/[ÙÚÛÜ]/g, "U")
    .replace(/[Ç]/g, "C")
    .replace(/[^A-Z0-9]/g, "");

function compareStringsIgnoreCaseAndRemoveSpecialChars(str1: string, str2: string) {
  return normalizeString(str1) === normalizeString(str2);
}

function checkIfStringInArrayIgnoreCaseAndRemoveSpecialChars(
  str1: string,
  str2: string[]
): boolean {
  return str2.map(normalizeString).includes(normalizeString(str1));
}

const getFilters = (filters: any, disabledChangeSupplierNameFilter?: boolean) => {
  const object: { [key: string]: any } = {};

  if (filters?.length > 0)
    filters?.forEach(
      (filter: any) =>
        (object[
          `filter[${
            filter.id === "supplierName" && !disabledChangeSupplierNameFilter
              ? "supplier"
              : filter.id
          }]`
        ] = filter.value)
    );

  return { ...object };
};

const getSorting = (sorting: any) => {
  const object: { [key: string]: any } = {};

  if (sorting?.length > 0)
    sorting?.forEach(
      (sort: any) =>
        (object[`sort[${sort.id === "supplierName" ? "supplier" : sort.id}]`] = sort.desc
          ? "desc"
          : "asc")
    );

  return { ...object };
};

export {
  getBadgeFromStatus,
  formateDateWithTZ,
  useDebounce,
  compareStringsIgnoreCaseAndRemoveSpecialChars,
  checkIfStringInArrayIgnoreCaseAndRemoveSpecialChars,
  getFilters,
  getSorting,
};
