import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from "react";
import { FieldValues, UseFormSetValue } from "react-hook-form";
import { FieldsProps } from "services/input-core/clientConfig/type";
import Button from "./UploadButton";
import * as FU from "./FileUpload.styles";
import DocumentIcon from "@material-ui/icons/Description";

export interface FileUploadProps {
  label?: string;
  fieldData?: FieldsProps;
  hookFormSetValue?: UseFormSetValue<FieldValues>;
  onFileUpload?: (imageUrl: any) => any;
  isLoading?: boolean;
  type?: "wizard" | "form";
  currentFiles?: File[];
  setCurrentFiles?: Dispatch<SetStateAction<File[]>>;
}

const fileNotIsAllowed = (file: string) => {
  switch (file) {
    case "docx":
      return true;
    case "csv":
      return true;
  }
};

function FileUpload(props: FileUploadProps) {
  const {
    fieldData,
    hookFormSetValue,
    isLoading,
    label,
    onFileUpload,
    type,
    currentFiles,
    setCurrentFiles,
  } = props;

  const [filePreview, setFilePreview] = useState<string | null>(null);
  const [files, setFiles] = useState(currentFiles ?? []);
  const [fileSizeMessage, setFileSizeMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  useEffect(() => {
    const typeFile = files[0]?.name?.split(".").slice(-1)[0];
    const fileSize = (files[0]?.size / (1024 * 1024)).toFixed(2);

    if (files?.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", async (e) => {
        setFilePreview(String(e.target?.result));
      });

      reader.readAsDataURL(files[0]);
    }

    if (files?.length > 0 && onFileUpload) {
      onFileUpload(files);
    }

    if (type === "wizard") {
      hookFormSetValue(fieldData?.name, files);
    }

    if (+fileSize > 30) setTimeout(() => setFileSizeMessage(true));

    if (fileNotIsAllowed(typeFile)) {
      setErrorMessage(true);
    } else {
      setErrorMessage(false);
    }
  }, [files]);

  useEffect(() => {
    if (currentFiles?.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", async (e) => {
        setFilePreview(String(e?.target?.result));
      });

      reader.readAsDataURL(currentFiles[0]);
    }
  }, [currentFiles]);

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    const file = e?.target?.files?.[0];
    if (setCurrentFiles) {
      setCurrentFiles([file]);
    }
    setFiles([file]);
  }

  if (filePreview && !errorMessage)
    return (
      <FU.ImagePreviewWrapper>
        <FU.ImagePreview preview={filePreview}>
          <FU.FilePreviewWrapper>
            {files[0]?.name.substring(0, 40) + "(...)." + files[0]?.name.split(".")[1]}
          </FU.FilePreviewWrapper>
          <Button
            disabled={isLoading}
            variant={"danger"}
            label={"Remove file"}
            onClick={() => {
              setFilePreview(null), setFiles([]), setFileSizeMessage(false), setErrorMessage(false);
            }}
          />
        </FU.ImagePreview>
      </FU.ImagePreviewWrapper>
    );

  return (
    <>
      <FU.Wrapper>
        <FU.Label>
          <DocumentIcon style={{ color: "#08558D", fontSize: "50px" }} />
          {label}
          <FU.Input type='file' onChange={handleChange} />
        </FU.Label>
      </FU.Wrapper>
      <p style={{ color: "#e43", margin: "10px 0 0 10px" }}>
        {fileSizeMessage ? "* File cannot be larger than 30MB" : ""}
      </p>
      <p style={{ color: "#e43", margin: "10px 0 0 10px" }}>
        {errorMessage ? "* File format is not supported" : ""}
      </p>
    </>
  );
}

export default FileUpload;
