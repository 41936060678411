import { Button, Icon } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const stepsButtonStyle = {
  backgroundColor: "#2f87ee",
  color: "white",
  width: "140px",
  margin: "40px",
};
export function MaintenancePage() {
  const navigate = useNavigate();
  const { t } = useTranslation("common");

  return (
    <div style={{ textAlign: "center", padding: "100px" }}>
      <Icon style={{ fontSize: 90 }}>warning</Icon>
      <h1>{t("page-under-maintenance")}</h1>
      <p>{t("maintenance-page-text")}</p>
      <Button
        onClick={() => navigate("../")}
        variant='contained'
        style={stepsButtonStyle}
        type='submit'
      >
        Try again
      </Button>
    </div>
  );
}
