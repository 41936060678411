export function sowRemap(sowObj: {
  customFields: any[];
  otherLocationCodes: string;
  otherLocationNames: string;
  sowID: any;
  sowName: any;
  status: any;
  masterSow: any;
  billable: any;
  classificationCode: any;
  currency: any;
  maximumBudget: any;
  remainingBudget: any;
  otherPendingSpend: any;
  poNumber: any;
  clauses: any;
  events: any;
  schedules: any;
  revisionNumber: any;
  sowAgencyWorkers: any;
  sowTemplateName: any;
  sowTemplateType: any;
  startDate: any;
  submitDate: any;
  supplierPrimaryContactPerson: any;
  siteName: any;
  siteCode: any;
  businessUnit: any;
}) {
  const customFields: { [key: string]: string } = {};

  sowObj?.customFields?.forEach(
    (customField) => (customFields[customField.name] = customField.value)
  );

  const getLocation = () => {
    const locationsCode = sowObj.otherLocationCodes?.split("~");
    const locationsName = sowObj.otherLocationNames?.split("~");

    const locationsFormatted = locationsCode?.map(
      (element, index) =>
        element + " - " + (index < locationsName?.length ? locationsName[index] : "")
    );

    return locationsFormatted?.join(", ");
  };

  const sowInfo = {
    "SOW ID": sowObj.sowID,
    "SOW Name": sowObj.sowName,
    Status: sowObj.status,
    "Master SOW": sowObj.masterSow,
    Billable: sowObj.billable,
    "Classification Code": sowObj.classificationCode,
    currency: sowObj.currency,
    "Maximum Budget": sowObj.maximumBudget,
    "Remaining Budget": sowObj.remainingBudget,
    "Othe Pending Spend": sowObj.otherPendingSpend,
    "PO Number": sowObj.poNumber,
    Clauses: sowObj.clauses,
    Events: sowObj.events,
    Schedules: sowObj.schedules,
    "Revision Number": sowObj.revisionNumber,
    "SOW Agency Workers": sowObj.sowAgencyWorkers,
    "SOW Template Name": sowObj.sowTemplateName,
    "SOW Template Type": sowObj.sowTemplateType,
    "Start Date": sowObj.startDate,
    "Submit Date": sowObj.submitDate,
    "Supplier Primary Contact": sowObj.supplierPrimaryContactPerson,
    Site: `${sowObj.siteName} (${sowObj.siteCode})`,
    Location: getLocation() ?? "-",
    "Business Unit": sowObj.businessUnit ? sowObj.businessUnit : "",
  };

  return sowInfo;
}
