import { useQuery } from "@tanstack/react-query";
import apiInputService from "services/apiInputService";

interface canProcessProps {
  canProcess: boolean;
}

const getFormStatus = async (formIdentifier: string, refId?: string) => {
  const response = await apiInputService.get<canProcessProps>(
    `/clientSensitiveData/canProcess?&formIdentifier=${formIdentifier}&refId=${refId}`
  );

  const { data } = response;

  return data as canProcessProps;
};

export const useGetFormStatus = (formIdentifier?: string, refId?: string, options?: any) => {
  return useQuery<canProcessProps>(
    ["getFormStatus", refId],
    () => getFormStatus(formIdentifier, refId),
    {
      ...options,
    }
  );
};
