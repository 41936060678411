/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useState } from "react";

import TranslateIcon from "@material-ui/icons/Translate";

import { Box, Grid, Button } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import i18next from "i18next";
import MenuItem from "@material-ui/core/MenuItem";

import LoginForm from "./components/LoginForm";
import Footer from "./components/Footer";
import { isMobile } from "react-device-detect";

export default function LoginPage() {
  const [cardAnimaton, setCardAnimation] = useState("cardHidden");

  const [anchorEl, setAnchorEl] = React.useState(null);

  const changeLanguage = (lng) => {
    i18next.changeLanguage(lng);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickEnglish = () => {
    changeLanguage("en");
  };
  const handleClickSpanish = () => {
    changeLanguage("es");
  };
  const handleClickItalian = () => {
    changeLanguage("it");
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    const id = setTimeout(function () {
      setCardAnimation("");
    }, 700);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });

  return (
    <Box
      width='100vw'
      height='100%'
      minHeight='100vh'
      style={{
        backgroundColor: "#FAFAFB",
        overflowY: "hidden",
      }}
    >
      <Box
        style={{
          position: "relative",
          top: "0",
          right: "0",
          display: "inline-block",
          left: isMobile ? "76%" : "92%",
        }}
      >
        <Button aria-controls='simple-menu' aria-haspopup='true' onClick={handleClick}>
          <TranslateIcon />
        </Button>
        <Menu
          id='simple-menu'
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleClickEnglish}>English</MenuItem>
          <MenuItem onClick={handleClickSpanish}>Español</MenuItem>
          <MenuItem onClick={handleClickItalian}>Italiano</MenuItem>
        </Menu>
      </Box>
      <Box
        height='80vh'
        display='flex'
        justifyContent='center'
        alignItems='center'
        style={{
          backgroundColor: "#FAFAFB",
        }}
      >
        <Grid>
          <LoginForm />
        </Grid>
      </Box>
      <Footer />
    </Box>
  );
}
