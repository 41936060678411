import { useKeycloak } from "@react-keycloak/web";
import React, {
  createContext,
  PropsWithChildren,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

interface SubscriptionProps {
  children: ReactNode;
}

interface SubscriptionData {
  subscription: string;
  subscriptionMini: string;
  subscriptionsAvailable: RoleAttributes[];
  setSubscription(subscription: string): void;
}

export interface RoleAttributes {
  name?: string;
  mini?: string;
  subscriptionId?: string;
}

const SubscriptionContext = createContext({});

type SubscriptionProviderProps = PropsWithChildren<SubscriptionProps>;

export const SubscriptionProvider: React.FC<SubscriptionProviderProps> = ({ children }) => {
  const [subscription, setSubscription] = useState<string>("");
  const [subscriptionMini, setSubscriptionMini] = useState<string>("");
  const [subscriptionsAvailable, setSubscriptionAvailable] = useState<RoleAttributes[]>([]);
  const { keycloak } = useKeycloak();

  useEffect(() => {
    if (keycloak?.tokenParsed?.subscriptionAttributes) {
      setSubscriptionAvailable([
        ...keycloak.tokenParsed.subscriptionAttributes.map((element: string) =>
          JSON.parse(element)
        ),
      ]);
    }
  }, [keycloak.tokenParsed]);

  useEffect(() => {
    if (subscriptionsAvailable.length > 0) {
      let hasSub = false;

      const sub = sessionStorage.getItem("subscription");

      if (sub) hasSub = Boolean(subscriptionsAvailable.find((role) => role.subscriptionId === sub));

      setSubscriptionAndStore(hasSub ? sub : subscriptionsAvailable[0].subscriptionId);
    }
  }, [subscriptionsAvailable.length]);

  const setSubscriptionAndStore = (sub: string) => {
    setSubscription(sub);
    sessionStorage.setItem("subscription", sub);
    setSubscriptionMini(
      subscriptionsAvailable.find((role) => role.subscriptionId === sub)?.mini || ""
    );
  };

  return (
    <SubscriptionContext.Provider
      value={{
        subscription,
        setSubscription: setSubscriptionAndStore,
        subscriptionsAvailable,
        subscriptionMini,
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
};

export function useSubscription(): SubscriptionData {
  const context: any = useContext(SubscriptionContext);

  return context;
}
