import React, { SyntheticEvent, useEffect } from "react";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Grid, TextField, Typography } from "@material-ui/core";
// material-ui components

import "moment/locale/es-mx";
import ButtonTS from "components/CustomButtons/ButtonTS";
import CustomDialog from "components/CustomDialog/CustomDialog";
import { useTranslation } from "react-i18next";

import { useWorkItemManualValidation } from "services/input-core/work-items/manual-validate";
import { useForm, Controller } from "react-hook-form";
import { useQueryClient } from "@tanstack/react-query";
import AutoCompleteCustom from "components/AutoCompleteCustom";
import { compareStringsIgnoreCaseAndRemoveSpecialChars } from "utils/functions";
import { ManualValidationStatusEnum } from "utils/@types";

// core components

interface ManualValidationModalProps {
  open: boolean;
  OnSuccess: () => void;
  OnFail: () => void;
  workItemID: string;
  workOrderOrWorkerID: string;
  setOpen: (state: boolean) => void;
}

export default function ManualValidationModal({
  open,
  workItemID,
  setOpen,
  OnSuccess,
  OnFail,
  workOrderOrWorkerID,
}: ManualValidationModalProps) {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const queryClient = useQueryClient();
  const { t } = useTranslation("activity-item-table");
  const { watch, register, setValue, reset, control } = useForm();

  const { mutate: submitManualValidation } = useWorkItemManualValidation();

  const handleClose = () => {
    reset();
    setIsSubmitting(false);
    setOpen(false);
  };

  useEffect(() => {
    if (
      compareStringsIgnoreCaseAndRemoveSpecialChars(
        watch("decision"),
        ManualValidationStatusEnum.REJECTED
      )
    )
      setValue("expirationDate", undefined);
  }, [watch("decision")]);

  function onSubmit() {
    setIsSubmitting(true);
    submitManualValidation(
      {
        workItemID: workItemID,
        manualValidation: {
          user: sessionStorage.getItem("username"),
          observation: watch("observation"),
          expirationDate: watch("expirationDate"),
          approved: watch("decision") === ManualValidationStatusEnum.APPROVED,
        },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["workOrder-process-table", workOrderOrWorkerID]);
          queryClient.invalidateQueries(["worker-process-table", workOrderOrWorkerID]);
          queryClient.invalidateQueries([
            "WorkOrder",
            new URLSearchParams(window.location.search).get("id"),
          ]);
          OnSuccess();
          handleClose();
        },
        onError: (error) => {
          queryClient.invalidateQueries(["workOrder-process-table", workOrderOrWorkerID]);
          queryClient.invalidateQueries(["worker-process-table", workOrderOrWorkerID]);
          OnFail();
          handleClose();
        },
      }
    );
  }

  return (
    <CustomDialog
      handleClose={handleClose}
      open={open}
      dialogTitle={
        <>
          <Typography>Work Order</Typography>
          <Typography style={{ fontWeight: 700, fontSize: 18 }}>Manual Validation</Typography>
        </>
      }
      dialogContent={
        <Grid container spacing={3} style={{ overflow: "hidden" }}>
          <Grid item xs={6} style={{ display: "flex" }}>
            <Controller
              render={({ field: { onChange } }) => (
                <AutoCompleteCustom
                  label={t("decision")}
                  required
                  disablePortal
                  value={watch("decision")}
                  id='outlined-select-currency'
                  onChange={(e: SyntheticEvent<Element, Event>, data: { id: string }) => {
                    if (typeof data !== "string") onChange(data?.id ?? undefined);
                  }}
                  options={[
                    {
                      id: ManualValidationStatusEnum.APPROVED,
                      label: t(ManualValidationStatusEnum.APPROVED),
                    },
                    {
                      id: ManualValidationStatusEnum.REJECTED,
                      label: t(ManualValidationStatusEnum.REJECTED),
                    },
                  ]}
                />
              )}
              rules={{
                required: true,
              }}
              name='decision'
              control={control}
            />
          </Grid>
          {compareStringsIgnoreCaseAndRemoveSpecialChars(
            watch("decision"),
            ManualValidationStatusEnum.APPROVED
          ) && (
            <Grid item xs={6}>
              <LocalizationProvider
                adapterLocale='pt-BR'
                dateAdapter={AdapterDayjs}
                dateFormats={{ keyboardDate: "DD/MM/YYYY" }}
              >
                <DatePicker
                  key='expirationDate'
                  defaultValue={undefined}
                  label={t("expiration-date") + "*"}
                  disablePast
                  value={watch("expirationDate" || "")}
                  onChange={(value: any) => setValue("expirationDate", value)}
                />
              </LocalizationProvider>
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              variant='standard'
              label={t("observation")}
              style={{ width: "100%" }}
              value={watch("observation") || ""}
              required
              {...register("observation", { required: true })}
            />
          </Grid>
        </Grid>
      }
      dialogAction={
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "end",
            padding: 10,
          }}
        >
          <ButtonTS
            color='primary'
            variant='contained'
            disabled={
              isSubmitting ||
              (compareStringsIgnoreCaseAndRemoveSpecialChars(
                watch("decision"),
                ManualValidationStatusEnum.APPROVED
              ) &&
                !watch("expirationDate")) ||
              ((compareStringsIgnoreCaseAndRemoveSpecialChars(
                watch("decision"),
                ManualValidationStatusEnum.APPROVED
              ) &&
                !(
                  Object.values(watch())
                    .slice(0, 3)
                    .filter((value) => value !== "").length === 3
                )) ||
              compareStringsIgnoreCaseAndRemoveSpecialChars(
                watch("decision"),
                ManualValidationStatusEnum.REJECTED
              )
                ? !watch("observation").length
                : false || watch("decision") === undefined)
            }
            onClick={onSubmit}
          >
            Save
          </ButtonTS>
        </div>
      }
    />
  );
}
