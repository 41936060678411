import { useQuery } from "@tanstack/react-query";
import apiInputService from "services/apiInputService";

const fetchWorker = async (workerID: string) => {
  const { data } = await apiInputService.get(`/Worker/workerId/${workerID}`);

  return data;
};

export const useWorkerQuery = (workerID: string, options?: any) => {
  return useQuery<any>(["worker", workerID], () => fetchWorker(workerID), {
    ...options,
  });
};
