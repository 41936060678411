/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, CircularProgress, Grid, TextField, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import AutoCompleteCustom from "components/AutoCompleteCustom";
import CustomDialog from "components/CustomDialog/CustomDialog";
import InputSearcher from "components/InputSearcher";
import { useSubscription } from "hooks/useSubscription";
import { SyntheticEvent, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { getConfigFromClientConfig } from "services/input-core/clientConfig/get-client-config";
import { AvailableValuesProps, FieldsProps } from "services/input-core/clientConfig/type";
import Snackbar from "components/Snackbar/Snackbar";

import { useCreateSensitiveData } from "services/input-core/sensitive-data/create-sensitive-data";
import { useSensitiveData } from "services/input-core/sensitive-data/get-all-sensitive-data";
import { ProfileType } from "services/input-core/sensitive-data/type";
import { useReportToPosition } from "services/input-core/sows/get-report-to-position";
import { AddAlert } from "@material-ui/icons";
import { useAuth } from "hooks/useAuth";

type ApproveSheFileProps = {
  open: boolean;
  handleClose: () => void;
  workOrder: any;
};

interface SnackbarPayload {
  color: "success" | "warning" | "danger" | "info";
  message: string;
}

export default function ApproveWorkOrderDialog({
  open,
  handleClose,
  workOrder,
}: ApproveSheFileProps) {
  const refId = workOrder.workOrderID;
  const sowRefId = workOrder.sowID;
  const formIdentifier: ProfileType = workOrder.formIdentifier;
  const sowFormIdentifier: ProfileType =
    workOrder.formIdentifier === "WORK_ORDER_APPROVE_OREP" ? "SOW_OREP" : "SOW";

  const queryClient = useQueryClient();
  const { subscription } = useSubscription();
  const [clientConfig, setClientConfig] = useState();
  const { hasRealmRole } = useAuth();

  const { mutate: dataPositionCode, isLoading: buttonValidateLoading } = useReportToPosition();
  const [notification, setNotification] = useState({ open: false, message: "", color: "success" });

  useEffect(() => {
    setClientConfig(JSON.parse(localStorage.getItem("clientConfig")));
  }, []);

  const sensitiveDataOptions = getConfigFromClientConfig(clientConfig, formIdentifier);

  const fields = sensitiveDataOptions?.steps?.reduce((acc, step) => [...acc, ...step.fields], []);

  const { data: sensitiveDataFromSow, isFetching } = useSensitiveData({
    formIdentifier: sowFormIdentifier,
    refId: sowRefId,
  });

  const { mutate } = useCreateSensitiveData();

  const {
    setValue,
    register,
    watch,
    getValues,
    reset,
    control,
    formState: { errors },
  } = useForm({});

  const getNameByDisplayName = (displayName: string) => {
    return fields?.find((field) => field.displayName.default === displayName)?.name;
  };

  useEffect(() => {
    if (!fields) return;

    if (sensitiveDataFromSow?.length > 0) {
      const loadedState: any = sensitiveDataFromSow.reduce((prev = {}, current) => {
        return {
          ...prev,
          [getNameByDisplayName(current.label.default)]: current._id,
        };
      }, {});

      reset(loadedState);
    }
  }, [sensitiveDataFromSow, sensitiveDataOptions]);

  useEffect(() => {
    if (!fields) return;

    if (sensitiveDataFromSow)
      if (sensitiveDataFromSow?.length > 0) {
        if (sensitiveDataFromSow[0]._id !== watch("orgUnit")) {
          setValue("personnelArea", "");
          setValue("personnelSubArea", "");
          setValue("businessArea", "");
        }
      }
  }, [watch("orgUnit")]);

  const formData = getValues();

  const handleSubmit = () => {
    const formDataFormatted = Object.keys(formData).map((key) => ({
      name: key,
      value: formData[key],
    }));

    mutate(
      {
        refId,
        subscriptionId: subscription,
        formIdentifier: formIdentifier,
        formData: formDataFormatted,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["WorkOrders", subscription]);
          queryClient.invalidateQueries(["sensitiveData", formIdentifier, refId]);
          reset();
          handleClose();
        },
        onError: () => {
          queryClient.invalidateQueries(["WorkOrders", subscription]);
          reset();
          handleClose();
        },
      }
    );
  };

  const buttonProps: any = {
    color: "primary",
    onClick: () => handleSubmit(),
  };

  const verifyEmailButtonProps: any = {
    color: "primary",
    variant: "outlined",
    size: "small",
    onClick: () => getPositionCode(),
  };

  const showNotification = (payload: SnackbarPayload) => {
    setNotification({ message: payload.message, color: payload.color, open: true });
  };

  const getPositionCode = async () => {
    const emailForm = getValues("email");

    if (emailForm) {
      dataPositionCode(emailForm, {
        onSuccess: (response) => {
          setValue("positionCode", response);

          showNotification({
            color: "success",
            message: "Email verified!",
          });
        },
        onError: () => {
          showNotification({
            color: "danger",
            message: "Invalid email.",
          });
        },
      });
    }
  };
  const renderFields = (field: FieldsProps) => {
    if (field.name === "email") {
      return (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: "16px",
              alignItems: "center",
            }}
          >
            <TextField
              id='email'
              label='Email'
              variant='standard'
              style={{
                display: "flex",
                width: "100%",
                marginBottom: "10px",
              }}
              value={watch("email") || ""}
              {...register(field.name, { required: field.required })}
            />
            <Button
              {...verifyEmailButtonProps}
              disabled={
                !hasRealmRole(["Human Resources - Org Data Structure"]) ||
                formData["email"] === "" ||
                !formData["email"] ||
                buttonValidateLoading
              }
              style={{
                height: 30,
                width: "40%",
              }}
            >
              Verify Email
            </Button>
          </div>
        </>
      );
    }

    switch (field.fieldType) {
      case "string":
        return (
          <TextField
            id='standard-basic'
            variant='standard'
            label={field.displayName.default}
            style={{ width: "100%" }}
            value={watch(field.name) || ""}
            required={field.required}
            {...register(field.name, { required: field.required })}
            disabled={field?.allowWhenIsFilled && !watch(field?.allowWhenIsFilled)}
          />
        );

      case "select":
        return (
          <Controller
            render={({ field: { onChange } }) => (
              <AutoCompleteCustom
                label={field.displayName.default}
                disablePortal
                value={
                  field?.availableValues?.find((a) => a?.value === watch(field?.name))?.displayName
                    ?.default ?? ""
                }
                id='outlined-select-currency'
                onChange={(e: SyntheticEvent<Element, Event>, data: { id: string }) => {
                  if (typeof data !== "string") onChange(data?.id ?? undefined);
                }}
                options={field.availableValues
                  .filter((option: AvailableValuesProps) =>
                    !option.showWhen
                      ? option
                      : watch(option.showWhen[0].key) === option.showWhen[0].value[0]
                  )
                  .map((option: AvailableValuesProps) => ({
                    id: option.value,
                    label: option.displayName.default,
                  }))}
                sx={{
                  width: "100% ",
                }}
                disabled={field?.allowWhenIsFilled && !watch(field?.allowWhenIsFilled)}
              />
            )}
            rules={{
              required: field.required,
            }}
            name={field.name}
            control={control}
          />
        );

      case "searcher":
        return <InputSearcher fieldData={field} hookFormWatch={watch} hookFormControl={control} />;
    }
  };

  return (
    <>
      {notification.open && (
        <Snackbar
          place='bc'
          color={notification.color}
          icon={AddAlert}
          message={notification.message}
          open={notification.open}
          closeNotification={() => setNotification({ open: false, message: "", color: "success" })}
          close 
        />
      )}

      <CustomDialog
        handleClose={handleClose}
        open={open}
        dialogTitle={
          <>
            <Typography
              style={{
                fontWeight: 700,
                fontSize: 18,
                textAlign: "center",
              }}
            >
              {workOrder.workOrderID}
            </Typography>
            <Typography
              style={{
                fontWeight: 700,
                fontSize: 18,
                textAlign: "center",
              }}
            >
              {workOrder.firstName} - {workOrder.lastName}
            </Typography>
          </>
        }
        dialogContent={
          <>
            {isFetching ? (
              <CircularProgress />
            ) : (
              <Grid container spacing={2} style={{ width: "100%" }}>
                {fields
                  ?.sort((fieldA, fieldB) => fieldA.sequence - fieldB.sequence)
                  ?.map((field: FieldsProps) => (
                    <Grid
                      key={field.name}
                      item
                      xs={field.gridSize ?? 12}
                      style={{
                        paddingTop: field.fieldType === "checkbox" ? 12 : 24,
                        display: field.showWhen
                          ? field.showWhen[0].value[0] === watch(field.showWhen[0].key)
                            ? "inherit"
                            : "none"
                          : "inherit",
                      }}
                    >
                      {field.showWhen
                        ? field.showWhen[0].value[0] === watch(field.showWhen[0].key) &&
                          renderFields(field)
                        : renderFields(field)}
                      {errors[field.name] && (
                        <Grid key={field.name} item xs={field.gridSize ?? 12}>
                          This field is required
                        </Grid>
                      )}
                    </Grid>
                  ))}
              </Grid>
            )}
          </>
        }
        dialogAction={
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "end",
              padding: 10,
            }}
          >
            <Button {...buttonProps}>SAVE</Button>
          </div>
        }
      />
    </>
  );
}
