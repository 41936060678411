import { RoleAttributes } from "hooks/useSubscription";

export const isCurrentSubscriptionFrom = (
  name: string,
  subscription: string,
  subscriptionsAvailable: RoleAttributes[]
) => {
  const sub = subscriptionsAvailable?.find((s) => s.mini === "BEL");
  return subscription === sub?.subscriptionId;
};
