import { CircularProgress, makeStyles } from "@material-ui/core";
import { Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import Badge from "components/Badge/Badge";
import moment from "moment";
import api from "services/api";

const styles: any = {
  dialogTitle: {
    fontWeight: 700,
    fontSize: 18,
    textAlign: "center",
  },
  gridContainer: {
    width: "100%",
    paddingTop: 30,
  },
  gridItem: {},
  logoContainer: {
    display: "flex",
    justifyContent: "end",
    alignItems: "start",
  },
  logo: {
    width: 40,
    height: 40,
    marginRight: 10,
  },
  logoText: {
    fontWeight: 700,
    fontSize: 24,
    textAlign: "center",
    color: "#2033a5",
  },
  avatar: {
    position: "relative",
    top: "-5%",
    marginRight: "5%",
    borderRadius: 5,
    width: 200,
  },
  workerInfoContainer: {
    top: "-5%",
    alignSelf: "center",
    display: "flex",
    flexDirection: "column",
  },
  workerInfoText: {
    fontSize: 18,
  },
  QRCode: {
    transition: "transform 130ms ease-out",
    cursor: "pointer",
    "&:hover": {
      transform: "scale(1.1)",
    },
  },
};

const useStyles = makeStyles(styles);

interface Column {
  id: "name" | "type" | "isValid" | "expirationDate";
  label: string;
  minWidth?: number;
  align?: "left";
  format?: (value: any) => any;
}

const columns: readonly Column[] = [
  { id: "name", label: "Name" },
  { id: "type", label: "Type" },
  { id: "expirationDate", label: "Expiration Date" },
  {
    id: "isValid",
    label: "Status",
    format: (value: boolean) =>
      value ? (
        <Badge {...{ color: "success" }}>Valid</Badge>
      ) : (
        <Badge {...{ color: "danger" }}>Invalid</Badge>
      ),
  },
];

interface PhysicalCardPagePayload {
  _id: string;
}

export default function PhysicalCard() {
  const classes = useStyles();
  const { t } = useTranslation("physical-card");
  const _id = new URLSearchParams(window.location.search).get("_id");

  const [data, setData] = useState({
    labels: [],
    badgeInfos: [],
  });
  const { watch, setValue } = useForm();
  const currentWorker = watch("currentWorker");

  const [sessionTimeout, setSessionTimeout] = useState(false);
  const [loadingData, setLoadingData] = useState(true);

  const fetchWorkerPhysicalCardPage = async ({ _id }: PhysicalCardPagePayload) => {
    await api
      .get(`/worker/badge/virtual/${_id}`)
      .then((response) => {
        setData(response.data);
        setLoadingData(false);
      })
      .catch((error) => {
        alert(error);
        setLoadingData(false);
      });
  };

  useEffect(() => {
    fetchWorkerPhysicalCardPage({ _id });

    if (!sessionStorage.getItem("physicalCardOpenDate"))
      sessionStorage.setItem("physicalCardOpenDate", moment().toISOString());

    const interval = setInterval(() => {
      if (moment().isAfter(moment(sessionStorage.getItem("physicalCardOpenDate"))?.add(5, "m")))
        setSessionTimeout(true);
    }, 1000);

    return () => {
      sessionStorage.removeItem("physicalCardOpenDate");
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (data?.badgeInfos?.length > 0) setValue("currentWorker", data?.badgeInfos[0]);
  }, [data]);

  return sessionTimeout ? (
    <Typography className={classes.workerInfoText} style={{ padding: 20 }}>
      <h3>{t("session-timeout")}</h3>
    </Typography>
  ) : loadingData ? (
    <div style={{ display: "flex", alignContent: "center", justifyContent: "center", padding: 20 }}>
      <CircularProgress />{" "}
    </div>
  ) : (
    <Grid container className={classes.gridContainer} spacing={4}>
      {/* <Grid item className={classes.gridItem} xs={12}>
        <Controller
          render={({ field: { onChange } }) => (
            <AutoCompleteCustom
              label={t("select-worker")}
              disablePortal
              value={watch("workerLabel") || ""}
              id='outlined-select-currency'
              onChange={(e: SyntheticEvent<Element, Event>, data: { id: string }) => {
                if (typeof data !== "string") onChange(data?.id ?? undefined);
              }}
              options={(data?.labels ?? []).map((option: any) => ({
                id: option,
                label: option,
              }))}
              sx={{
                width: 100,
              }}
            />
          )}
          name='workerLabel'
          control={control}
        />
      </Grid> */}

      {watch("currentWorker") && (
        <>
          <Grid item className={classes.gridItem} xs={12} md={2}>
            <img src={currentWorker?.workerImage} alt='Avatar' className={classes.avatar} />
          </Grid>

          <Grid item className={classes.gridItem} xs={12} md={8}>
            <div className={classes.workerInfoContainer}>
              <Grid item className={classes.gridItem} xs={12} md={12}>
                <Typography
                  className={classes.workerInfoText}
                  style={{ display: "flex", alignContent: "center" }}
                >
                  <b style={{ marginRight: 10 }}>{t("status")}: </b>
                  {currentWorker?.activityItems?.find(
                    (activityItem: any) => !activityItem.isValid
                  ) ? (
                    <Badge {...{ color: "danger" }}>Invalid</Badge>
                  ) : (
                    <Badge {...{ color: "success" }}>Valid</Badge>
                  )}
                </Typography>
              </Grid>
              <Typography className={classes.workerInfoText}>
                <b>{t("name")}: </b> {currentWorker?.firstName} {currentWorker?.lastName}
              </Typography>

              <Typography className={classes.workerInfoText}>
                <b>{t("id")}: </b> {currentWorker?.consolidatedWorkerId}
              </Typography>

              <Typography className={classes.workerInfoText}>
                <b>{t("worker-role")}: </b> {currentWorker?.sowWorkerRole}
              </Typography>

              <Typography className={classes.workerInfoText}>
                <b>{t("worker-role-end-date")}: </b> {currentWorker?.endDate}
              </Typography>

              <Typography className={classes.workerInfoText}>
                <b>{t("supplier")}: </b> {currentWorker?.supplierName}
              </Typography>

              <Typography className={classes.workerInfoText}>
                <b>{t("driver-license")}: </b> {currentWorker?.driverLicenseType} &emsp;
              </Typography>
            </div>
          </Grid>

          <Grid item className={classes.gridItem} xs={12}>
            <Typography className={classes.workerInfoText}>
              <b>{t("activity-items")}</b>
            </Typography>

            <Table>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id} align={column.align}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {currentWorker?.activityItems?.map((row: any) => {
                  return (
                    <TableRow hover tabIndex={-1} key={row.code}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format ? column.format(value) : value ?? "-"}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Grid>
        </>
      )}
    </Grid>
  );
}
