import { useMutation } from "@tanstack/react-query";
import apiInputService from "services/apiInputService";

type ManualValidationPayload = {
  workItemID: string;
  manualValidation: {
    observation: string;
    expirationDate: Date;
    approved: boolean;
    user: string;
  };
};

const workItemManualValidation = async (manualValidationPayload: ManualValidationPayload) => {
  return await apiInputService.post("/manual-validation", manualValidationPayload);
};

export const useWorkItemManualValidation = (options?: any) => {
  return useMutation(workItemManualValidation, {
    ...options,
  });
};
