import { useQuery } from "@tanstack/react-query";
import apiInputService from "services/apiInputService";

export const fetchActivityItemTable = async (workOrderID: string) => {
  const { data } = await apiInputService.get(`/workOrders/statusActivityItems/${workOrderID}`);

  return data as any;
};

export const useWorkOrderActivityItemsWithStatus = (workOrderID: string, options?: any) => {
  return useQuery<any>(
    ["work-order-activity-items-status", workOrderID],
    () => fetchActivityItemTable(workOrderID),
    {
      enabled: Boolean(workOrderID),
      ...options,
    }
  );
};
