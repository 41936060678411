/* eslint-disable no-undef */
import { Box, CircularProgress, Typography } from "@material-ui/core";
import ButtonTS from "components/CustomButtons/ButtonTS";

import { Outlet } from "react-router-dom";
import { useAuth } from "hooks/useAuth";

export const PrefetchingErrorScreen = () => {
  const { logout } = useAuth();

  function handleLogout() {
    logout();
  }

  return (
    <Box
      display='flex'
      minWidth='100vw'
      minHeight='100vh'
      justifyContent='center'
      flexDirection='column'
      alignItems='center'
    >
      <h3>"We are setting your username in U-Verify. Check back after 15 Minutes."</h3>
      <ButtonTS color='primary' onClick={handleLogout}>
        OK
      </ButtonTS>
    </Box>
  );
};

export const PrefetchingScreen = () => (
  <div
    style={{
      width: "100vw",
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
    }}
  >
    <h1 style={{ marginBottom: 50 }}>Uverify</h1>
    <CircularProgress style={{ marginBottom: 20 }} />
    <Typography>We are loading your data</Typography>
    <Typography>Please wait a moment!</Typography>
  </div>
);

const Prefetcher = () => {
  const hasJsonCustomAttributes = localStorage.getItem("hasJsonCustomAttributes");
  const hasAngloEmail = localStorage.getItem("hasAngloEmail");

  if (
    hasAngloEmail === "true" &&
    (hasJsonCustomAttributes === "false" || hasJsonCustomAttributes === "undefined")
  ) {
    return <PrefetchingErrorScreen />;
  }
  return <Outlet />;
};

const PrefetchComponent = () => {
  return <Prefetcher />;
};

export default PrefetchComponent;
