import WorkerProfile from "./WorkerProfile";
import WorkOrderProfile from "./WorkOrderProfile";

export default function Profile() {
  const type = new URLSearchParams(window.location.search).get("type");

  switch (type) {
    case "worker":
      return <WorkerProfile />;
    case "workOrder":
      return <WorkOrderProfile />;
    default:
      return <WorkerProfile />;
  }
}
