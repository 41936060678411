import React from "react";
import Modal from "@mui/material/Modal";
import { Button, Typography } from "@mui/material";
import { Box } from "@mui/material";
import { actionsStyle, buyBtnStyle, modalHeader } from "./styles";
import { IModalProps } from "./interface";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "5px",
  minWidth: "600px",
};

export const ModalForm: React.FC<IModalProps> = ({
  title,
  formName,
  step,
  confirmLabel,
  cancelLabel,
  onClick,
  handleClose,
  open,
  children,
  disabled,
}) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        {formName !== undefined ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Box sx={modalHeader}>
              <Typography variant='subtitle1' textTransform='capitalize'>
                {title}
              </Typography>
              <Typography variant='h6' textTransform='capitalize'>
                {formName}
              </Typography>
              <Typography variant='body2' textTransform='capitalize'>
                {step}
              </Typography>
            </Box>
          </div>
        ) : (
          ""
        )}
        <Box sx={{ p: 3, marginTop: "60px" }}>{children}</Box>
        <Box sx={actionsStyle}>
          {cancelLabel && (
            <Button sx={buyBtnStyle} variant='contained' autoFocus onClick={handleClose}>
              {cancelLabel ?? "CANCEL"}
            </Button>
          )}
          {confirmLabel && (
            <Button
              sx={buyBtnStyle}
              variant='contained'
              onClick={onClick}
              disabled={disabled}
              type='submit'
            >
              {confirmLabel ?? "FINISH"}
            </Button>
          )}
        </Box>
      </Box>
    </Modal>
  );
};
