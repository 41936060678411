import Search from "@material-ui/icons/Search";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import CustomSkeleton from "components/CustomSkeleton/CustomSkeleton";
import ReactTable from "components/ReactTable/ReactTable";
import { sortDateWithMoment } from "helpers/customSortMethods";
import { useTranslation } from "react-i18next";

interface ProcessTableProps {
  processData: any[];
  isProcessLoading: boolean;
  classes: any;
}

export default function ProcessTable({
  processData,
  isProcessLoading,
  classes,
}: ProcessTableProps) {
  const { t } = useTranslation("worker-profile");

  const columns = [
    {
      Header: t("id"),
      accessor: "code",
    },
    {
      Header: t("action"),
      accessor: "actionID",
    },
    {
      Header: t("document"),
      accessor: "documentName",
    },
    {
      Header: t("worker-table-status"),
      accessor: "status",
    },
    {
      Header: t("result"),
      accessor: "result",
    },
    {
      Header: t("date"),
      accessor: "date",
      sortType: sortDateWithMoment,
      sortDescFirst: true,
    },
    {
      Header: t("worker-table-action"),
      accessor: "actions",
    },
  ];

  return (
    <Card>
      <CardHeader color='rose' icon>
        <CardIcon color='rose'>
          <Search />
        </CardIcon>
        <h4 className={classes.cardIconTitle}>{t("processes")}</h4>
      </CardHeader>
      <CardBody>
        {isProcessLoading ? (
          <CustomSkeleton numberOfLines={5} />
        ) : (
          <ReactTable
            columns={columns}
            data={processData}
            {...{ defaultSorted: { id: "date", desc: true } }}
          />
        )}
      </CardBody>
    </Card>
  );
}
