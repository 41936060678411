import axios from "axios";
import { differenceInYears } from "date-fns";
import { isNumber } from "lodash";
import { formateDateWithTZ } from "utils/functions";

export interface StrategyArgs {
  handle(args: any | any[]): any;
}

const subscription = sessionStorage.getItem("subscription");

class formateDateBirthdayStrategy implements StrategyArgs {
  handle(args: any) {
    try {
      const dateBirthdayFormatted = formateDateWithTZ(args);
      return dateBirthdayFormatted;
    } catch {
      return "Not found";
    }
  }
}

class formateDateBirthdayForAgeStrategy implements StrategyArgs {
  handle(args: any) {
    try {
      const dateBirthdayForAge = differenceInYears(new Date(), new Date(args));

      if (!isNumber(dateBirthdayForAge)) throw new Error("Not found");

      return dateBirthdayForAge;
    } catch {
      return "Not found";
    }
  }
}

class removeValueFieldIfValueIsTrue implements StrategyArgs {
  handle(args: any) {
    const resetFormFunction = args[1];

    resetFormFunction(args[0]);
  }
}

class validateWhenValueIsMeet implements StrategyArgs {
  handle(args: any) {
    return args
      ?.map((arg: any) => arg.currentValue === arg.valueToMeet)
      .every((v: boolean) => v === true);
  }
}

class disableFieldIfValueIsMeet implements StrategyArgs {
  handle(args: any) {
    return args
      ?.map((arg: any) => arg.currentValue === arg.valueToMeet)
      .every((v: boolean) => v === true);
  }
}

class callApi implements StrategyArgs {
  handle(args: any) {
    const userSubscription = subscription ? subscription : args[2];
    const userToken = args[3];
    const url = args[0][0].url + `?subscription=${userSubscription}&value=${args[1]}`;

    const response = axios({
      url: url,
      method: args[0][0].method,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
    });
    return response;
  }
}

class convertToBase64 implements StrategyArgs {
  async handle(args: any) {
    const images = args[0];

    return Promise.all(
      images.map(
        (image: Blob) =>
          new Promise((resolve, reject) => {
            const fileReader = new FileReader();

            fileReader.onload = (file) => {
              resolve((file.target.result as any).split(",")[1]);
            };

            fileReader.onerror = (error) => reject(error);

            fileReader.readAsDataURL(image);
          })
      )
    );
  }
}

const allStrategies = new Map<string, StrategyArgs>();

export function getStrategy(strategyName: string) {
  allStrategies.set("formateDateBirthday", new formateDateBirthdayStrategy());
  allStrategies.set("formateDateBirthdayForAge", new formateDateBirthdayForAgeStrategy());
  allStrategies.set("removeValueFieldIfValueIsTrue", new removeValueFieldIfValueIsTrue());
  allStrategies.set("disableFieldIfValueIsMeet", new disableFieldIfValueIsMeet());
  allStrategies.set("validateWhen", new validateWhenValueIsMeet());
  allStrategies.set("callApi", new callApi());
  allStrategies.set("convertToBase64", new convertToBase64());

  return allStrategies.get(strategyName);
}
