import { useKeycloak } from "@react-keycloak/web";
import { useEffect } from "react";
import { useNavigate, Outlet, useLocation } from "react-router-dom";

export default function AuthRedirector() {
  const { keycloak, initialized } = useKeycloak();
  const navigate = useNavigate();
  const location: any = useLocation();
  useEffect(() => {
    if (initialized) {
      if (keycloak?.authenticated) {
        navigate(location?.state?.from ?? "/app/dashboard", {
          replace: true,
          state: { from: window?.location?.pathname },
        });
      }
    }
  }, [initialized, keycloak?.authenticated]);

  return !keycloak?.authenticated ? <Outlet /> : null;
}
