import { useRef } from "react";


// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

import LoadingBar from "react-top-loading-bar";
import WorkerTable from "components/Worker/WorkerTable";
import { useTranslation } from "react-i18next";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
};

const useStyles = makeStyles(styles);

export default function Workers() {
  const { t } = useTranslation("worker-profile");
  const ref = useRef(null);

 
  const classes = useStyles();
  return (
    <GridContainer>
      <LoadingBar color="#ff9800" ref={ref} />
      <WorkerTable omit={["vendorName", "vendorCode"]}/>
    </GridContainer>
  );
}
