import { makeStyles } from '@material-ui/core/styles';
import { primaryColor, blackColor, hexToRgb } from "assets/jss/material-dashboard-pro-react.js";

interface TabStyleProps {
    variant: 'card' | 'page';
}

const tabSizeDefinitions = {
    card: "100px",
    page: "120px",
}

export const styles = makeStyles(({ palette }) => ({
    root: (props: TabStyleProps) => ({
        backgroundColor: "transparent",

        "& span": {
            padding: "2px 0",
            minWidth: tabSizeDefinitions[props.variant],
            color: "inherit",

            "& span": {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "0px 2px",
                background: primaryColor[0],
                borderRadius: 999,
                color: "white",
                minWidth: 36,
                marginLeft: "10px",
            }
        },

        "&[data-state='active']": {
            background: primaryColor[0],
            color: "white",
            fontWeight: "bold",
            boxShadow:
                "0 2px 2px 0 rgba(" +
                hexToRgb(primaryColor[0]) +
                ", 0.14), 0 3px 1px -2px rgba(" +
                hexToRgb(primaryColor[0]) +
                ", 0.2), 0 1px 5px 0 rgba(" +
                hexToRgb(primaryColor[0]) +
                ", 0.12)",
            "&:hover,&:focus": {
                backgroundColor: primaryColor[0],
                boxShadow:
                    "0 14px 26px -12px rgba(" +
                    hexToRgb(primaryColor[0]) +
                    ", 0.42), 0 4px 23px 0px rgba(" +
                    hexToRgb(blackColor) +
                    ", 0.12), 0 8px 10px -5px rgba(" +
                    hexToRgb(primaryColor[0]) +
                    ", 0.2)",
            },

            "& span": {
                "& span": {
                    background: "white",
                    color: primaryColor[0],
                }
            },
        }
    }),


}))