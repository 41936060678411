import { InputHTMLAttributes } from "react";
import { Controller } from "react-hook-form";
import styled from "styled-components";
import InputMask from "react-input-mask";
import { masksByCountryCode } from "utils/masks";

type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  name: string;
  type: string;
  label?: string;
  control: any;
  rules?: any;
  countryCode?: string;
};

export const CustomInputField = ({
  name,
  control,
  countryCode,
  rules,
  type,
  label,
  ...props
}: InputProps) => {

  return (
    <>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) => (
          <LabelFloat>
            <InputMask mask={masksByCountryCode[countryCode]} type={type} {...props} {...field}>
              <Input type={type} />
            </InputMask>
            <LabelText  aria-disabled={props.disabled} >{label}</LabelText>
          </LabelFloat>
        )}
      />
    </>
  );
};

const LabelFloat = styled.div`
  position: relative;
  padding-top: 13px;
`;

const Input = styled.input`
  border: 0;
  border-bottom: 1px solid #000;
  outline: none;
  /* min-width: 180px; */
  font-size: 16px;
  transition: all 0.1s ease-out;
  -webkit-transition: all 0.1s ease-out;
  -moz-transition: all 0.1s ease-out;
  -webkit-appearance: none;
  border-radius: 0;

  &:focus {
    border-bottom: 2px solid #3951b2;
  }

  &::placeholder {
    color: transparent;
  }

  /* &:required:invalid + label {
    color: red;
  } */

  /* &:focus:required:invalid {
    border-bottom: 2px solid red;
  } */

  &:required:invalid + label:before {
    content: "*";
  }

  &:disabled {
    color: #9e9e9e;
    border-bottom: 1px solid #9e9e9e;
  }

  &:focus + label,
  &:not(:placeholder-shown) + label {
    font-size: 13px;
    margin-top: 0;
    /* color: #3951b2; */
  }
`;

const LabelText = styled.label`
  font-size: 16px;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 10px;
  transition: all 0.1s ease-out;
  -webkit-transition: all 0.1s ease-out;
  -moz-transition: all 0.1s ease-out;

  &:[aria-disabled="true"] {
    opacity: 0.5;
  }
  
`;
