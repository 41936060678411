import React from 'react'
import Button from './Button'

export default function ButtonTS({...props}: any) {
  return (
    <>
      <Button {...props}>{props.children}</Button>
    </>
  )
}
