import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import apiInputService from "services/apiInputService";
import { SensitiveDataQuery } from "./type";

const fetchSensitiveData = async (query?: SensitiveDataQuery) => {
  const sensitiveData = await apiInputService.get("/clientSensitiveData", {
    params: { ...query },
  });

  const { data } = sensitiveData;

  return data ?? [];
};

export const useSensitiveData = (query?: SensitiveDataQuery, options?: any) => {
  return useQuery<any[]>(
    ["sensitiveData", ...Object.values(query)],
    () => fetchSensitiveData(query),
    {
      ...options,
    }
  );
};
