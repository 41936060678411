/*eslint-disable*/
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import styles from 'assets/jss/material-dashboard-pro-react/components/footerStyle.js';
import Modal from 'components/SyncModal/Modal';
import RegularButton from 'components/CustomButtons/Button';
import { Settings } from '@material-ui/icons';
import Snackbar from 'components/Snackbar/Snackbar';

const useStyles = makeStyles(styles);
export default function Footer(props) {
  const classes = useStyles();
  const { fluid, white, rtlActive } = props;

  const [syncModalOpen, setSyncModalOpen] = React.useState(false);
  const [syncSnackOpen, setSyncSnackOpen] = React.useState(false);

  var container = cx({
    [classes.container]: !fluid,
    [classes.containerFluid]: fluid,
    [classes.whiteColor]: white,
  });

  var anchor =
    classes.a +
    cx({
      [' ' + classes.whiteColor]: white,
    });

  var block = cx({
    [classes.block]: true,
    [classes.whiteColor]: white,
  });

  return (
    <footer className={classes.footer}>
      <Snackbar
        place='br'
        color={'info'}
        message={'Sync started'}
        open={syncSnackOpen}
        closeNotification={() => setSyncSnackOpen(false)}
        close
      />
      <Modal
        open={syncModalOpen}
        onClose={() => setSyncModalOpen(false)}
        setSyncSnackOpen={setSyncSnackOpen}
      />
      <div className={container}>
        <RegularButton
          justIcon
          round
          color='transparent'
          size='sm'
          onClick={() => setSyncModalOpen(true)}
        >
          <Settings />
        </RegularButton>
        <p className={classes.right}>
          &copy; {1900 + new Date().getYear()}{' '}
          {' uVerify, a '}
          <a
            href='https://www.stratesys-ts.com'
            className={anchor}
            target='_blank'
          >
            {'Stratesys '}
          </a>
          {`product. ${process.env?.REACT_APP_BUILD_VERSION}`}
        </p>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  fluid: PropTypes.bool,
  white: PropTypes.bool,
  rtlActive: PropTypes.bool,
};
