import { FC } from "react";
import { TextField, TextFieldProps } from "@material-ui/core";
import Autocomplete from "@mui/material/Autocomplete";
import { SxProps, Theme } from "@mui/material";

type AutoCompleteOptions = {
  id: string;
  label: string;
};

interface IAutoCompleteCustom {
  id: string;
  label: string;
  placeholder?: string;
  type?: string;
  options: readonly AutoCompleteOptions[];
  value: string;
  disabled?: boolean;
  onChange: any;
  onBlur?: () => void;
  onFocus?: () => void;
  onTypeChange?: (value: string) => void;
  onTypeBlur?: (value: string) => void;
  onTypeFocus?: (value: string) => void;
  onTypeSearch?: (value: string) => void;
  onSearchClear?: () => void;
  onSearch?: (value: string) => void;
  onValueClick?: (value: string) => void;
  onValueBlur?: (value: string) => void;
  inputRef?: any;
  required?: boolean;
  params?: JSX.IntrinsicAttributes & TextFieldProps;
  disablePortal?: boolean;
  sx?: unknown;
}

const autoImportStyles: SxProps<Theme> = {
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export const AutoCompleteCustom: FC<IAutoCompleteCustom> = ({
  value,
  id,
  onChange,
  options,
  inputRef,
  label,
  required,
  disabled,
}) => {
  return (
    <Autocomplete
      disablePortal
      value={value as any}
      id={id}
      disabled={disabled}
      onChange={onChange}
      options={options}
      sx={autoImportStyles}
      renderInput={({ ...params }: TextFieldProps) => (
        <TextField
          inputRef={inputRef}
          value={value}
          id={id}
          disabled={disabled}
          label={label}
          required={required}
          {...params}
        />
      )}
    />
  );
};
