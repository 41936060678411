import { Grid } from "@material-ui/core";
import { Box } from "@mui/material";
import DataCard from "components/DataCard/DataCard";
import { useSubscription } from "hooks/useSubscription";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDashboard } from "services/input-core/dashboard/get-dashboard-data";

enum IconColor {
  Success = "success",
  Warning = "warning",
  Info = "info",
}

export default function DashboardCards() {
  const { t } = useTranslation("dashboard");
  const { subscription } = useSubscription();
  const { data, isLoading } = useDashboard(subscription);
  const navigate = useNavigate();

  const pairsOfCards = [];

  const dataCards = [
    {
      value: data?.supplier,
      title: t("suppliers"),
      iconName: "business_center",
      iconColor: IconColor.Warning,
      linkText: `See ${t("suppliers")}`,
      handleClick: () => navigate("../suppliers/list"),
    },
    {
      value: data?.sow,
      title: t("sows"),
      iconName: "business_center",
      iconColor: IconColor.Info,
      linkText: `See ${t("sows")}`,
      handleClick: () => navigate("../sows/list"),
    },
    {
      value: data?.worker,
      title: t("workers"),
      iconName: "groups",
      iconColor: IconColor.Success,
      linkText: `See ${t("workers")}`,
      handleClick: () => navigate("../workers/list"),
    },
    {
      value: data?.workOrder,
      title: t("work-orders"),
      iconName: "groups",
      iconColor: IconColor.Success,
      linkText: `See ${t("work-orders")}`,
      handleClick: () => navigate("../workers/list"),
    },
    {
      value: data?.documentFile,
      title: t("documents"),
      iconName: "folder",
      iconColor: IconColor.Warning,
    },
    {
      value: data?.workItem,
      title: t("processes"),
      iconName: "search",
      iconColor: IconColor.Info,
    },
  ];

  for (let i = 0; i < dataCards.length; i += 2) {
    pairsOfCards.push(dataCards.slice(i, i + 2));
  }

  return (
    <>
      <Box style={{ width: "100%" }}>
        {pairsOfCards.map((pair, index) => (
          <Grid container spacing={2} key={index}>
            {pair.map((cardData, cardIndex) => (
              <Grid item xs={12} sm={6} key={cardIndex}>
                <DataCard
                  isLoading={isLoading}
                  key={cardIndex}
                  title={cardData.title}
                  value={cardData.value}
                  handleClick={cardData.handleClick}
                  linkText={cardData.linkText}
                  iconName={cardData.iconName}
                  iconColor={cardData.iconColor}
                />
              </Grid>
            ))}
          </Grid>
        ))}
      </Box>
    </>
  );
}
