import { useMutation } from "@tanstack/react-query";
import apiInputService from "services/apiInputService";

interface uploadWorkerAvatarPayload {
  formData: FormData;
  workerId: string;
}

const uploadWorkerAvatar = async ({ formData, workerId }: uploadWorkerAvatarPayload) => {
  return await apiInputService.post("worker/identification/save/" + workerId, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const useUploadWorkerAvatar = (options?: any) => {
  return useMutation(uploadWorkerAvatar, {
    ...options,
  });
};
