import { useMutation } from "@tanstack/react-query";
import apiInputService from "services/apiInputService";

type CreateUserBySupplierInvitePayload = {
  password: string;
  confirmPassword: string;
  firstName: string;
  lastName: string;
  token: string;
};

const createUserBySupplierInvite = async (
  createUserBySupplierInvitePayload: CreateUserBySupplierInvitePayload
) => {
  return await apiInputService.put(
    `/users/acceptSupplierInvite?realm=${process.env.REACT_APP_API_AUTH_REALM}`,
    createUserBySupplierInvitePayload
  );
};

export const useCreateUserBySupplierInvite = (options?: any) => {
  return useMutation(createUserBySupplierInvite, {
    ...options,
  });
};
