import React from "react";
import styled from "@emotion/styled";

interface SidebarHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  rtl?: boolean;
  logo: string;
  logoText: string;
}

const StyledSidebarHeader = styled.div`
  height: 64px;
  min-height: 64px;
  display: flex;
  align-items: center;
  padding: 0 20px;

  > div {
    width: 100%;
    overflow: hidden;
  }
`;

export const SidebarHeader: React.FC<SidebarHeaderProps> = ({
  logo,
  logoText,
  ...rest
}) => {
  return (
    <StyledSidebarHeader {...rest}>
      <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
        <img
          src={logo}
          alt="logo"
          style={{ width: "2.18rem", verticalAlign: "middle", border: "0" }}
        />
        <img
          src={logoText}
          alt="logo text"
          style={{
            display: "block",
            width: "5.36rem",
            verticalAlign: "middle",
            border: "0",
            padding: "5px 0px",
          }}
        />
      </div>
    </StyledSidebarHeader>
  );
};
