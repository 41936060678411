import { useQuery } from "@tanstack/react-query";
import apiInputService from "services/apiInputService";
import { WorkerRelatedQuery } from "./types";

const fetchWorkOrdersRelated = async (workerRelatedQuery: WorkerRelatedQuery) => {
  const { workerID } = workerRelatedQuery;

  const workersRelated = await apiInputService.get(`/WorkOrders/relatedWorkers/${workerID}`);
  const { data } = workersRelated;

  return data;
};

export const useWorkOrdersRelatedQuery = (
  workerRelatedQuery: WorkerRelatedQuery,
  options?: any
) => {
  const { workerID } = workerRelatedQuery;

  return useQuery<any[]>(
    ["workOrders-related", workerID],
    () => fetchWorkOrdersRelated(workerRelatedQuery),
    {
      ...options,
    }
  );
};
