import { Box, Typography, styled } from "@mui/material";

export const ContainerFooter = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

export const TypographyTitleStyle = styled(Typography)`
  font-size: 13;
  font-weight: bold;
  padding-bottom: 10px;
`;

export const SocialMediaContent = styled(Box)`
  display: flex;
  gap: 10px;
`;

export const TypographyDescriptionStyle = styled(Box)`
  font-size: 12;
  font-weight: 400;
  margin-bottom: 5px;
  color: inherit;
  font-weight: 400;
  letter-spacing: 3px;
`;

export const Container = styled(Box)`
  padding: 0 20px;
`;

export const Content = styled(Box)`
  border-top: 1px solid #e9e9e9;
  padding: 5px;
  width: 95%;
  margin: 0 auto;
  align-items: center;
  position: inherit;
`;

export const DataContent = styled(Box)`
  width: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  color: #76767f;
`;
