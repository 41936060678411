import {
  Box,
  Grid,
  InputAdornment,
  Icon,
  makeStyles,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { Email } from "@material-ui/icons";
import CustomInput from "components/CustomInput/CustomInput";
import React from "react";
import uverifyLogo from "assets/img/uverify-logo.jpg";
import { useTranslation } from "react-i18next";
import * as z from "zod";
import { useForm } from "react-hook-form";
import { useAuth } from "hooks/useAuth";
import AddAlert from "@material-ui/icons/AddAlert";
import Snackbars from "components/Snackbar/Snackbar.js";
import { Link } from "@mui/material";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import { tryRedirectToSSO } from "helpers/KeycloakLoginStrategy";
import { zodResolver } from "@hookform/resolvers/zod";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles(styles);
const apiUrl = `https://uverify-keycloak-service-dot-uverify-input-core.uc.r.appspot.com/auth/realms/${process.env.REACT_APP_API_AUTH_REALM}/login-actions/reset-credentials?client_id=uverify-dev&tab_id=5t-dvOHsHnM`;

const loginSchema = z.object({
  username: z.string().min(3),
  password: z.string().min(6),
});

const LoginForm = () => {
  const classes = useStyles();

  const { t } = useTranslation("login-page");
  const { signIn } = useAuth();
  const navigate = useNavigate();
  const { getUserInfo } = useAuth();

  const [currentStep, setCurrentStep] = React.useState(0);
  const { handleSubmit, trigger, register, watch } = useForm({
    resolver: zodResolver(loginSchema),
  });
  const [loading, setLoading] = React.useState(false);
  const [tl, setTl] = React.useState(false);

  const trySubmitUserToSSO = async () => {
    setLoading(true);
    const isUsernameValid = await trigger("username");

    if (isUsernameValid) {
      !tryRedirectToSSO(watch("username")) ? setCurrentStep(1) : null;
    }

    setLoading(false);
  };

  const onSubmit = async (data) => {
    setLoading(true);
    await signIn(data.username, data.password).catch((err) => {
      setLoading(false);
      setTl(true);
    });
  };

  const buttonConfig = [
    {
      text: t("next"),
      type: "button",
      onClick: trySubmitUserToSSO,
    },
    {
      text: t("login"),
      type: "submit",
      onClick: undefined,
    },
  ];

  return (
    <Box width={isMobile ? "290px" : "400px"}>
      <Snackbars
        place='tr'
        color='warning'
        icon={AddAlert}
        message={t("login-error")}
        open={tl}
        closeNotification={() => setTl(false)}
        close
      />
      <Box textAlign='center' marginBottom='40px'>
        <img src={uverifyLogo} alt='U-verify Logo' />
      </Box>
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box width={isMobile ? "290px" : "400px"}>
            {currentStep === 0 && (
              <CustomInput
                labelText={t("username")}
                id='username'
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  ...register("username", { required: true }),
                  endAdornment: (
                    <InputAdornment position='end'>
                      <Email className={classes.inputAdornmentIcon} />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          </Box>
          <Box width={isMobile ? "290px" : "400px"}>
            {currentStep === 1 && (
              <>
                <CustomInput
                  labelText={t("password")}
                  id='password'
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    ...register("password", { required: true }),
                    endAdornment: (
                      <InputAdornment position='end'>
                        <Icon className={classes.inputAdornmentIcon}>lock_outline</Icon>
                      </InputAdornment>
                    ),
                    type: "password",
                    autoComplete: "off",
                  }}
                />
                <Box
                  sx={{
                    typography: "body1",
                    "& > :not(style) ~ :not(style)": {
                      ml: 2,
                    },
                  }}
                  display='flex'
                  justifyContent='end'
                >
                  <Link
                    style={{
                      textDecoration: "none",
                    }}
                    href={apiUrl}
                  >
                    {t("forgot-my-password")}
                  </Link>
                </Box>
              </>
            )}
          </Box>
          <Box mt={1} mb={1}>
            <Button
              variant='outlined'
              {...buttonConfig[currentStep]}
              fullWidth
              disabled={loading}
              style={{
                width: isMobile ? "290px" : "400px",
                padding: "10px",
                backgroundColor: "#FF6700",
                borderColor: "transparent",
                color: "#FFFFFF",
                marginTop: "10px",
              }}
            >
              {loading ? <CircularProgress size={20} /> : buttonConfig[currentStep].text}
            </Button>
            {currentStep === 1 && (
              <Button
                fullWidth
                variant='outlined'
                color='primary'
                onClick={() => setCurrentStep(0)}
                disabled={loading}
                style={{
                  width: isMobile ? "290px" : "400px",
                  padding: "10px",
                  marginTop: "10px",
                }}
              >
                Back
              </Button>
            )}
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default LoginForm;
