import { useKeycloak } from "@react-keycloak/web";
import {
  createContext,
  ReactNode,
  useEffect,
  useContext,
  useMemo,
  useState,
  Dispatch,
  SetStateAction,
} from "react";

import { IconsBySubscription } from "helpers/SubscriptionIconHelper";
import { useSubscription } from "./useSubscription";
import { useAuth } from "./useAuth";

interface ISidebarContext {
  collapsed: boolean;
  toggled: boolean;
  broken: boolean;
  rtl: boolean;
  hasImage: boolean;
  theme: Theme;
  setCollapsed: Dispatch<SetStateAction<boolean>>;
  setToggled: Dispatch<SetStateAction<boolean>>;
  setBroken: Dispatch<SetStateAction<boolean>>;
  setRtl: Dispatch<SetStateAction<boolean>>;
  setHasImage: Dispatch<SetStateAction<boolean>>;
  setTheme: Dispatch<SetStateAction<Theme>>;
  routes: any;
  username: any;
  subscriptionName: any;
  avatar: any;
  setRoutes: Dispatch<SetStateAction<any>>;
  setUsername: Dispatch<SetStateAction<any>>;
}

type Theme = "light" | "dark";

interface ISidebarProviderProps {
  children: ReactNode;
}

const SidebarContext = createContext<ISidebarContext>({} as ISidebarContext);

export function SidebarProvider({ children }: ISidebarProviderProps) {
  const { subscription, subscriptionsAvailable } = useSubscription();
  const { hasResourceRole } = useAuth();

  const roles = subscriptionsAvailable;

  const { keycloak } = useKeycloak();

  const [username, setUsername] = useState("");
  const [subscriptionName, setSubscriptionName] = useState("");
  const [avatar, setAvatar] = useState(null);

  const [routes, setRoutes] = useState([]);

  const [collapsed, setCollapsed] = useState(false);
  const [toggled, setToggled] = useState(false);
  const [broken, setBroken] = useState(false);
  const [rtl, setRtl] = useState(false);
  const [hasImage, setHasImage] = useState(true);
  const [theme, setTheme] = useState<Theme>("dark");

  useEffect(() => {
    setUsername(keycloak?.tokenParsed?.name);
  }, []);

  useEffect(() => {
    routes.forEach((route: { feature: any; invisible: boolean }) => {
      if (route.feature) {
        route.invisible = !hasResourceRole([route.feature, "uverify"]);
      }
    });
  }, [routes]);

  useEffect(() => {
    if (subscriptionsAvailable?.length > 0 && subscription) {
      const roleAvailable = roles.find((role) => role.subscriptionId == subscription);

      setSubscriptionName(roleAvailable.name);
      setAvatar(IconsBySubscription[roleAvailable.mini]);
    }
  }, [subscriptionsAvailable?.length, subscription]);

  const value = useMemo(
    () => ({
      collapsed,
      toggled,
      broken,
      rtl,
      hasImage,
      theme,
      setCollapsed,
      setToggled,
      setBroken,
      setRtl,
      setHasImage,
      setTheme,
      routes,
      avatar,
      username,
      subscriptionName,
      setRoutes,
      setUsername,
    }),
    [
      collapsed,
      toggled,
      broken,
      rtl,
      hasImage,
      theme,
      setCollapsed,
      setToggled,
      setBroken,
      setRtl,
      setHasImage,
      setTheme,
      routes,
      avatar,
      username,
      subscriptionName,
      setRoutes,
      setUsername,
    ]
  );

  return <SidebarContext.Provider value={value}>{children}</SidebarContext.Provider>;
}

export function useSideBar(): ISidebarContext {
  const context = useContext(SidebarContext);

  if (!context) {
    throw new Error("useSideBar must be used within an SidebarProvider");
  }

  return context;
}
