import React from 'react'
import * as Tabs from '@radix-ui/react-tabs';
import { TabTrigger } from '../TabTrigger';

export interface Tab {
    title: string;
    hasCounter?: number | null;
    id: string;
}

type TabSystemProps = Tabs.TabsProps & {
    loopable?: boolean;
    tabs?: Tab[];
}

export default function TabSystem({ tabs, loopable = true, defaultValue = tabs[0]?.id , ...rest }: TabSystemProps) {
  return (
    <>
        <Tabs.Root {...rest} defaultValue={defaultValue}>
            <Tabs.List loop={loopable}>
                {tabs.map(({ id, title, hasCounter = null }, index) => (
                    <TabTrigger value={id} hasCounter={hasCounter} key={index}>{title}</TabTrigger>
                ))}
            </Tabs.List>

            {!!tabs && rest.children}
        </Tabs.Root>
        {}
    </>
  )
}
