import { BrowserRouter, Route, Routes } from "react-router-dom";
import ReactDOM from "react-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "assets/scss/material-dashboard-pro-react.scss?v=1.10.0";

import "./i18n";
import IndexComponent from "IndexComponent";

import PhysicalCard from "views/Pages/PhysicalCard/PhysicalCard";

ReactDOM.render(
  <BrowserRouter>
    <IndexComponent />
    <Routes>
      <Route path='/physical-card' element={<PhysicalCard />} />
    </Routes>
  </BrowserRouter>,
  document.getElementById("root")
);
