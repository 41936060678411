export const masksByCountryCode:{ [key: string]: string } = {
  "93": "+93 99 999 9999",
  "355": "+355 99 999 9999",
  "376": "+376 99 999 9999",
  "244": "+244 99 999 9999",
  "54": "+54 9 99 9999-9999",
  "374": "+374 99 999 9999",
  "297": "+297 999 9999",
  "61": "+61 99 9999 9999",
  "43": "+43 9999 999999",
  "994": "+994 99 999 9999",
  "973": "+973 3999 9999",
  "880": "+880 1999-999999",
  "375": "+375 99 999 99 99",
  "32": "+32 999 99 99 99",
  "501": "+501 999-9999",
  "229": "+229 99 99 99 99",
  "975": "+975 17 99 9999",
  "591": "+591 69999999",
  "387": "+387 99 999 999",
  "267": "+267 99 999 999",
  "55": "+55 (99) 99999-9999",
  "673": "+673 999 9999",
  "359": "+359 99 999 999",
  "226": "+226 99 99 99 99",
  "257": "+257 99 99 99 99",
  "855": "+855 99 999 999",
  "237": "+237 9999 9999",
  "238": "+238 (9) 999 999",
  "236": "+236 99 99 99 99",
  "235": "+235 99 99 99 99",
  "56": "+56 99 9999 9999",
  "86": "+86 999 9999 9999",
  "57": "+57 999 9999999",
  "269": "+269 999 99 99",
  "242": "+242 9999 999 999",
  "243": "+243 999 999 999",
  "682": "+682 99 999",
  "506": "+506 9999 9999",
  "385": "+385 99 999 9999",
  "53": "+53 9 9999999",
  "357": "+357 99 999 999",
  "420": "+420 999 999 999",
  "45": "+45 99 99 99 99",
  "253": "+253 99 99 99 99",
  "670": "+670 999 9999",
  "593": "+593 999 999999",
  "20": "+20 99 999 9999",
  "503": "+503 9999 9999",
  "240": "+240 999 999 999",
  "291": "+291 7 999 999",
  "372": "+372 9999 9999",
  "251": "+251 99 999 9999",
  "500": "+500 99999",
  "298": "+298 999999",
  "679": "+679 999 9999",
  "358": "+358 999 9999999",
  "33": "+33 9 99 99 99 99",
  "594": "+594 9999 99 99 99",
  "689": "+689 99 99 99",
  "241": "+241 999 99 99 99",
  "220": "+220 999 9999",
  "995": "+995 99 999 9999",
  "49": "+49 9999 999999",
  "233": "+233 99 999 9999",
  "350": "+350 99999999",
  "30": "+30 999 999 9999",
  "299": "+299 99 99 99",
  "590": "+590 9999 99 99 99",
  "502": "+502 9999 9999",
  "224": "+224 99 99 99 99",
  "245": "+245 999 9999",
  "592": "+592 999 9999",
  "509": "+509 9999 9999",
  "504": "+504 9999 9999",
  "36": "+36 99 999 9999",
  "354": "+354 999 9999",
  "91": "+91 99999 99999",
  "62": "+62 99 999999999",
  "98": "+98 9999 999 9999",
  "964": "+964 999 999 9999",
  "353": "+353 99 999 9999",
  "972": "+972 99-999-9999",
  "39": "+39 999 999 9999",
  "225": "+225 99 999 999",
  "81": "+81 99-9999-9999",
  "962": "+962 99 999 9999",
  "7": "+7 999 999-99-99",
  "254": "+254 999 999999",
  "686": "+686 999 9999",
  "965": "+965 9999 9999",
  "996": "+996 999 999 9999",
  "856": "+856 20 99 999 999",
  "371": "+371 99 999 999",
  "961": "+961 99 999 999",
  "266": "+266 9999 9999",
  "231": "+231 999 999 999",
  "218": "+218 999 999 999",
  "423": "+423 999 999 999",
  "370": "+370 999 99999",
  "352": "+352 999 999 999",
  "853": "+853 9999 9999",
  "389": "+389 99 999 999",
  "261": "+261 99 99 999 99",
  "265": "+265 99 999 9999",
  "60": "+60 99-999 9999",
  "960": "+960 999 9999",
  "223": "+223 99 99 99 99",
  "356": "+356 9999 9999",
  "692": "+692 999 9999",
  "222": "+222 99 99 99 99",
  "230": "+230 9999 9999",
  "262": "+262 999 99 99 99",
  "52": "+52 99 9999 9999",
  "691": "+691 999 9999",
  "373": "+373 9999 9999",
  "377": "+377 9999 9999",
  "976": "+976 9999 9999",
  "382": "+382 99 999 999",
  "212": "+212 9999-999999",
  "258": "+258 99 999 9999",
  "95": "+95 99 999 9999",
  "264": "+264 999 999 999",
  "674": "+674 999 9999",
  "977": "+977 999 9999999",
  "31": "+31 99 9999999",
  "687": "+687 99.99.99",
  "64": "+64 99-999 9999",
  "505": "+505 9999 9999",
  "227": "+227 99 99 99 99",
  "234": "+234 999 999 9999",
  "683": "+683 9999",
  "672": "+672 9999 9999",
  "850": "+850 191 999 9999",
  "47": "+47 999 99 999",
  "968": "+968 9999 9999",
  "92": "+92 99 99999999",
  "680": "+680 999 9999",
  "970": "+970 9999 999 999",
  "507": "+507 9999-9999",
  "675": "+675 9999 9999",
  "595": "+595 999 999999",
  "51": "+51 999 999 999",
  "63": "+63 99 999 9999",
  "48": "+48 99 999 9999",
  "351": "+351 999 999 999",
  "974": "+974 9999 9999",
  "40": "+40 99 999 9999",
  "250": "+250 9999 999 999",
  "290": "+290 9999",
  "508": "+508 9999 99 99 99",
  "685": "+685 99 99999",
  "378": "+378 9999 999999",
  "239": "+239 999 9999",
  "966": "+966 99 999 9999",
  "221": "+221 99 99 99 99",
  "381": "+381 99 999 9999",
  "248": "+248 999 99 99",
  "232": "+232 999 99999",
  "65": "+65 9999 9999",
  "421": "+421 999 999 999",
  "386": "+386 99 999 999",
  "677": "+677 9999 999",
  "252": "+252 99 999999",
  "27": "+27 99 999 9999",
  "82": "+82 99-9999-9999",
  "211": "+211 99 999 9999",
  "34": "+34 999 99 99 99",
  "94": "+94 99 999 9999",
  "249": "+249 999 999 9999",
  "597": "+597 999-9999",
  "268": "+268 9999 9999",
  "46": "+46 999-999 9999",
  "41": "+41 99 999 99 99",
  "963": "+963 9999 999 999",
  "886": "+886 9999 999 999",
  "992": "+992 999 999 999",
  "255": "+255 99 999 9999",
  "66": "+66 99 999 9999",
  // "670": "+670 999 9999",
  "228": "+228 99 99 99 99",
  "690": "+690 999 9999",
  "676": "+676 999 9999",
  "216": "+216 99 999 999",
  "90": "+90 9999 999 999",
  "993": "+993 99 999999",
  "688": "+688 999 9999",
  "971": "+971 9999 999999",
  "44": "+44 99999 999999",
  "1": "+1 (999) 999-9999",
  "598": "+598 999 9999",
  "998": "+998 99 999 9999",
  "678": "+678 999 9999",
  "379": "+379 999 999999",
  "58": "+58 999 9999999",
  "84": "+84 99 999 9999",
  "681": "+681 99.99.99",
  "967": "+967 999 999 999",
  "260": "+260 99 9999999",
  "263": "+263 99 9999999",
};

export const countriesInfo = [
  { code: "93", name: "Afghanistan" },
  { code: "355", name: "Albania" },
  { code: "376", name: "Andorra" },
  { code: "244", name: "Angola" },
  { code: "54", name: "Argentina" },
  { code: "374", name: "Armenia" },
  { code: "297", name: "Aruba" },
  { code: "61", name: "Australia" },
  { code: "43", name: "Austria" },
  { code: "994", name: "Azerbaijan" },
  { code: "973", name: "Bahrain" },
  { code: "880", name: "Bangladesh" },
  { code: "375", name: "Belarus" },
  { code: "32", name: "Belgium" },
  { code: "501", name: "Belize" },
  { code: "229", name: "Benin" },
  { code: "975", name: "Bhutan" },
  { code: "591", name: "Bolivia" },
  { code: "387", name: "Bosnia and Herzegovina" },
  { code: "267", name: "Botswana" },
  { code: "55", name: "Brazil" },
  { code: "673", name: "Brunei" },
  { code: "359", name: "Bulgaria" },
  { code: "226", name: "Burkina Faso" },
  { code: "257", name: "Burundi" },
  { code: "855", name: "Cambodia" },
  { code: "237", name: "Cameroon" },
  { code: "238", name: "Cape Verde" },
  { code: "236", name: "Central African Republic" },
  { code: "235", name: "Chad" },
  { code: "56", name: "Chile" },
  { code: "86", name: "China" },
  { code: "57", name: "Colombia" },
  { code: "269", name: "Comoros" },
  { code: "242", name: "Congo" },
  { code: "243", name: "Congo (DR)" },
  { code: "682", name: "Cook Islands" },
  { code: "506", name: "Costa Rica" },
  { code: "385", name: "Croatia" },
  { code: "53", name: "Cuba" },
  { code: "357", name: "Cyprus" },
  { code: "420", name: "Czech Republic" },
  { code: "45", name: "Denmark" },
  { code: "253", name: "Djibouti" },
  { code: "670", name: "East Timor" },
  { code: "593", name: "Ecuador" },
  { code: "20", name: "Egypt" },
  { code: "503", name: "El Salvador" },
  { code: "240", name: "Equatorial Guinea" },
  { code: "291", name: "Eritrea" },
  { code: "372", name: "Estonia" },
  { code: "251", name: "Ethiopia" },
  { code: "500", name: "Falkland Islands" },
  { code: "298", name: "Faroe Islands" },
  { code: "679", name: "Fiji" },
  { code: "358", name: "Finland" },
  { code: "33", name: "France" },
  { code: "594", name: "French Guiana" },
  { code: "689", name: "French Polynesia" },
  { code: "241", name: "Gabon" },
  { code: "220", name: "Gambia" },
  { code: "995", name: "Georgia" },
  { code: "49", name: "Germany" },
  { code: "233", name: "Ghana" },
  { code: "350", name: "Gibraltar" },
  { code: "30", name: "Greece" },
  { code: "299", name: "Greenland" },
  { code: "590", name: "Guadeloupe" },
  { code: "502", name: "Guatemala" },
  { code: "224", name: "Guinea" },
  { code: "245", name: "Guinea-Bissau" },
  { code: "592", name: "Guyana" },
  { code: "509", name: "Haiti" },
  { code: "504", name: "Honduras" },
  { code: "36", name: "Hungary" },
  { code: "354", name: "Iceland" },
  { code: "91", name: "India" },
  { code: "62", name: "Indonesia" },
  { code: "98", name: "Iran" },
  { code: "964", name: "Iraq" },
  { code: "353", name: "Ireland" },
  { code: "972", name: "Israel" },
  { code: "39", name: "Italy" },
  { code: "225", name: "Ivory Coast" },
  { code: "81", name: "Japan" },
  { code: "962", name: "Jordan" },
  { code: "7", name: "Kazakhstan" },
  { code: "254", name: "Kenya" },
  { code: "686", name: "Kiribati" },
  { code: "965", name: "Kuwait" },
  { code: "996", name: "Kyrgyzstan" },
  { code: "856", name: "Laos" },
  { code: "371", name: "Latvia" },
  { code: "961", name: "Lebanon" },
  { code: "266", name: "Lesotho" },
  { code: "231", name: "Liberia" },
  { code: "218", name: "Libya" },
  { code: "423", name: "Liechtenstein" },
  { code: "370", name: "Lithuania" },
  { code: "352", name: "Luxembourg" },
  { code: "853", name: "Macau" },
  { code: "389", name: "Macedonia" },
  { code: "261", name: "Madagascar" },
  { code: "265", name: "Malawi" },
  { code: "60", name: "Malaysia" },
  { code: "960", name: "Maldives" },
  { code: "223", name: "Mali" },
  { code: "356", name: "Malta" },
  { code: "692", name: "Marshall Islands" },
  { code: "222", name: "Mauritania" },
  { code: "230", name: "Mauritius" },
  { code: "262", name: "Mayotte" },
  { code: "52", name: "Mexico" },
  { code: "691", name: "Micronesia" },
  { code: "373", name: "Moldova" },
  { code: "377", name: "Monaco" },
  { code: "976", name: "Mongolia" },
  { code: "382", name: "Montenegro" },
  { code: "212", name: "Morocco" },
  { code: "258", name: "Mozambique" },
  { code: "95", name: "Myanmar" },
  { code: "264", name: "Namibia" },
  { code: "674", name: "Nauru" },
  { code: "977", name: "Nepal" },
  { code: "31", name: "Netherlands" },
  { code: "687", name: "New Caledonia" },
  { code: "64", name: "New Zealand" },
  { code: "505", name: "Nicaragua" },
  { code: "227", name: "Niger" },
  { code: "234", name: "Nigeria" },
  { code: "683", name: "Niue" },
  { code: "672", name: "Norfolk Island" },
  { code: "850", name: "North Korea" },
  { code: "47", name: "Norway" },
  { code: "968", name: "Oman" },
  { code: "92", name: "Pakistan" },
  { code: "680", name: "Palau" },
  { code: "970", name: "Palestine" },
  { code: "507", name: "Panama" },
  { code: "675", name: "Papua New Guinea" },
  { code: "595", name: "Paraguay" },
  { code: "51", name: "Peru" },
  { code: "63", name: "Philippines" },
  { code: "48", name: "Poland" },
  { code: "351", name: "Portugal" },
  { code: "974", name: "Qatar" },
  { code: "40", name: "Romania" },
  { code: "7", name: "Russia" },
  { code: "250", name: "Rwanda" },
  { code: "590", name: "Saint Barthélemy" },
  { code: "290", name: "Saint Helena" },
  { code: "508", name: "Saint Pierre and Miquelon" },
  { code: "685", name: "Samoa" },
  { code: "378", name: "San Marino" },
  { code: "239", name: "São Tomé and Príncipe" },
  { code: "966", name: "Saudi Arabia" },
  { code: "221", name: "Senegal" },
  { code: "381", name: "Serbia" },
  { code: "248", name: "Seychelles" },
  { code: "232", name: "Sierra Leone" },
  { code: "65", name: "Singapore" },
  { code: "421", name: "Slovakia" },
  { code: "386", name: "Slovenia" },
  { code: "677", name: "Solomon Islands" },
  { code: "252", name: "Somalia" },
  { code: "27", name: "South Africa" },
  { code: "82", name: "South Korea" },
  { code: "211", name: "South Sudan" },
  { code: "34", name: "Spain" },
  { code: "94", name: "Sri Lanka" },
  { code: "249", name: "Sudan" },
  { code: "597", name: "Suriname" },
  { code: "268", name: "Swaziland" },
  { code: "46", name: "Sweden" },
  { code: "41", name: "Switzerland" },
  { code: "963", name: "Syria" },
  { code: "886", name: "Taiwan" },
  { code: "992", name: "Tajikistan" },
  { code: "255", name: "Tanzania" },
  { code: "66", name: "Thailand" },
  { code: "228", name: "Togo" },
  { code: "690", name: "Tokelau" },
  { code: "676", name: "Tonga" },
  { code: "216", name: "Tunisia" },
  { code: "90", name: "Turkey" },
  { code: "993", name: "Turkmenistan" },
  { code: "688", name: "Tuvalu" },
  { code: "256", name: "Uganda" },
  { code: "380", name: "Ukraine" },
  { code: "971", name: "United Arab Emirates" },
  { code: "44", name: "United Kingdom" },
  { code: "1", name: "United States" },
  { code: "598", name: "Uruguay" },
  { code: "998", name: "Uzbekistan" },
  { code: "678", name: "Vanuatu" },
  { code: "379", name: "Vatican City" },
  { code: "58", name: "Venezuela" },
  { code: "84", name: "Vietnam" },
  { code: "681", name: "Wallis and Futuna" },
  { code: "967", name: "Yemen" },
  { code: "260", name: "Zambia" },
  { code: "263", name: "Zimbabwe" },
];

export function unMaskPhone(phoneNumber: string) {
  if (!phoneNumber) return;
  return phoneNumber.replace(/\D/g, "");
}

export function checkMaskLength(value1: any, value2: any) {
  const countryCode = value2;
  const mask = masksByCountryCode[countryCode];
  const maxLength = mask?.replace(/\D/g, "")?.length;
  return value1?.length === maxLength;
}
