/* eslint-disable prefer-spread */
import React from "react";
import {
  Column,
  Table,
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getSortedRowModel,
  FilterFn,
  flexRender,
  PaginationState,
  SortingState,
  ColumnDef,
} from "@tanstack/react-table";
import classnames from "classnames";

import { RankingInfo, rankItem } from "@tanstack/match-sorter-utils";
import { useTranslation } from "react-i18next";

import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import { Button, FormControl, MenuItem, Select } from "@mui/material";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import { getBadgeFromStatus } from "utils/functions";

type TableState = {
  pageIndex: number;
  pageSize: number;
  columnFilters: ColumnFiltersState;
  sorting: SortingState;
};

const newStyles: any = {
  ...styles,
  formControlMargins: {
    margin: "3px 0 !important",
  },
  gridContainer: {
    justifyContent: "center",
  },
};

const useStyles = makeStyles(newStyles);

declare module "@tanstack/table-core" {
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }
  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value);

  // Store the itemRank info
  addMeta({
    itemRank,
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

interface EntityTableProps {
  columns: ColumnDef<any, any>[];
  setTableState: React.Dispatch<React.SetStateAction<TableState>>;
  tableState: TableState;
  data: any;
  pageCount: number;
}

const EntityTable = ({ columns, data, tableState, setTableState, pageCount }: EntityTableProps) => {
  const classes = useStyles();
  const { t } = useTranslation("common");

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    tableState.columnFilters
  );
  const [globalFilter, setGlobalFilter] = React.useState("");

  const [sorting, setSorting] = React.useState<SortingState>(tableState.sorting);

  const [{ pageIndex, pageSize }, setPagination] = React.useState<PaginationState>({
    pageIndex: tableState.pageIndex,
    pageSize: tableState.pageSize,
  });

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      sorting,
      columnFilters,
      globalFilter,
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    debugTable: true,
    debugHeaders: true,
    debugColumns: false,
    manualPagination: true,
    enableMultiSort: true,
  });

  React.useEffect(() => {
    if (pageIndex > pageCount) table.setPageIndex(0);
    setTableState({
      pageIndex: pageIndex > pageCount ? 0 : pageIndex,
      pageSize: pageSize,
      columnFilters: columnFilters,
      sorting: sorting,
    });
  }, [pageIndex, pageSize, sorting, columnFilters]);

  return (
    <div className='ReactTable -striped -highlight'>
      <div className='h-2' />
      <table className='rt-table'>
        <thead className='rt-thead -header'>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} className='rt-tr'>
              {headerGroup.headers.map((header) => {
                return (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className={classnames("rt-th rt-resizable-header")}
                    style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                  >
                    {header.isPlaceholder ? null : (
                      <div
                        style={{
                          height: "120px",
                        }}
                      >
                        <div
                          onClick={header.column.getToggleSortingHandler()}
                          style={{
                            cursor: header.column.getCanSort() ? "pointer" : "default",
                          }}
                          className='rt-resizable-header-content'
                        >
                          {flexRender(header.column.columnDef.header, header.getContext())}
                          {{
                            asc: <KeyboardArrowUp />,
                            desc: <KeyboardArrowDown />,
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>
                        {header.column.getCanFilter() ? (
                          <div>
                            <Filter column={header.column} table={table} />
                          </div>
                        ) : null}
                      </div>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody className='rt-tbody'>
          {table.getCoreRowModel().rows.map((row, i) => {
            return (
              <tr
                key={row.id}
                className={classnames("rt-tr", { " -odd": i % 2 === 0 }, { " -even": i % 2 === 1 })}
              >
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td key={cell.id} className='rt-td'>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className='pagination-top' style={{ padding: 20 }}>
        <div className='-pagination'>
          <div className='-previous'>
            <Button
              onClick={() => table.previousPage()}
              disabled={pageIndex === 0}
              className='-btn'
            >
              {t("react-previous")}
            </Button>
          </div>

          <div className='-center'>
            <GridContainer className={classes.gridContainer}>
              <GridItem>
                {!!pageCount && (
                  <FormControl
                    fullWidth
                    className={classes.selectFormControl + " " + classes.formControlMargins}
                  >
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      style={{ width: "100%" }}
                      variant='standard'
                      value={pageIndex}
                      onChange={(e) => {
                        table.setPageIndex(Number(e.target.value));
                      }}
                      label={t("react-page")}
                      inputProps={{
                        name: "pageSelect",
                        id: "page-select",
                      }}
                    >
                      {Array?.from(Array(pageCount)?.keys())?.map((key: any) => {
                        return (
                          <MenuItem
                            key={key}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value={key}
                          >
                            {t("react-page")} {key + 1}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                )}
              </GridItem>
              <GridItem>
                <FormControl
                  fullWidth
                  className={classes.selectFormControl + " " + classes.formControlMargins}
                >
                  <Select
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                    }}
                    variant='standard'
                    value={pageSize}
                    onChange={(e) => {
                      table.setPageSize(Number(e.target.value));
                    }}
                    label={t("react-row")}
                    inputProps={{
                      name: "numberOfRows",
                      id: "number-of-rows",
                    }}
                  >
                    {[10, 20, 30, 40, 50]?.map((pageSize) => (
                      <MenuItem
                        key={pageSize}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value={pageSize}
                      >
                        {pageSize} {t("react-row")}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>
          </div>

          <div className='-next'>
            <Button
              className='-btn'
              onClick={() => table.nextPage()}
              disabled={tableState.pageIndex + 1 === pageCount}
            >
              {t("react-next")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

function Filter({ column, table }: { column: Column<any, unknown>; table: Table<any> }) {
  const [clientConfig, setClientConfig] = React.useState<any>();

  React.useEffect(() => {
    setClientConfig(JSON.parse(localStorage.getItem("clientConfig")));
  }, []);

  const firstValue = table.getPreFilteredRowModel().flatRows[0]?.getValue(column.id);

  const columnFilterValue = column.getFilterValue();

  if (column?.columnDef.header === "WO Status") {
    return (
      <FormControl fullWidth>
        <Select
          style={{
            padding: "28px 0px 0px 20px",
            display: "flex",
            flexDirection: "column",
            opacity: columnFilterValue ? 1 : 0.5,
          }}
          variant='standard'
          value={(columnFilterValue ?? "") as string}
          onChange={(e) => column.setFilterValue(e.target.value || undefined)}
        >
          <MenuItem value='Pending'>{getBadgeFromStatus("Pending")}</MenuItem>
          <MenuItem value='Withdrawn'>{getBadgeFromStatus("Withdrawn")}</MenuItem>
          <MenuItem value='Rejected'>{getBadgeFromStatus("Rejected")}</MenuItem>
          <MenuItem value='Confirmed'>{getBadgeFromStatus("Confirmed")}</MenuItem>
          <MenuItem value='Closed'>{getBadgeFromStatus("Closed")}</MenuItem>
          <MenuItem value=''>Clear</MenuItem>
        </Select>
      </FormControl>
    );
  } else if (column?.columnDef.header === "Worker Status") {
    return (
      <FormControl fullWidth>
        <Select
          style={{
            padding: "28px 0px 0px 20px",
            display: "flex",
            flexDirection: "column",
            opacity: columnFilterValue ? 1 : 0.5,
          }}
          variant='standard'
          value={(columnFilterValue ?? "") as string}
          onChange={(e) => column.setFilterValue(e.target.value || undefined)}
        >
          <MenuItem value='Pending'>{getBadgeFromStatus("Pending")}</MenuItem>
          <MenuItem value='Active'>{getBadgeFromStatus("Active")}</MenuItem>
          <MenuItem value='Closed'>{getBadgeFromStatus("Closed")}</MenuItem>
          <MenuItem value=''>Clear</MenuItem>
        </Select>
      </FormControl>
    );
  } else if (column?.id === "sensitiveDataStatus") {
    return (
      <FormControl fullWidth>
        <Select
          style={{
            padding: "28px 0px 0px 20px",
            display: "flex",
            flexDirection: "column",
            opacity: columnFilterValue ? 1 : 0.5,
          }}
          variant='standard'
          value={(columnFilterValue ?? "") as string}
          onChange={(e) => column.setFilterValue(e.target.value || undefined)}
        >
          <MenuItem value='Completed'>{getBadgeFromStatus("Completed")}</MenuItem>
          <MenuItem value='Pending HR Fields'>{getBadgeFromStatus("Pending HR Fields")}</MenuItem>
          <MenuItem value='Pending OREP'>{getBadgeFromStatus("Pending OREP")}</MenuItem>
          <MenuItem value='Pending Data'>{getBadgeFromStatus("Pending Data")}</MenuItem>
          <MenuItem value=''>Clear</MenuItem>
        </Select>
      </FormControl>
    );
  } else if (column?.columnDef.header === "Status") {
    return (
      <FormControl fullWidth>
        <Select
          style={{
            padding: "28px 0px 0px 20px",
            display: "flex",
            flexDirection: "column",
            opacity: columnFilterValue ? 1 : 0.5,
          }}
          variant='standard'
          value={(columnFilterValue ?? "") as string}
          onChange={(e) => column.setFilterValue(e.target.value || undefined)}
        >
          {clientConfig?.sowStatusConfig?.allAvailableValues?.map((status: any) => (
            <MenuItem key={status} value={status}>
              {getBadgeFromStatus(status)}
            </MenuItem>
          ))}
          <MenuItem value=''>Clear</MenuItem>
        </Select>
      </FormControl>
    );
  }

  return typeof firstValue === "number" ? (
    <div>
      <div className='flex space-x-2'>
        <DebouncedInput
          type='number'
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? "")}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? "")}
          value={(columnFilterValue as [number, number])?.[0] ?? ""}
          onChange={(value) => column.setFilterValue((old: [number, number]) => [value, old?.[1]])}
          placeholder={`Min ${
            column.getFacetedMinMaxValues()?.[0] ? `(${column.getFacetedMinMaxValues()?.[0]})` : ""
          }`}
          className='w-24 border shadow rounded'
        />
        <DebouncedInput
          type='number'
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? "")}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? "")}
          value={(columnFilterValue as [number, number])?.[1] ?? ""}
          onChange={(value) => column.setFilterValue((old: [number, number]) => [old?.[0], value])}
          placeholder={`Max ${
            column.getFacetedMinMaxValues()?.[1] ? `(${column.getFacetedMinMaxValues()?.[1]})` : ""
          }`}
          className='w-24 border shadow rounded'
        />
      </div>
      <div className='h-1' />
    </div>
  ) : (
    <>
      <DebouncedInput
        type='text'
        value={(columnFilterValue ?? "") as string}
        onChange={(value) => column.setFilterValue(value)}
        placeholder='Search'
        className='w-36 border shadow rounded'
      />
    </>
  );
}

function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 1000,
  ...props
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange">) {
  const [value, setValue] = React.useState(initialValue);
  const { t } = useTranslation("common");

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <CustomInput
      {...props}
      inputProps={{
        value: value,
        onChange: (e: any) => setValue(e.target.value),
        placeholder: `${t("react-search")}`,
      }}
    />
  );
}

export default EntityTable;
