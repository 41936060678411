import { Link } from "@material-ui/core";
import { Box, styled } from "@mui/material";

export const Title = styled(Box)`
  margin-top: 10px;
  margin-bottom: 3px;
  color: #999;
`;

export const Value = styled(Box)`
  margin-top: 10px;
  margin-bottom: 3px;
  color: #000;
`;

export const LinkStyle = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #999;
  font-size: 12px;
  line-height: 22px;
  cursor: pointer;
`;
