import { useMutation } from "@tanstack/react-query";
import apiInputService from "services/apiInputService";

const fetchReportToPosition = async (email: string) => {
  const { data } = await apiInputService.get(`/Worker/reportToPosition/${email}`);
  
  return data;
};

export const useReportToPosition = (options?: any) => {  
  const response = useMutation(fetchReportToPosition, {
    ...options,
  });
  
  return response;
};
