import React, { ReactNode, useEffect, useState } from "react";
import { useSideBar } from "hooks/useSidebar";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { SidebarHeader } from "./components/SidebarHeader";
import { Typography } from "./components/Typography";
import { SidebarFooter } from "./components/SidebarFooter";
import {
  GroupsMenu,
  SidebarMain,
  StyledLogo,
  WrapperSidebar,
  hexToRgba,
  menuItemStyles,
  themes,
} from "./styles/stylesSidebar";
import { CircularProgress, Icon, Tooltip } from "@material-ui/core";

import { NavLink, useNavigate } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import { useTranslation } from "react-i18next";

// import BuildIcon from "@material-ui/icons/Build";
import PortraitIcon from "@material-ui/icons/Portrait";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import CachedIcon from "@material-ui/icons/Cached";
import TranslateIcon from "@material-ui/icons/Translate";

import { useLocation } from "react-router-dom";
import { useSubscription } from "hooks/useSubscription";
import { useAuth } from "hooks/useAuth";
import Modal from "components/Modal/Modal";
import i18next from "i18next";

interface ISidebarProps {
  propsRoutes: any;
  image: any;
  logo: any;
  logoText: any;
  logoStratesys: any;
  logoStratesysMini: any;
  children: ReactNode;
}

const SidebarPro = ({
  children,
  propsRoutes,
  image,
  logo,
  logoText,
  logoStratesys,
  logoStratesysMini,
}: ISidebarProps) => {
  const { t } = useTranslation("sidebar");

  const location = useLocation();
  const navigate = useNavigate();

  const [openConfirmModal, setOpenConfirmModal] = useState(null);

  const { logout } = useAuth();

  const changeLanguage = (lng: "en" | "es" | "it") => {
    i18next.changeLanguage(lng);
  };

  const { setSubscription, subscriptionsAvailable } = useSubscription();

  const {
    collapsed,
    toggled,
    rtl,
    hasImage,
    theme,
    setToggled,
    setBroken,
    setRtl,
    avatar,
    routes,
    username,
    subscriptionName,
    setRoutes,
    setUsername,
  } = useSideBar();

  const { keycloak } = useKeycloak();

  useEffect(() => {
    setUsername(keycloak.tokenParsed.name);
  }, []);

  useEffect(() => {
    if (username) return;

    setUsername(keycloak.tokenParsed.name);
  }, [username]);

  useEffect(() => {
    propsRoutes && setRoutes(propsRoutes);
  }, [propsRoutes]);

  const handleOpenConfirmModal = () => {
    setOpenConfirmModal(<Modal onConfirm={logout} onDenied={hideConfirmModal} />);
  };

  const hideConfirmModal = () => {
    setOpenConfirmModal(null);
  };

  return (
    <WrapperSidebar rtl={rtl} backgroundScroll={themes[theme].sidebar.backgroundColor}>
      {openConfirmModal}
      <Sidebar
        className='sidebar'
        collapsed={collapsed}
        toggled={toggled}
        onBackdropClick={() => setToggled(false)}
        onBreakPoint={(broken) => {
          setBroken(broken);
          setRtl(broken);
        }}
        image={hasImage ? image : null}
        width={rtl ? "300px" : "270px"}
        customBreakPoint='959px'
        rtl={rtl}
        backgroundColor={
          hasImage ? hexToRgba(themes[theme].sidebar.backgroundColor, 0.85) : "transparent"
        }
        rootStyles={{
          background: !hasImage ? `linear-gradient(195deg, #323a54, #1a2035) !important` : "",
          color: themes[theme].sidebar.color,
          zIndex: "1200 !important",
        }}
      >
        <GroupsMenu collapsed={collapsed}>
          <SidebarHeader
            logo={logo}
            logoText={logoText}
            rtl={rtl}
            style={{ marginBottom: "1.5rem", marginTop: "1rem" }}
          />

          <SidebarMain>
            <>
              <div className='groupTitle'>
                <Typography
                  variant='body2'
                  fontWeight={600}
                  style={{
                    opacity: collapsed ? 0 : 0.7,
                    letterSpacing: "0.5px",
                  }}
                >
                  {t("sections")}
                </Typography>
              </div>

              <Menu menuItemStyles={menuItemStyles(theme, collapsed, hasImage)}>
                {routes.map((route: any, index: number) => {
                  if (!route.invisible) {
                    if (route.redirect) {
                      return null;
                    }

                    return (
                      <Tooltip
                        title={collapsed ? t(route.name) : ""}
                        key={index + Date.now()}
                        placement='right'
                      >
                        <MenuItem
                          icon={
                            route.icon !== undefined ? (
                              typeof route.icon === "string" ? (
                                <Icon className={"itemIcon"}>{route.icon}</Icon>
                              ) : (
                                <route.icon className={"itemIcon"} />
                              )
                            ) : (
                              <StyledLogo>{rtl ? t(route.rtlMini) : t(route.mini)}</StyledLogo>
                            )
                          }
                          component={
                            <NavLink
                              to={route.layout + route.path}
                              onClick={() => toggled && setToggled(() => false)}
                            />
                          }
                        >
                          {t(route.name)}
                        </MenuItem>
                      </Tooltip>
                    );
                  }
                })}
              </Menu>
            </>

            <>
              <div className='groupTitle'>
                <Typography
                  variant='body2'
                  fontWeight={600}
                  style={{
                    opacity: collapsed ? 0 : 0.7,
                    letterSpacing: "0.5px",
                  }}
                >
                  {t("profile")}
                </Typography>
              </div>

              <Menu closeOnClick menuItemStyles={menuItemStyles(theme, collapsed, hasImage)}>
                <SubMenu
                  icon={
                    <div className={"photo"}>
                      <img src={avatar} className={"avatarImg"} alt='avatar-photo' />
                    </div>
                  }
                  label={
                    !username ? (
                      <CircularProgress size='16px' />
                    ) : (
                      <span
                        style={{
                          textTransform: "capitalize",
                          marginLeft: "0.5rem",
                        }}
                      >
                        {username}
                      </span>
                    )
                  }
                >
                  <MenuItem
                    icon={<PortraitIcon className={"itemIcon"} />}
                    component={
                      <NavLink
                        to={"/app/profile"}
                        onClick={() => toggled && setToggled(() => false)}
                      />
                    }
                  >
                    {t("profile")}
                  </MenuItem>
                  <SubMenu
                    icon={<TranslateIcon className={"itemIcon"} />}
                    label={
                      <div
                        onClick={() => {
                          toggled && setToggled(() => false);
                        }}
                      >
                        {t("language")}
                      </div>
                    }
                  >
                    <MenuItem
                      component={
                        <div
                          onClick={() => {
                            toggled && setToggled(() => false);
                            changeLanguage("en");
                          }}
                        />
                      }
                    >
                      English
                    </MenuItem>
                    <MenuItem
                      component={
                        <div
                          onClick={() => {
                            toggled && setToggled(() => false);
                            changeLanguage("es");
                          }}
                        />
                      }
                    >
                      Español
                    </MenuItem>
                    <MenuItem
                      component={
                        <div
                          onClick={() => {
                            toggled && setToggled(() => false);
                            changeLanguage("it");
                          }}
                        />
                      }
                    >
                      Italiano
                    </MenuItem>
                  </SubMenu>
                  <MenuItem
                    icon={<ExitToAppIcon className={"itemIcon"} />}
                    component={
                      <div
                        onClick={() => {
                          toggled && setToggled(() => false);
                          handleOpenConfirmModal();
                        }}
                      />
                    }
                  >
                    {t("logout")}
                  </MenuItem>
                </SubMenu>

                {subscriptionsAvailable.length > 1 && (
                  <SubMenu label={subscriptionName} icon={<CachedIcon className={"itemIcon"} />}>
                    {subscriptionsAvailable.map((role, index) => (
                      <MenuItem
                        key={index + Date.now()}
                        component={
                          <div
                            onClick={() => {
                              setSubscription(role.subscriptionId);
                              navigate(location.search || location.pathname);
                            }}
                          />
                        }
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: rtl ? "flex-end" : "flex-start",
                            flexDirection: rtl ? "row-reverse" : "row",
                            gap: "1rem",
                          }}
                        >
                          <span>{role.mini}</span>
                          <span>{role?.name}</span>
                        </div>
                      </MenuItem>
                    ))}
                  </SubMenu>
                )}
              </Menu>
            </>
          </SidebarMain>
          <SidebarFooter
            collapsed={collapsed}
            stratesys={logoStratesys}
            stratesysMini={logoStratesysMini}
          />
        </GroupsMenu>
      </Sidebar>

      {children}
    </WrapperSidebar>
  );
};

export default SidebarPro;
