import React, { useState } from "react";
import { CircularProgress } from "@material-ui/core";
import { Button } from "@mui/material";
import { DisplayNameProps } from "services/input-core/clientConfig/type";
import { useDecisionTreeWizardStyles } from "./decisionTreeWizardStyles";

type DecisionTreeDataType = {
  label: DisplayNameProps | null; // Resposta da pergunta anterior
  value?: string | null; // Valor da resposta final
  question: DisplayNameProps | null; // Próxima questão
  children: Array<DecisionTreeDataType> | null; // Filhos do mesmo objeto seguindo a estrutura
};

type DecisionTreeWizardProps = {
  activeModal: boolean;
  handleActiveModal: React.Dispatch<React.SetStateAction<boolean>>;
  decisionTreeData: DecisionTreeDataType;
  handleSubmit: (children: DecisionTreeDataType) => void;
  title: string;
};

const stepsButtonStyle = {
  backgroundColor: "#08558D",
  color: "white",
  width: "140px",
};

export default function DecisionTreeWizard({
  activeModal,
  handleActiveModal,
  decisionTreeData,
  handleSubmit,
  title,
}: DecisionTreeWizardProps) {
  const decisionTreeWizardStyles = useDecisionTreeWizardStyles();

  const initialFormState = {
    question: decisionTreeData.question,
    children: decisionTreeData.children,
  };
  const [atualStep, setAtualState] = useState(initialFormState);

  const resetState = () => {
    setAtualState(initialFormState);
  };

  const nextStep = (children: DecisionTreeDataType) => {
    if (children.question) {
      setAtualState(children);
    } else {
      handleSubmit(children);
      resetState();
    }
  };

  return (
    activeModal && (
      <>
        <div
          onClick={() => {
            handleActiveModal(false);
            resetState();
          }}
          className={decisionTreeWizardStyles.background}
        />
        {decisionTreeData ? (
          <div className={decisionTreeWizardStyles.modal}>
            <div className={decisionTreeWizardStyles.modalheader}>
              <span style={{ fontSize: "20px" }}>Decision Tree</span>
              <br />
              <br />
              <span style={{ fontSize: "20px", fontWeight: "bold" }}>{title}</span>
            </div>
            <div className={decisionTreeWizardStyles.modalbody}>
              <div>
                <span
                  style={{
                    color: "#08558D",
                    fontSize: "15px",
                  }}
                >
                  {atualStep.question.default}
                </span>
              </div>
              <div className={decisionTreeWizardStyles.buttonGrid}>
                {atualStep.children.map((answare) => (
                  <>
                    <Button
                      variant='contained'
                      style={{
                        ...stepsButtonStyle,
                        marginRight: "10px",
                      }}
                      onClick={() => nextStep(answare)}
                    >
                      {answare.label.default}
                    </Button>
                  </>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div
            style={{
              position: "fixed",
              height: "auto",
              top: "50%",
              left: "50%",
            }}
          >
            <CircularProgress />
          </div>
        )}
      </>
    )
  );
}
