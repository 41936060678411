import { Typography } from "@material-ui/core";
import { Box } from "@mui/material";
import ButtonTS from "components/CustomButtons/ButtonTS";
import styled from "styled-components";

export const StyledNoImageAvailable = styled(Box)`
  display: flex;
  padding: 40px;
  align-items: center;
  justify-content: center;
`;

export const StyledContainer = styled(Box)`
  display: flex;
  justify-content: center;
`;

export const StyledBox = styled(Box)`
  display: block;
  justify-content: center;
`;

export const StyledTypography = styled(Typography)`
  text-align: center;
  color: #c8c8c8;
  font-size: 14px;
`;

export const StyledButtonTS = styled(ButtonTS)`
  margin: 1.25rem auto;
`;
