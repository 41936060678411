import { useEffect, useState } from "react";
import { Box, TextField, Checkbox } from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";
import { Button, CircularProgress } from "@material-ui/core";
import { ActivityItemsProps, IFormActivityItemsInput } from "./interface";
import { useCreateActivityUploadFile } from "services/input-core/work-items/upload-activity-item";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useQueryClient,
} from "@tanstack/react-query";
import { ActivityItemsWorkerWithLocation } from "services/input-core/work-items/@types";
import FileUpload from "components/FileUpload";

type FileData = {
  activityItem: ActivityItemsProps;
  profile: "worker" | "sow";
  refID?: string;
  showNotification?: (color: string, message: string) => void;
  setModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  refetchList?: <TPageData>(
    options?: RefetchOptions & RefetchQueryFilters<TPageData>
  ) => Promise<QueryObserverResult<ActivityItemsWorkerWithLocation, unknown>>;
};

const stepsButtonStyle = {
  backgroundColor: "#2f87ee",
  color: "white",
  width: "140px",
};

export default function ActivityItemForm({
  activityItem,
  setModalOpen,
  profile,
  showNotification,
  refetchList,
  refID,
}: FileData) {
  const { mutate, isLoading } = useCreateActivityUploadFile();
  const queryClient = useQueryClient();

  const [fileUpload, setFileUpload] = useState([]);

  const fileSize = (fileUpload[0]?.size / (1024 * 1024)).toFixed(2);

  useEffect(() => {
    if (+fileSize > 30) setFileUpload([]);
  }, [fileUpload]);

  const initialValuesForm = {
    documentName: activityItem.activityItemAction,
    documentUpload: "",
    hasExpirationDate: false,
    expirationDate: "",
  };

  const {
    register,
    handleSubmit,
    watch,
  } = useForm({
    defaultValues: initialValuesForm,
  });

  const handleActivityItemsFormChange: SubmitHandler<IFormActivityItemsInput> = (
    e: IFormActivityItemsInput
  ) => {
    if (!e.hasExpirationDate && e.expirationDate !== "") e.expirationDate = "";

    const formData = new FormData();

    formData.append("files", fileUpload[0]);
    formData.append("fieldglassId", activityItem.fieldglassId);
    formData.append("name", fileUpload[0].name);
    formData.append("dueDate", e.expirationDate);
    formData.append("subscriptionId", activityItem.subscription);
    formData.append("profile", profile);
    formData.append("activityItemCode", activityItem.activityItemCode);
    formData.append("zIdentifier", activityItem.zIdentifier);

    mutate(formData, {
      onSuccess: () => {
        showNotification("success", "Activity item file uploaded successfully!");
        queryClient.invalidateQueries(["activity-items-status", refID]);
        refetchList();
        setModalOpen(false);
      },
      onError: (error) => {
        showNotification("danger", "Activity item file upload failed!");
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(handleActivityItemsFormChange)}>
      <Box
        sx={{
          marginBottom: "20px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <TextField
          name='documentName'
          id='standard-basic'
          disabled={isLoading}
          variant='standard'
          label='Document Name'
          style={{ width: "89%", marginBottom: "10px" }}
          required
          {...register("documentName")}
        />
      </Box>

      <Box sx={{ marginBottom: "20px" }}>
        <FileUpload
          onFileUpload={setFileUpload}
          label={"Click and select your document ..."}
          isLoading={isLoading}
        />
      </Box>
      <Box>
        <Checkbox
          disabled={isLoading}
          name='hasExpirationDate'
          color='primary'
          {...register("hasExpirationDate")}
        />
        Has expiration date?
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        {watch("hasExpirationDate") === true && (
          <TextField
            id='standard-basic'
            name='expirationDate'
            variant='standard'
            disabled={isLoading}
            type='date'
            required={watch("hasExpirationDate") === true ? true : false}
            InputLabelProps={{ shrink: true }}
            style={{ width: "400px", marginBottom: "10px" }}
            {...register("expirationDate")}
          />
        )}
      </Box>
      <Box sx={{ display: "flex", justifyContent: "end" }}>
        <Button
          disabled={(fileUpload?.length ? false : true) || isLoading || +fileSize > 30}
          variant='contained'
          style={stepsButtonStyle}
          type='submit'
        >
          {isLoading ? <CircularProgress size={20} color='inherit' /> : "SAVE"}
        </Button>
      </Box>
    </form>
  );
}
