import styled from "styled-components";

export const CardContainer = styled.div`
  perspective: 1000px;
`;

export const CardInner = styled.div<{ isFlipped: boolean }>`
  width: 400px;
  height: 250px;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.6s;
  transform: ${(props) => (props.isFlipped ? "rotateY(180deg)" : "rotateY(0deg)")};
`;

export const CardFront = styled.div`
  position: absolute;
  backface-visibility: hidden;
  display: flex;
  right: "40px";
  align-items: center;
  justify-content: center;
  margin-left: 45px;
`;

export const CardBack = styled.div`
  position: absolute;
  backface-visibility: hidden;
  background-color: #fff;
  display: flex;
  align-items: flex-start;
  margin-left: 16px;
  justify-content: start;
  transform: rotateY(180deg);
`;

export const FlipButton = styled.button`
  padding: 10px 20px;
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 16px;
`;

export const ButtonContainer = styled.div`
  @media print {
    .hide-on-print {
      display: none;
    }
  }
`;
