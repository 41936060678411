import { useQuery } from "@tanstack/react-query";
import apiInputService from "services/apiInputService";

const fetchSowProcessTable = async (sowID: string) => {
  const { data } = await apiInputService.get(`/workItem/profile/SOW/requestParam/${sowID}`);
  return data;
};

export const useSowProcessTableQuery = (sowID: string, options?: any) => {
  return useQuery<any>(["workOrder-process-table", sowID], () => fetchSowProcessTable(sowID), {
    ...options,
  });
};
