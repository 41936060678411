import { Grid, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import styles from "assets/jss/material-dashboard-pro-react/views/errorPageStyles.js";

const useStyles = makeStyles(styles as never);

export default function NoAccessPermission() {
  const classes = useStyles();
  const { t } = useTranslation("common");

  return (
    <div className={classes.contentCenter}>
      <Grid container>
        <Grid item md={12}>
          <h2 className={classes.subTitle}>{t("access-denied")}</h2>
        </Grid>
      </Grid>
    </div>
  );
}
