import { useKeycloak } from "@react-keycloak/web";
import ButtonTS from "components/CustomButtons/ButtonTS";
import { useTranslation } from "react-i18next";

export default function Unauthorized() {
  const { keycloak } = useKeycloak();
  const { t } = useTranslation("common");

  return (
    <div
      style={{
        width: "100%",
        height: "80vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h2>
        <strong>{t("access-denied")}</strong>
      </h2>
      <ButtonTS
        color='danger'
        onClick={() => keycloak.logout({ redirectUri: `${window.location.origin}/auth/login` })}
      >
        {t("finish-session")}
      </ButtonTS>
    </div>
  );
}
