import { useQuery } from "@tanstack/react-query";
import apiInputService from "services/apiInputService";

const fetchSuppliers = async (pagination: any) => {
  const suppliers = await apiInputService.get("/supplier/paginated/", {
    params: { ...pagination },
  });

  const data = suppliers.data._metadata;

  return data;
};

export const useSuppliersQuery = (subscription: string, pagination?: string, options?: any) => {
  return useQuery<any>(["suppliers", subscription, pagination], () => fetchSuppliers(pagination), {
    ...options,
  });
};
