import { useNavigate } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "assets/scss/material-dashboard-pro-react.scss?v=1.10.0";
import { SubscriptionProvider } from "hooks/useSubscription";

import App from "App";
import keycloak from "keycloak";
import { ReactKeycloakProvider } from "@react-keycloak/web";

import "./i18n";
import { TabProvider } from "hooks/useTab";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { AuthProvider } from "hooks/useAuth";
import { CircularProgress } from "@material-ui/core";
import { KeycloakInitOptions } from "keycloak-js";
import { parseCookies } from "nookies";
import { SidebarProvider } from "hooks/useSidebar";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ClientConfigProvider } from "hooks/useClientConfig";

const redirectEndpoints = ["/sow/sow", "/Worker/workerId/"];

const Loading: React.FC = () => {
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <h1 style={{ marginBottom: 50 }}>Uverify</h1>
      <CircularProgress style={{ marginBottom: 20 }} />
    </div>
  );
};

export default function IndexComponent() {
  const navigate = useNavigate();

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: Infinity,
        cacheTime: Infinity,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        retry: 1,
        onError: ({ response }) => {
          if (response?.status === 403 && redirectEndpoints.includes(response?.data?.url))
            navigate("/app/no-access-permission");
          if (response?.status === 404 && redirectEndpoints?.includes(response?.data?.url))
            navigate("/app/not-found");
          if (response?.status === 503 && response.data.error === "IN_MAINTENANCE")
            navigate("/app/under-maintenance");
        },
      },
    },
  });

  const cookies = parseCookies();

  const initOptions: KeycloakInitOptions = {
    token: localStorage.getItem("token") || cookies["token"],
    refreshToken: localStorage.getItem("refreshToken") || cookies["refreshToken"],
    checkLoginIframe: false,
  };

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      LoadingComponent={<Loading />}
      autoRefreshToken
      initOptions={initOptions}
    >
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <SubscriptionProvider>
            <ClientConfigProvider>
              <TabProvider>
                <SidebarProvider>
                  <App />
                </SidebarProvider>
                <ToastContainer
                  position='top-right'
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  theme='light'
                />
              </TabProvider>
            </ClientConfigProvider>
          </SubscriptionProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </AuthProvider>
      </QueryClientProvider>
    </ReactKeycloakProvider>
  );
}
