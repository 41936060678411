import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { ExpandMoreRounded } from "@material-ui/icons";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

interface FieldGlassInfoProps {
  title: string;
  fieldGlassData: any;
  remapper: (fieldGlassData: any) => any;
}

export default function FieldglassInfo({ title, fieldGlassData, remapper }: FieldGlassInfoProps) {
  return (
    <Accordion style={{ padding: "8px", margin: "16px 0px" }}>
      <AccordionSummary
        expandIcon={<ExpandMoreRounded />}
        aria-controls='panel1a-content'
        id='panel1a-header'
      >
        <Typography>{title}</Typography>
      </AccordionSummary>

      <AccordionDetails>
        <GridContainer>
          <GridItem {...{ xs: 12 }}>
            {!!fieldGlassData &&
              Object.keys(fieldGlassData).length &&
              Object.entries(remapper(fieldGlassData)).map((entry) => (
                <Tooltip
                  {...{
                    title: entry[1] || "-",
                    followCursor: true,
                    placement: "bottom-start",
                  }}
                >
                  <TextField
                    inputProps={{ readOnly: true }}
                    fullWidth={true}
                    value={entry[1] || "-"}
                    disabled={true}
                    label={entry[0]}
                    id={entry[0]}
                    style={{ marginBottom: "8px" }}
                  />
                </Tooltip>
              ))}
          </GridItem>
        </GridContainer>
      </AccordionDetails>
    </Accordion>
  );
}
