import { useState, useEffect } from "react";
import { useForm, SubmitHandler, FieldValues, Controller } from "react-hook-form";

import { AvailableValuesProps, Config, FieldsProps } from "services/input-core/clientConfig/type";
import InputSearcher from "components/InputSearcher";
import AutoCompleteCustom from "components/AutoCompleteCustom";
import { getStrategy, StrategyArgs } from "helpers/formStrategyHelper/strategy-mapper";
import CustomDialog from "components/CustomDialog/CustomDialog";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
  MenuItem,
  TextField,
  Button,
} from "@mui/material";
import FileUpload from "components/FileUpload";

const stepsButtonStyle = {
  backgroundColor: "#2f87ee",
  color: "white",
  width: "140px",
};

type WizardProps = {
  wizardData: Config;
  active: boolean;
  handleActive: React.Dispatch<React.SetStateAction<boolean>>;
  formSubmit: SubmitHandler<FieldValues>;
  title: string;
  caption: string;
  omit?: string[];
  context?: Record<string, unknown>;
};

export default function Wizard({
  wizardData,
  active,
  handleActive,
  formSubmit,
  title,
  caption,
  context,
}: WizardProps) {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    resetField,
    formState: { errors },
    setValue,
    control,
  } = useForm();

  const [atualPage, setAtualPage] = useState<number>(0);
  
  const resetForm = () => {
    handleActive(false);
  };

  useEffect(() => {
    return () => {
      reset();
      setAtualPage(0);
    };
  }, [active]);

  function applyStrategy<T>(strategyKey: string, args: any): T {
    const strategy: StrategyArgs = getStrategy(strategyKey);

    return strategy.handle(args) as T;
  }

  function getFromContext(fieldName: string): unknown {
    return context ? context[fieldName] ?? null : null;
  }

  const getFieldForStrategy: any = {
    context: getFromContext,
    form: watch,
  };

  useEffect(() => {
    resetField("liveAreaOfResidence");
    resetField("liveRegion");
    resetField("liveCountry");
  }, [watch("liveNextToOperation")]);

  useEffect(() => {
    if (watch("documentType") === "62cef0b20c2aa161589583a2") resetField("documentUnexpired");
  }, [watch("documentType")]);

  const handleButton = () => {
    if (atualPage === wizardData.steps.length - 1) {
      const data = watch();
      formSubmit(data);
      handleActive(false);
      resetForm();
    } else {
      setAtualPage(atualPage + 1);
    }
  };

  const renderFields = (field: FieldsProps) => {
    if (field.name === "contractorCategory") {
      return (
        <TextField
          id='outlined-select-currency'
          select
          label={field.displayName.default}
          style={{ width: "100%" }}
          value={localStorage.getItem(
            "contractorCategory" + new URLSearchParams(window.location.search).get("id")
          )}
          required={field.required}
          inputProps={{ readOnly: true }}
          {...register(field.name, { required: field.required })}
        >
          {field.availableValues
            .filter((option: AvailableValuesProps) =>
              !option.showWhen
                ? option
                : watch(option.showWhen[0].key) === option.showWhen[0].value[0]
            )
            .map((option: AvailableValuesProps) => (
              <MenuItem key={option.value} value={option.value}>
                {option.displayName.default}
              </MenuItem>
            ))}
        </TextField>
      );
    }

    switch (field.fieldType) {
      case "string":
        return (
          <TextField
            id='standard-basic'
            variant='standard'
            label={field.displayName.default}
            value={watch(field.name)}
            required={field.required}
            style={{ width: "100%" }}
            disabled={field?.allowWhenIsFilled && !watch(field?.allowWhenIsFilled)}
            {...register(field.name, { required: field.required })}
          />
        );

      case "select":
        return (
          <Controller
            render={({ field: { onChange } }) => (
              <AutoCompleteCustom
                label={field.displayName.default}
                disablePortal
                value={
                  field?.availableValues?.find((a) => a?.value === watch(field?.name))?.displayName
                    ?.default ?? ""
                }
                id='outlined-select-currency'
                disabled={field?.allowWhenIsFilled && !watch(field?.allowWhenIsFilled)}
                onChange={(e: unknown, data: { id: unknown }) => {
                  if (typeof data !== "string") onChange(data?.id ?? undefined);
                }}
                options={field?.availableValues?.map((option: AvailableValuesProps) => ({
                  id: option.value,
                  label: option.displayName.default,
                }))}
                sx={{
                  width: "100% ",
                }}
              />
            )}
            rules={{
              required: field.required,
            }}
            name={field.name}
            control={control}
          />
        );

      case "boolean":
        return (
          <Controller
            render={({ field: { onChange } }) => (
              <AutoCompleteCustom
                label={field.displayName.default}
                disablePortal
                value={
                  field?.availableValues?.find((a) => a?.value === watch(field?.name))?.displayName
                    ?.default ?? ""
                }
                disabled={field?.allowWhenIsFilled && !watch(field?.allowWhenIsFilled)}
                id='outlined-select-currency'
                onChange={(e: unknown, data: { id: unknown }) => {
                  if (typeof data !== "string") onChange(data?.id ?? undefined);
                }}
                options={[
                  {
                    id: "true",
                    label: "Yes",
                  },
                  {
                    id: "false",
                    label: "No",
                  },
                ]}
              />
            )}
            rules={{
              required: field.required,
            }}
            name={field.name}
            control={control}
          />
        );

      case "date":
        return (
          <TextField
            id='standard-basic'
            label={field.displayName.default}
            variant='standard'
            value={watch(field.name)}
            type='date'
            required={
              field?.validateWhen && field?.validateWhen.length > 0
                ? applyStrategy<boolean>("validateWhen", [
                    ...field?.validateWhen?.map((arg: any) => ({
                      currentValue: watch(arg.key),
                      valueToMeet: arg.value[0],
                    })),
                  ])
                : field?.required
            }
            InputLabelProps={{ shrink: true }}
            {...register(field.name, {
              required: field?.strategyArgsMapper
                ? applyStrategy<boolean>(field.strategyKey, [
                    ...field.strategyArgsMapper.map((arg) => ({
                      currentValue: watch(arg.fieldName),
                      valueToMeet: arg.value,
                    })),
                  ])
                  ? field?.validateWhen && field?.validateWhen.length > 0
                    ? applyStrategy<boolean>("validateWhen", [
                        ...field?.validateWhen?.map((arg: any) => ({
                          currentValue: watch(arg.key),
                          valueToMeet: arg.value[0],
                        })),
                      ])
                    : field?.required
                  : field?.required
                : field?.validateWhen && field?.validateWhen.length > 0
                ? applyStrategy<boolean>("validateWhen", [
                    ...field?.validateWhen?.map((arg: any) => ({
                      currentValue: watch(arg.key),
                      valueToMeet: arg.value[0],
                    })),
                  ])
                : field?.required,
            })}
            style={{ width: "100%" }}
            disabled={
              (field?.strategyArgsMapper &&
                applyStrategy<boolean>(field.strategyKey, [
                  ...field.strategyArgsMapper.map((arg) => ({
                    currentValue: watch(arg.fieldName),
                    valueToMeet: arg.value,
                  })),
                ])) ||
              (field?.allowWhenIsFilled && !watch(field?.allowWhenIsFilled))
            }
          />
        );

      case "readonly":
        return (
          <TextField
            id='standard-basic'
            label={field.displayName.default}
            variant='standard'
            type='button'
            inputProps={{ readOnly: true }}
            {...register(field.name)}
            style={{ width: "100%" }}
          />
        );

      case "searcher":
        return (
          <InputSearcher
            fieldData={field}
            disabled={field?.allowWhenIsFilled && !watch(field?.allowWhenIsFilled)}
            hookFormWatch={watch}
            hookFormControl={control}
          />
        );

      case "checkbox":
        return (
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={watch(field.name)}
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: 30,
                    },
                  }}
                  {...register(field.name, {
                    onChange: (event: any) => {
                      if (event.target.checked)
                        applyStrategy<void>(field.strategyKey, [
                          ...field.strategyArgsMapper.map((arg) => arg.fieldName),
                          resetField,
                        ]);
                      setValue(field.name, event.target.checked);
                    },
                  })}
                />
              }
              label={
                <Typography
                  style={{
                    fontSize: "1rem",
                    color: "rgba(0, 0, 0, 0.67)",
                  }}
                >
                  {field.displayName.default}
                </Typography>
              }
              disabled={field?.allowWhenIsFilled && !watch(field?.allowWhenIsFilled)}
              labelPlacement='top'
            />
          </FormGroup>
        );

      case "file":
        return (
          <FileUpload
            fieldData={field}
            hookFormSetValue={setValue}
            label={"Click and select your document ..."}
            type='wizard'
          />
        );

      case "readonlyWithStrategy":
        return (
          <TextField
            id='standard-basic'
            label={field.displayName.default}
            variant='standard'
            value={applyStrategy<string>(
              field.strategyKey,
              field.strategyArgsMapper.map((arg) => getFieldForStrategy[arg.type](arg.fieldName))
            )}
            type='button'
            inputProps={{ readOnly: true }}
            disabled
            style={{ width: "100%" }}
            {...register(field.name)}
          />
        );

      default:
        return <></>;
    }
  };

  return (
    wizardData && (
      <CustomDialog
        handleClose={resetForm}
        open={active}
        dialogTitle={
          <>
            <Typography>{title}</Typography>
            <Typography style={{ fontWeight: 700, fontSize: 18 }}>{caption}</Typography>
            <Typography>
              Step {atualPage + 1}/{wizardData.steps.length}
            </Typography>
          </>
        }
        dialogContent={
          <>
            <Grid container spacing={1} style={{ width: "100%" }}>
              {wizardData?.steps[atualPage]?.fields
                ?.sort((fieldA, fieldB) => fieldA.sequence - fieldB.sequence)
                ?.map((field: FieldsProps) => (
                  <>
                    <Grid
                      key={field.name}
                      item
                      xs={field.gridSize ?? 12}
                      style={{
                        paddingTop: field.fieldType === "checkbox" ? 12 : 24,
                        display: !field?.showWhen
                          ? "flex"
                          : field.showWhen[0].value[0] === watch(field.showWhen[0].key)
                          ? "flex"
                          : "none",
                        flexDirection: "column",
                      }}
                    >
                      {field.showWhen
                        ? field.showWhen[0].value[0] === watch(field.showWhen[0].key) &&
                          renderFields(field)
                        : renderFields(field)}
                      {errors[field.name] && (
                        <div key={field.name} style={{ width: "100%" }}>
                          This field is required
                        </div>
                      )}
                    </Grid>
                  </>
                ))}
            </Grid>
          </>
        }
        dialogAction={
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "end",
              padding: 10,
            }}
          >
            {atualPage > 0 && (
              <Button
                variant='contained'
                style={{
                  ...stepsButtonStyle,
                  marginRight: "10px",
                }}
                onClick={() => setAtualPage(atualPage - 1)}
              >
                Back
              </Button>
            )}
            <Button
              variant='contained'
              style={stepsButtonStyle}
              onClick={() => {
                handleSubmit(handleButton)();
              }}
            >
              {atualPage === wizardData.steps.length - 1 ? "Finish" : "Next"}
            </Button>
          </div>
        }
      />
    )
  );
}
