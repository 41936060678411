import { useQuery } from "@tanstack/react-query";
import apiInputService from "services/apiInputService";

const fetchActivityItemsFromSOW = async (sowID: string) => {
  const { data } = await apiInputService.get(`/Fieldglass/activityItem/sow/${sowID}`);

  return data;
};

export const useActivityItemsFromSOW = (sowID: string, options?: any) => {
  return useQuery<any[]>(["activity-items-sow", sowID], () => fetchActivityItemsFromSOW(sowID), {
    ...options,
  });
};
