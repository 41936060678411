import { useQuery } from "@tanstack/react-query";
import { ProfileType } from "../sensitive-data/type";
import { ClientConfig, Config } from "./type";
import axios from "axios";

export const fetchClientConfig = async (subscription: string): Promise<ClientConfig> => {
  const clientConfig = await axios.get(
    process.env.REACT_APP_CLIENT_CONFIG.replace("/static/", `/static/${subscription}_`)
  );

  const { data } = clientConfig;
  localStorage.setItem("clientConfig", JSON.stringify(data));
  window.dispatchEvent(new Event("storage"));

  return data;
};

export const useClientConfig = (subscription: string, options?: any) => {
  return useQuery<ClientConfig>(["clientConfig"], () => fetchClientConfig(subscription), {
    ...options,
  });
};

export function getConfigFromClientConfig(
  clientConfig: ClientConfig,
  formIdentifier: ProfileType
): Config | null {
  return (
    clientConfig?.sensitiveData?.find((config) => config.formIdentifier === formIdentifier) ?? null
  );
}
