import React from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

interface ZoomableImageProps {
  src: string;
}
const ZoomableImage: React.FC<ZoomableImageProps> = ({ src }) => {
  return (
    <TransformWrapper smooth initialScale={1} initialPositionX={0} initialPositionY={0}>
      <TransformComponent
        wrapperStyle={{
          margin: "0.62rem auto",
          border: "0.05rem solid grey",
          borderRadius: 5,
          width: "100%",
          cursor: "move",
        }}
      >
        <img src={src} style={{ maxHeight: 500 }} />
      </TransformComponent>
    </TransformWrapper>
  );
};
export default ZoomableImage;
