import { Skeleton } from "@mui/material";

interface CustomSkeletonProps {
  numberOfLines: number;
  height?: string | number;
  width?: string | number;
}

function CustomSkeleton({ numberOfLines = 1, height = 25, width }: CustomSkeletonProps) {
  const skeletonList = new Array(numberOfLines).fill("");

  return (
    <>
      {skeletonList.map((_, index) => (
        <Skeleton key={index} height={height} width={width} />
      ))}
    </>
  );
}

export default CustomSkeleton;
