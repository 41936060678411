import { useState, useEffect, useRef } from "react";

import { useNavigate } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// material-ui icons
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItemTS";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";


import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import Business from "@material-ui/icons/Business";

import LoadingBar from "react-top-loading-bar";
import { useSubscription } from "hooks/useSubscription";
import { useTranslation } from "react-i18next";
import { useSuppliersQuery } from "services/input-core/suppliers/get-all-suppliers";
import { ColumnDef } from "@tanstack/react-table";
import ButtonTS from "components/CustomButtons/ButtonTS";
import EntityTable from "components/ReactTable/CustomTable";
import CustomSkeleton from "components/CustomSkeleton/CustomSkeleton";
import { getBadgeFromStatus } from "utils/functions";
import { Link } from "react-router-dom";
const useStyles = makeStyles(styles as any);

const columnsSows: ColumnDef<any, any>[] = [
  {
    header: "Code",
    footer: (props: any) => props.column.id,
    accessorKey: "supplierCode",
    cell: (info: any) => info.getValue(),
  },
  {
    footer: (props: any) => props.column.id,
    header: "Name",
    accessorKey: "supplierName",
    cell: (info: any) => info.getValue(),
  },

  {
    header: "Status",
    footer: (props: any) => props.column.id,
    accessorKey: "status",
    cell: (info: any) => info.getValue(),
    enableColumnFilter: false,
    enableSorting: false,
  },
  {
    header: "Actions",
    footer: (props: any) => props.column.id,
    accessorKey: "actions",
    cell: (info: any) => info.getValue(),
    enableColumnFilter: false,
  },
];

const getFilters = (filters: any) => {
  const object: { [key: string]: any } = {};

  if (filters?.length > 0)
    filters?.forEach((filter: any) => (object[`filter[${filter.id}]`] = filter.value));

  return { ...object };
};

const getSorting = (sorting: any) => {
  const object: { [key: string]: any } = {};

  if (sorting?.length > 0)
    sorting?.forEach((sort: any) => (object[`sort[${sort.id}]`] = sort.desc ? "desc" : "asc"));

  return { ...object };
};

export default function Suppliers() {
  const classes = useStyles();
  const ref = useRef(null);
  const { t } = useTranslation("supplier-list");
  const { subscription } = useSubscription();

  const queryParams = new URLSearchParams(window.location.search);
  const supplierCode = queryParams.get("id") ?? "";

  const [supplierFormatted, setSupplierFormatted] = useState([]);
  const [supplierTable, setSupplierTable] = useState({
    pageIndex: 0,
    pageSize: 10,
    columnFilters: [],
    sorting: [],
  });

  const { data: suppliers, isFetching: isSuppliersLoading } = useSuppliersQuery(subscription, {
    "page[index]": supplierTable.pageIndex,
    "page[size]": supplierTable.pageSize,
    "filter[vendorNumber]": supplierCode ?? "",
    ...getFilters(supplierTable.columnFilters),
    ...getSorting(supplierTable.sorting),
  } as any);

  useEffect(() => {
    if (suppliers?.records?.length > 0) {
      setSupplierFormatted(
        suppliers?.records?.map((supplier: any, key: any) => ({
          id: key,
          supplierCode: supplier.supplierCode,
          supplierName: supplier.supplierName,
          status: getBadgeFromStatus(supplier.status),
          actions: (
            <div className='actions-right'>
              <Link
                to={`/app/suppliers/profile?type=supplier&id=${supplier.supplierCode}`}
                target='_blank'
                rel='noopener noreferrer'
              >
                <ButtonTS
                  round
                  color='info'
                  className={classes.actionButton + " " + classes.actionButtonRound}
                >
                  <ArrowForwardIosIcon className={classes.icon} />
                </ButtonTS>
              </Link>
            </div>
          ),
        }))
      );
    }
  }, [suppliers]);

  return (
    <GridContainer>
      <LoadingBar color='#ff9800' ref={ref} />
      <GridItem xs={12}>
        <Card>
          <CardHeader color='rose' icon>
            <CardIcon color='rose'>
              <Business />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>{t("supplier-table-title")}</h4>
          </CardHeader>
          <CardBody>
            {isSuppliersLoading ? (
              <CustomSkeleton numberOfLines={10} height={50} />
            ) : (
              <EntityTable
                columns={columnsSows}
                setTableState={setSupplierTable}
                tableState={supplierTable}
                pageCount={suppliers?.page_count}
                data={supplierFormatted}
              />
            )}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
