import { useQuery } from "@tanstack/react-query";
import apiInputService from "services/apiInputService";

const fetchWorkOrderProcessTable = async (workOrderID: string) => {
  const { data } = await apiInputService.get(
    `/workItem/profile/WORK_ORDER/requestParam/${workOrderID}`
  );
  return data;
};

export const useWorkOrderProcessTableQuery = (workOrderID: string, options?: any) => {
  return useQuery<any>(
    ["workOrder-process-table", workOrderID],
    () => fetchWorkOrderProcessTable(workOrderID),
    {
      ...options,
    }
  );
};
