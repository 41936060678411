import { useQuery } from "@tanstack/react-query";
import apiInputService from "services/apiInputService";

const fetchWorkerPhysicalCard = async (_id: string) => {
  const { data } = await apiInputService.get(`/worker/badge/physical/${_id}`);

  return data;
};

export const useWorkerPhysicalCardQuery = (_id: string, options?: any) => {
  return useQuery<any>(["worker-physical-card"], () => fetchWorkerPhysicalCard(_id), {
    ...options,
    cacheTime: 0,
  });
};
