import styled from 'styled-components'
import { Wrapper as Button } from './UploadButton.styles'

export const Wrapper = styled.div``

export const ImagePreview = styled.div<{ preview: string }>`
    height: 100%;

    background-image: url(${(p) => p.preview});
    background-position: center;
    background-size: cover;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
`

export const ImagePreviewWrapper = styled.div`
    background-color: #274060;
    height: 200px;

    ${Button} {
        display: none;
    }

    &:hover {
        ${ImagePreview} {
            opacity: 0.7;
        }

        ${Button} {
            display: block;
        }
    }
`
export const FilePreviewWrapper = styled.label`
    background-color: #2F87EE;
    padding: 10px;
    border-radius: 3px;

    color: #fff;
    font-weight: bold;
`

export const Input = styled.input`
    display: none;
`

export const Label = styled.label`
    background-color: #fff;
    color: #08558d;

    border: dashed 3px #08558d;
    textalign: center;

    padding: 10px;

    cursor: pointer;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
`
