import axios from "axios";
import { UverifyClientConfig } from "./client-config";
import { useQuery } from "@tanstack/react-query";



const fetch = async () => {
    
    const { data }  = await axios.get(
    "https://uverifystorage.blob.core.windows.net/client-config/clientConfig.json",
    {
        headers: {
            "Content-Type": "application/json",
        }
    })

    return data as UverifyClientConfig;
}


export const useClientConfig = () => {
    return useQuery<UverifyClientConfig>(["client-config"], fetch, {
        staleTime: Infinity
    });
}