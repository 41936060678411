import React from 'react'

import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';

import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import CardIcon from 'components/Card/CardIcon';
import CustomInput from 'components/CustomInput/CustomInput';
import Skeleton from 'react-loading-skeleton';
import InfoArea from 'components/InfoArea/InfoArea';
import Clearfix from 'components/Clearfix/Clearfix';
import PermIdentity from '@material-ui/icons/PermIdentity';
import Send from '@material-ui/icons/Send';
import Search from '@material-ui/icons/Search';
import Close from '@material-ui/icons/Close';
import CheckOutlined from '@material-ui/icons/CheckOutlined';

import { useTranslation } from 'react-i18next';


export default function ProfileSummary({ classes, data, statusObj, isLoading, entityType }) {
  const { t } = useTranslation('worker-profile');
  return (
    <Card>
      <CardHeader color='rose' icon>
        <CardIcon color='rose'>
          <PermIdentity />
        </CardIcon>
        <h4 className={classes.cardIconTitle}>{entityType === "workOrder" ? "Work Order Profile" : t('profile')}  </h4>
      </CardHeader>
      <CardBody>
        <GridContainer>
          <GridItem xs={12} sm={12} md={5}>
            {data?.firstName !== undefined ? (
              <CustomInput
                id='company-disabled'
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: `${data.firstName} ${data.lastName}`,
                  disabled: true,
                }}
              />
            ) : (
              <Skeleton />
            )}
          </GridItem>
          {entityType === "workOrder" ? 
            <GridItem xs={12} sm={12} md={3}>
            {data?.workOrderID !== undefined ? (
              <CustomInput
                id='username'
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: data.workOrderID,
                  disabled: true,
                }}
              />
            ) : (
              <Skeleton />
            )}
          </GridItem>
          : 
            <GridItem xs={12} sm={12} md={3}>
            {data?.workerID !== undefined ? (
              <CustomInput
                id='username'
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: data.workerID,
                  disabled: true,
                }}
              />
            ) : (
              <Skeleton />
            )}
          </GridItem>
          }
        
          <GridItem xs={12} sm={12} md={4}>
            {isLoading ? <Skeleton /> : data?.workerEmail ? (
              <CustomInput
                id='email-address'
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: data.workerEmail,
                  disabled: true,
                }}
              />
            ) : (
              ""
            )}
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <InfoArea
              title={t('pending-submission')}
              description={`${statusObj?.pendingLength ?? "0"} ${t(
                'wait-submit'
              )}.`}
              icon={Send}
              iconColor='warning'
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <InfoArea
              title={t('analysis')}
              description={`${statusObj?.sentLength ?? "0"} ${t(
                'documents-analysis'
              )}.`}
              icon={Search}
              iconColor='info'
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <InfoArea
              title={t('worker-homologated')}
              description={`${statusObj?.homologatedLength ?? "0"} ${t(
                'approved-documents'
              )}.`}
              icon={CheckOutlined}
              iconColor='success'
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <InfoArea
              title={t('not-homologated')}
              description={`${statusObj?.deniedLength ?? "0"} ${t(
                'unapproved-documents'
              )}.`}
              icon={Close}
              iconColor='danger'
            />
          </GridItem>
        </GridContainer>
        <Clearfix />
      </CardBody>
    </Card>
  )
}