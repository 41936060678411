import { useQuery } from "@tanstack/react-query";
import apiInputService from "services/apiInputService";
import { ActivityItemsWorkerWithLocation } from "./@types";

export const fetchActivityItemTable = async (workerID: string) => {
  const { data } = await apiInputService.get(`/worker/statusActivityItems/${workerID}`);

  return data as ActivityItemsWorkerWithLocation;
};

export const useWorkerActivityItemsWithStatus = (workerID: string, options?: any) => {
  return useQuery<ActivityItemsWorkerWithLocation>(
    ["worker-activity-items-status", workerID],
    () => fetchActivityItemTable(workerID),
    {
      enabled: Boolean(workerID),
      ...options,
    }
  );
};
