import Icon from "@material-ui/core/Icon";
import Launch from "@material-ui/icons/Launch";
import Card from "components/Card/Card.js";
import CardFooter from "components/Card/CardFooter.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import Primary from "components/Typography/Primary";
import { LinkStyle, Title, Value } from "./DataCard.styles";
import Skeleton from "react-loading-skeleton";

type DataCardProps = {
  title: string;
  value: number;
  handleClick?: () => void;
  linkText?: string;
  iconName: string;
  iconColor: string;
  isLoading?: boolean;
};
export default function DataCard({
  title,
  value,
  handleClick,
  linkText,
  iconName,
  iconColor,
  isLoading,
}: DataCardProps) {
  return (
    <Card>
      <CardHeader color={iconColor} stats icon>
        <CardIcon color={iconColor}>
          <Icon>{iconName}</Icon>
        </CardIcon>
        <Title component='p'>{title}</Title>
        <Value component='h3'>{isLoading ? <Skeleton /> : value}</Value>
      </CardHeader>
      <CardFooter stats>
        {linkText && (
          <LinkStyle onClick={handleClick}>
            <Primary>
              <Launch />
            </Primary>
            {linkText}
          </LinkStyle>
        )}
      </CardFooter>
    </Card>
  );
}
