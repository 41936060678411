import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import Hidden from '@material-ui/core/Hidden';
import Popper from '@material-ui/core/Popper';
import Divider from '@material-ui/core/Divider';
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import Person from '@material-ui/icons/Person';
import TranslateIcon from '@material-ui/icons/Translate';
import { Extension } from "@material-ui/icons";

// core components
import Button from 'components/CustomButtons/Button.js';
import Modal from 'components/Modal/Modal.js';
import DownloadUverifyExtensionModal from "./DownloadUverifyExtensionModal";

import styles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js';
import { useNavigate } from 'react-router-dom';
import { Logger } from 'helpers/LogHelper';
import { useTranslation } from 'react-i18next';
import { CircularProgress, Typography } from '@material-ui/core';
import { useIsFetching } from '@tanstack/react-query';
import i18next from 'i18next';
import { useAuth } from 'hooks/useAuth';
import { isFirefox } from 'react-device-detect';
import { useClientConfig } from 'services/input-core/blob/get-client-config';

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  let navigate = useNavigate();
  const { logout } = useAuth();
  const [openConfirmModal, setOpenConfirmModal] = React.useState(null);
  const [openDownloadExtensionModal, setOpenDownloadExtensionModal] = React.useState(false);
  const [notificationCount] = React.useState(0);
  const { data: clientConfig, isLoading: isLoadingClientConfig } = useClientConfig();

  const { t } = useTranslation('navbar-links');
  const isNotificationListEmpty = notificationCount == 0;

  const changeLanguage = (lng) => {
    i18next.changeLanguage(lng);
  }
  const isFetchingPostsWorkOrders = useIsFetching(['sync', 'workOrders']);
  const isFetchingPostsWorker = useIsFetching(['sync', 'worker']);
  const isFetchingPostsSupplier = useIsFetching(['sync', 'supplier']);
  const isFetchingPostsSow = useIsFetching(['sync', 'sow']);

  const isSyncing =
    isFetchingPostsWorkOrders ||
    isFetchingPostsWorker ||
    isFetchingPostsSupplier ||
    isFetchingPostsSow;

  const handleOpenConfirmModal = () => {
    Logger('Abrindo modal de confirmação');
    setOpenConfirmModal(
      <Modal onConfirm={logout} onDenied={hideConfirmModal} />
    );
  };
  
  const [openProfile, setOpenProfile] = React.useState(null);

  const handleClickProfile = (event) => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };

  const handleCloseProfile = () => {
    setOpenProfile(null);
    navigate("/app/profile")
  };

  const [openLanguage, setOpenLanguage] = React.useState(null);
  const handleClickLanguage = (event) => {
    if (openLanguage && openLanguage.contains(event.target)) {
      setOpenLanguage(null);
    } else {
      setOpenLanguage(event.currentTarget);
    }
  };
  const handleCloseLanguage = () => {
    setOpenLanguage(null);
  };
  const handleClickEnglish = () => {
    changeLanguage('en');
  };
  const handleClickSpanish = () => {
    changeLanguage('es');
  };
  const handleClickItalian = () => {
    changeLanguage('it');
  };

  const hideConfirmModal = () => {
    setOpenConfirmModal(null);
  };

  const handleDownloadUverifyExtension = () =>
  setOpenDownloadExtensionModal(true);

  const classes = useStyles();
  const { rtlActive } = props;

  const dropdownItem = classNames(classes.dropdownItem, classes.primaryHover, {
    [classes.dropdownItemRTL]: rtlActive,
  });
  const wrapper = classNames({
    [classes.wrapperRTL]: rtlActive,
  });
  const managerClasses = classNames({
    [classes.managerClasses]: true,
  });
  return (
    <div
      className={wrapper}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {openConfirmModal}
      {!!isSyncing && (
        <>
          <CircularProgress
            className={classes.headerLinksSvg}
            style={{ marginRight: 10, color: '#666' }}
          />
          <Typography>Syncing</Typography>
        </>
      )}
      

      {openDownloadExtensionModal && !isLoadingClientConfig && (
        <DownloadUverifyExtensionModal
          open={openDownloadExtensionModal}
          clientConfig={clientConfig}
          onClose={() => {
            setOpenDownloadExtensionModal(false);
          }}
        />
      )}

        <div >
          <Tooltip title='Download U-Verify Extension'>
            <Button
              color='transparent'
              aria-label='Download U-Verify Extension'
              justIcon
              disabled={!clientConfig}
              onClick={
                !isFirefox
                  ? () =>
                    window
                      .open(
                        clientConfig?.browserExtensionConfig?.chromium,
                        "_blank"
                      )
                      .focus()
                  : handleDownloadUverifyExtension
              }
              className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
            >
              <Extension
                className={
                  classes.headerLinksSvg +
                  " " +
                  (rtlActive
                    ? classes.links + " " + classes.linksRTL
                    : classes.links)
                }
              />
            </Button>
          </Tooltip>
        </div>


      <div className={managerClasses}>
        <Button
          color='transparent'
          aria-label='Language'
          justIcon
          aria-owns={openLanguage ? 'language-menu-list' : null}
          aria-haspopup='true'
          onClick={handleClickLanguage}
          className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}

        >
          <TranslateIcon
            className={
              classes.headerLinksSvg +
              ' ' +
              (rtlActive
                ? classes.links + ' ' + classes.linksRTL
                : classes.links)
            }
          />
          <Hidden mdUp implementation='css'>
            <span onClick={handleClickLanguage} className={classes.linkText}>
              {rtlActive ? 'الملف الشخصي' : 'Perfil'}
            </span>
          </Hidden>
        </Button>
        <Popper
          open={Boolean(openLanguage)}
          anchorEl={openLanguage}
          transition
          disablePortal
          placement='bottom'
          className={classNames({
            [classes.popperClose]: !openLanguage,
            [classes.popperResponsive]: true,
            [classes.popperNav]: true,
          })}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              id='language-menu-list'
              style={{ transformOrigin: '0 0 0' }}
            >
              <Paper className={classes.dropdown}>
                <ClickAwayListener onClickAway={handleCloseLanguage}>
                  <MenuList role='menu'>
                    <MenuItem
                      onClick={handleClickEnglish}
                      className={dropdownItem}
                    >
                      <span>English</span>
                    </MenuItem>
                    <MenuItem
                      onClick={handleClickSpanish}
                      className={dropdownItem}
                    >
                      <span>Español</span>
                    </MenuItem>
                    <MenuItem
                      onClick={handleClickItalian}
                      className={dropdownItem}
                    >
                      <span>Italiano</span>
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    
      {/*Profile*/}
      <div className={managerClasses}>
        <Button
          color='transparent'
          aria-label='Person'
          justIcon
          aria-owns={openProfile ? 'profile-menu-list' : null}
          aria-haspopup='true'
          onClick={handleClickProfile}
          className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
          muiClasses={{
            label: rtlActive ? classes.labelRTL : '',
          }}
        >
          <Person
            className={
              classes.headerLinksSvg +
              ' ' +
              (rtlActive
                ? classes.links + ' ' + classes.linksRTL
                : classes.links)
            }
          />
          <Hidden mdUp implementation='css'>
            <span onClick={handleClickProfile} className={classes.linkText}>
              {rtlActive ? 'الملف الشخصي' : 'Perfil'}
            </span>
          </Hidden>
        </Button>
        <Popper
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          placement='bottom'
          className={classNames({
            [classes.popperClose]: !openProfile,
            [classes.popperResponsive]: true,
            [classes.popperNav]: true,
          })}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              id='profile-menu-list'
              style={{ transformOrigin: '0 0 0' }}
            >
              <Paper className={classes.dropdown}>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <MenuList role='menu'>
                    <MenuItem
                      onClick={handleCloseProfile}
                      className={dropdownItem}
                    >
                      {rtlActive ? 'الملف الشخصي' : t('profile')}
                    </MenuItem>
                    <Divider light />
                    <MenuItem
                      onClick={handleOpenConfirmModal}
                      className={dropdownItem}
                    >
                      {rtlActive ? 'الخروج' : t('logout')}
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}

HeaderLinks.propTypes = {
  rtlActive: PropTypes.bool,
};
